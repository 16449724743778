import React, { useState } from "react";
import ReactSVG from "react-svg";
import styled from "styled-components/macro"; // eslint-disable-line
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import queryString from "query-string";
import { size, map, get } from "lodash";

import LoginPage from "../layouts/LoginPage";
import { SimulatorButton as Button } from "../components/Button";
import { GradientInput } from "../components/inputs/BasicInput";
import { BasicError } from "../components/inputs/Error";
import Modal from "../components/Modal";

import lock from "../svgs/lock.svg";

import { changePassword, getCsrfToken } from "../api";

const Page = styled(LoginPage)`
  form {
    max-width: 100%;
  }
`;

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(12, "au moins 12 caractères")
    .matches(/[0-9]/, "au moins un chiffre")
    .matches(/[a-z]/, "au moins une minuscule")
    .matches(/[A-Z]/, "au moins une majuscule")
    .matches(/[^A-Za-z0-9]/, "au moins un caractère spécial")
    .required("Requis"),
  passwordRepeat: Yup.string()
    .required("Requis")
    .label("Confirmation du mot de passe")
    .test(
      "passwords-match",
      "Les mots de passe ne correspondent pas",
      function (value) {
        return this.parent.password === value;
      }
    ),
});

function Activation(props) {
  const [globalErrors, setErrors] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { email, token } = queryString.parse(props.location.search);

  return (
    <Page>
      <p
        css={`
          font-size: 18px;
          margin-bottom: 20px;
        `}
      >
        Activez votre compte
      </p>
      <Formik
        initialValues={{
          token: token || "",
          email: email || "",
          password: "",
          passwordRepeat: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          setErrors(false);
          try {
            const { token: csrfToken } = await getCsrfToken(
              "activation-account"
            );
            await changePassword({ ...values, csrfToken });
            setShowModal(true);
          } catch (error) {
            setErrors(
              get(error, `response.data.activation_form.errors`, false)
            );
            actions.setSubmitting(false);
          }
        }}
        render={({ values }) => {
          return (
            <Form>
              <Field name="token" type="hidden" />
              <GradientInput name="email" placeholder="Adresse e-mail" />

              <GradientInput
                name="password"
                type="password"
                placeholder="Créez votre mot de passe"
                suffix={<ReactSVG src={lock} />}
                helpMessage="Au moins 12 caractères, dont 1 majuscule, 1 minuscule, 1 chiffre et un caractère spécial."
              />

              <GradientInput
                name="passwordRepeat"
                type="password"
                placeholder="Confirmez votre mot de passe"
                suffix={<ReactSVG src={lock} />}
              />

              {globalErrors && size(globalErrors) > 0
                ? map(globalErrors, (error, i) => (
                    <BasicError key={i} message={error} />
                  ))
                : null}

              <div
                css={`
                  text-align: center;
                  margin-top: 50px;
                `}
              >
                <Button type="submit">Valider</Button>
              </div>
              <Modal open={showModal} lightStyle={true}>
                <span
                  css={`
                    text-align: center;
                  `}
                >
                  Votre mot de passe a bien été initialisé{" "}
                </span>
                <Button
                  css={`
                    margin-top: 30px;
                    background: ;
                  `}
                  onClick={async () => {
                    props.history.push(`/login?email=${values.email}`);
                  }}
                >
                  Fermer
                </Button>
              </Modal>
            </Form>
          );
        }}
      />
    </Page>
  );
}

export default Activation;
