import { Col, Row } from "styled-bootstrap-grid";
import { GetMatchingWidget, getInitialValues } from "../FormWidgets/helpers";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { forEach, get, map, size } from "lodash";
import { BasicError, Error } from "../../components/inputs/Error";
import Button, { LightButton } from "../../components/Button";
import { Formik } from "formik";
import LabelTitle from "../../components/LabelTitle";
import Loader from "../../components/Loader";
import LoaderBase from "react-loader-spinner";
import Tooltip from "../Tooltip";
import styled from "styled-components/macro";
import { workChoice, workChoiceTooltip } from "../../api";
import { InlineInputWrapper, InputEnergyBillValue } from "./Step1";
import { RadioGroupLabel } from "../FormWidgets/Switch";
import ToggleButton from "../inputs/Toggle";
import Switch from "../FormWidgets/Switch";

const FormContainer = styled.form`
  margin-top: 40px;
`;

const FormBottom = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  gap: 50px;
  margin-top: 40px;
`;
const BtnGroup = styled.div`
  margin-top: 45px;
  width: 100%;
  ${Button} + ${Button} {
    margin-left: 15px;
  }
`;

const ErrorInput = styled(Error)`
  position: absolute;
  bottom: -60px;

  ${RadioGroupLabel} {
    flex: 1 0 100%;
  }
`

function Step2(props) {
  const [globalErrors, setErrors] = useState(false);
  const [formData, setFormData] = useState(null);
  const [tooltip, setTooltip] = useState(null);
  const globalErrorsBlock = useRef(null);
  const [buttonLoading, setButtonLoading] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await workChoiceTooltip();
        setTooltip(data.tooltip);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [setTooltip]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await workChoice();
        setFormData(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const { properties } = formData || {};

  const worksFamilies = useMemo(
    () => ({
      isolationWork: properties?.isolationWork,
      heatingWork: properties?.heatingWork,
      domesticHotWaterWork: properties?.domesticHotWaterWork,
      airingWork: properties?.airingWork,
    }),
    [properties],
  );

  const performancesAfterWorks = useMemo(
    () => [
      {
        amount: {
          ...properties?.buildingPerformancePEAfterWorks,
          fieldName: "buildingPerformancePEAfterWorks",
          suffix: "kWh/m2.an",
        },
        known: {
          ...properties?.buildingPerformancePEAfterWorksKnown,
          fieldName: "buildingPerformancePEAfterWorksKnown",
        },
      },
      {
        amount: {
          ...properties?.buildingPerformanceFEAfterWorks,
          fieldName: "buildingPerformanceFEAfterWorks",
          suffix: "kWh/m2.an",
        },
        known: {
          ...properties?.buildingPerformanceFEAfterWorksKnown,
          fieldName: "buildingPerformanceFEAfterWorksKnown",
        },
      },
      {
        amount: {
          ...properties?.annualEnergyBillAfterWorkValue,
          fieldName: "annualEnergyBillAfterWorkValue",
          suffix: "€",
        },
        known: {
          ...properties?.annualEnergyBillAfterWork,
          fieldName: "annualEnergyBillAfterWork",
        },
      },
    ],
    [properties],
  );

  return (
    <>
      {formData ? (
        <div>
          <LabelTitle>
            Choix des travaux{" "}
            {tooltip && <Tooltip id="choix-travaux" tooltip={tooltip} />}
          </LabelTitle>
          <Formik
            enableReinitialize
            initialValues={getInitialValues(formData)}
            onSubmit={async (values, actions) => {
              const { title } = formData;
              setButtonLoading(1);

              try {
                await workChoice({
                  method: "post",
                  data: {
                    [title]: {
                      ...values,
                      EndEnergyLabelKnown: values.EnergyLabelAfterWork &&  !values.EndEnergyLabelKnown,
                      buildingPerformancePEAfterWorksKnown:
                        !values.buildingPerformancePEAfterWorksKnown,
                      buildingPerformanceFEAfterWorksKnown:
                        !values.buildingPerformanceFEAfterWorksKnown,
                      annualEnergyBillAfterWork:
                        !values.annualEnergyBillAfterWork,
                      "_token": formData["_token"],
                    },
                  },
                });

                window.sessionStorage.setItem("current_simulation", true);
                props.history.push("/simulator/3");
              } catch (error) {
                setButtonLoading(0);
                setErrors(get(error, `response.data.${title}.errors`, false));
                globalErrorsBlock.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
                forEach(get(error, `response.data.${title}`), (error, key) => {
                  actions.setFieldError(key, get(error, `errors`));
                });
              }
            }}
            render={({ values, errors, touched, handleSubmit }) => {
              return (
                <FormContainer onSubmit={handleSubmit}>
                  <div ref={globalErrorsBlock}>
                    {globalErrors && size(globalErrors) > 0
                      ? map(globalErrors, (error, i) => (
                        <BasicError key={i} message={error} />
                      ))
                      : null}
                  </div>
                  {worksFamilies ? (
                    <Row style={{ marginTop: "-20px" }}>
                      {map(worksFamilies, (field, fieldKey) => {
                        return (
                          <Col className="m-t-1" md="6" xl="3" key={fieldKey}>
                            {GetMatchingWidget({
                              field,
                              fieldKey,
                              values,
                              errors,
                              touched,
                            })}
                          </Col>
                        );
                      })}
                    </Row>
                  ) : null}
                  <FormBottom>
                    {performancesAfterWorks && (
                      <>
                        <div className="grid grid-cols-1 gap-5 border border-green-600 lg:grid-cols-2 xl:grid-cols-3">
                          {map(
                            performancesAfterWorks,
                            ({ amount, known }, key) => (
                              <InlineInputWrapper className="m-0" key={key}>
                                <RadioGroupLabel>
                                  {amount.title}
                                </RadioGroupLabel>
                                <div className="flex items-center gap-4">
                                  {get(properties, amount.fieldName) ? (
                                    <InputEnergyBillValue
                                      type={amount.type}
                                      field={amount}
                                      name={amount.fieldName}
                                      className={`m-0 ${values[`${known.fieldName}`]
                                        ? "opacity-50 pointer-events-none"
                                        : ""
                                        }`}
                                      suffix={amount.suffix}
                                      displayError={false}
                                    />
                                  ) : null}
                                  {get(properties, known.fieldName) ? (
                                    <ToggleButton
                                      field={{
                                        label: known.title,
                                      }}
                                      name={known.fieldName}
                                      minus={true}
                                    />
                                  ) : null}
                                </div>
                                <ErrorInput name={amount.fieldName} />
                              </InlineInputWrapper>
                            ),
                          )}
                        </div>
                        <div className="flex flex-wrap items-center justify-center gap-8 mt-8">
                          <RadioGroupLabel className="w-full">
                            {properties.EnergyLabelAfterWork.title}
                          </RadioGroupLabel>
                          {get(properties, "EnergyLabelAfterWork") ? (
                            <div
                              className={
                                values.EndEnergyLabelKnown
                                  ? "pointer-events-none opacity-50"
                                  : ""
                              }
                            >
                              <Switch
                                field={properties.EnergyLabelAfterWork}
                                name="EnergyLabelAfterWork"
                                choices={
                                  properties.EnergyLabelAfterWork.enum
                                }
                                className="m-0 smallField"
                              />
                            </div>
                          ) : null}
                          {get(properties, "EndEnergyLabelKnown") ? (
                            <ToggleButton
                              field={{
                                label: properties.EndEnergyLabelKnown.title,
                              }}
                              name="EndEnergyLabelKnown"
                            />
                          ) : null}
                        </div>
                      </>
                    )}
                    <BtnGroup
                      css={`
                        text-align: right;
                      `}
                    >
                      <LightButton
                        type="button"
                        onClick={() => {
                          props.history.push("/simulator/1");
                        }}
                      >
                        Etape Précédente
                      </LightButton>
                      <Button type="submit" className="flex-shrink-0 ml-auto">
                        {buttonLoading ? (
                          <LoaderBase
                            type="ThreeDots"
                            color="#ffffff"
                            height="16"
                            width="100"
                          />
                        ) : (
                          "Etape Suivante"
                        )}
                      </Button>
                    </BtnGroup>
                  </FormBottom>
                </FormContainer>
              );
            }}
          />
        </div>
      ) : (
        <div
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 90vh;
          `}
        >
          <Loader />
        </div>
      )}
    </>
  );
}

export default Step2;
