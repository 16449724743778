import React from "react";
import styled from "styled-components/macro";
import { Field } from "formik";

const ToggleWrapper = styled.div`
  display: inline-block;

  input {
    &:not(:checked),
    &:checked {
      position: absolute;
      left: -9999px;
    }

    + label {
      display: inline-block;
      background: #f7f7f8;
      font-size: 17px;
      line-height: 18px;
      padding: 28px 70px;
      border-radius: 10px;
      cursor: pointer;
      text-align: center;

      span {
        display: block;
      }
      svg + span {
        margin-top: 15px;
      }
    }

    &:checked + label {
      background: ${props => props.theme.mainGradient};
      color: #fff;
      border-color: transparent;

      svg {
        fill: #fff;
        stroke: #fff;
      }
    }
  }

  svg {
    width: 80px;
    height: 80px;
    fill: #c1c1c1;
    stroke: #c1c1c1;
  }

  &.minus {
    
    input + label {
      font-size: 14px;
      padding: 20px ;
    }
  }
`;

function ToggleButton({ name, field: { label }, minus = false, handleChange }) {
  return (
    <ToggleWrapper className={minus ? 'minus' : ''}>
      <Field component={Checkbox} name={name} label={label} handleChange={handleChange} />
    </ToggleWrapper>
  );
}


function Checkbox({ field: { name, onChange, ...rest }, label, handleChange }) {

  return (
    <div className="relative">
      <input
        name={name}
        id={name}
        type="checkbox"
        checked={rest.value}
        onChange={(props) => {
          onChange(props);
          if (typeof handleChange === "function") {
            handleChange()
          }
        }}
        {...rest}
      />
      <label htmlFor={name} >
        <span>{label}</span>
      </label>
    </div>
  )
}
export default styled(ToggleButton)``;
