import { Col, Row } from "styled-bootstrap-grid";
import React, { useEffect, useState } from "react";
import { get, map, sortBy } from "lodash";
import { getStage, isFirstUserLearning, setFirstUserLearningPopupHasBeenRead, useGetLimitedLearning } from "../../api";

import Block from "../../components/Block";
import BtnLibrary from "../../components/BtnLibrary";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import Page from "../../layouts/Page";
import Title from "../../components/Title";
import TrainingSubTitle from "../../components/TrainingSubtitle";
import VideoCard from "../../components/VideoCard";
import styled from "styled-components/macro";

const CustomButton = styled(Button)`
    background: none;
    border: 1px solid white;
    border-radius: 0;
    margin-top: 1rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
`;

const CustomModal = styled(Modal)`
    background: rgba(46, 56, 75, 0.8) !important;
`;

function popupHasBeenRead() {
  setFirstUserLearningPopupHasBeenRead();
}

function Discover({
  match: {
    params: { id }
  }
}) {
  const [data, setData] = useState(null);
  const [firstLearning, setFirstLearning] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    async function fetchStage() {
      try {
        const { data } = await getStage(id);
        setData(data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchStage();
  }, [id]);

  useEffect(() => {
    async function checkIfIsFirstUserLearning() {
      try {
        const { data } = await isFirstUserLearning(id);
        setFirstLearning(data.first);
      } catch (error) {
        console.error(error);
      }
    }
    checkIfIsFirstUserLearning();
  }, [id]);

  const { data:limitedLearning } = useGetLimitedLearning();

  if(limitedLearning) {
    if (showModal !== true && firstLearning !== null && firstLearning === true && limitedLearning.limited === true) {
      setFirstLearning(false);
      setShowModal(true);
    }
  }

  return (
    <Page>
      <Title>Formations</Title>
      <TrainingSubTitle completion={get(data, "completion", "")}>
        {limitedLearning !== null && !limitedLearning && (<Link to={"/training"}>Formations | </Link>)}
        <strong>{get(data, "stage_title", "")}</strong>
      </TrainingSubTitle>
      <BtnLibrary />
      <Block>
        {data ? (
          <Row>
            {map(sortBy(get(data, "preview_trainings"), formation => formation.training_number), index => {
              return (
                <Col md="4" key={index.id}>
                  <VideoCard
                    image={index.url_img_thumbnail}
                    typeImage={index.url_img_topic}
                    link={`/training/stage-details/${index.id}`}
                    title={index.title}
                    completion={index.completion}
                  />
                </Col>
              );
            })}
          </Row>
        ) : (
          <Loader />
        )}
      </Block>

      <CustomModal open={showModal}>
        <p css={"text-align:center;"}>
          Bienvenue sur le programme de formation Pepz !<br></br>
          <br></br>
          Vous pouvez dès à présent visionner l'ensemble des vidéos mis à votre disposition.<br></br>
          <br></br>
          Accomplissez 100% du parcours « Je découvre » pour <br></br>
          <b>participer au tirage au sort et peut-être gagner un vélo électrique !</b>
        </p>
        <CustomButton onClick={() => {
          setShowModal(false);
          popupHasBeenRead();
        }}>
          Commencez
        </CustomButton>
      </CustomModal>
    </Page>
  );
}

export default Discover;
