import { Link } from "react-router-dom";
import PlayIcon from "../svgs/videos-purple.svg";
import React from "react";
import ReactSVG from "react-svg";
import styled from "styled-components/macro";
import { useGetLimitedLearning } from "../api";

const Btn = styled(Link)`
  position: absolute;
  right: 50px;
  top: 50px;
  border-radius: 25px;
  background: #fff;
  padding: 5px 25px 5px 5px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.theme.color.primary};
  text-decoration: none;
  > span {
    margin-left: 5px;
  }
`;

export default function BtnLibrary() {
  const { data: limitedLearning } = useGetLimitedLearning();

  return limitedLearning === null || limitedLearning ? null : (
    <Btn to={"/training/library"}>
      <ReactSVG src={PlayIcon} />
      <span>Bibliothèque videos</span>
    </Btn>
  );
}
