import { Link } from "react-router-dom";
import React from "react";
import { map, split } from "lodash";
import reactStringReplace from "react-string-replace";
import Loader from "../Loader";

export default function Legend({ captions }) {
  const arrayCaption = split(captions, "\r\n");
  return (
    <div className="px-4 mt-6">
      {captions === false && <Loader />}
      {captions &&
        map(arrayCaption, (caption, i) => {
          const regex = /(zone climatique H\d)/gim;
          return (
            <div key={i}>
              {reactStringReplace(caption, regex, (match, i) => (
                <Link key={i} className="text-blue-600" to={`/glossaire/z`}>
                  {match}
                </Link>
              ))}
            </div>
          );
        })}
    </div>
  );
}
