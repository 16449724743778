import React, { useState } from "react";
import { map } from "lodash";
import Tooltip from "../../Tooltip";
import ReactSVG from "react-svg";
import cross from "../../../svgs/cross.svg";
import search from "../../../svgs/search.svg";
import { useDesktop } from "../../../utils/useDesktop";

export function AccordionCell({ tooltip, notice, text, children, title }) {
  const [displayDetails, setDisplayDetails] = useState(false);
  const isDesktop = useDesktop();

  function toggleDisplayDetails() {
    setDisplayDetails((prevState) => !prevState);
  }
  return (
    <div>
      <div
        className={`bg-white text-gray-900 rounded-2xl flex flex-col justify-center text-lg font-semibold`}
      >
        {!isDesktop && title && (
          <div className="bg-apple-500 rounded-t-2xl w-full flex items-center justify-center text-lg text-white p-2">
            {title}
          </div>
        )}
        <div className="h-16 flex items-center justify-center text-apple-500 text-xl font-bold">
          {text}
          {tooltip && <Tooltip tooltip={tooltip} />}
          {notice && (
            <small className="transform -translate-y-3 pl-1 font-semibold">
              ({notice})
            </small>
          )}
        </div>
        {displayDetails && (
          <>
            <div className="border-t-2 border-gray-200 border-solid text-sm p-6">
              {children}
            </div>
            <button
              className="text-white bg-apple-500 rounded-b-2xl flex items-center justify-center gap-2 py-2 font-semibold"
              onClick={toggleDisplayDetails}
            >
              <ReactSVG src={cross} /> <span>Fermer</span>
            </button>
          </>
        )}
      </div>

      {!displayDetails && (
        <div className="flex justify-center mt-2">
          <button
            className="flex text-white items-center gap-2"
            onClick={toggleDisplayDetails}
          >
            <ReactSVG src={search} />
            <span className="underline">Voir le détail</span>
          </button>
        </div>
      )}
    </div>
  );
}

export function WhiteCell({
  children,
  tooltip,
  notice,
  color,
  bgColor,
  fontSize,
  title,
  mobilBgColor,
  height = 16,
}) {
  const isDesktop = useDesktop();
  const cellHeight = `h-${height}`;
  return (
    <div
      className={`${color ? color : "text-gray-900"} ${
        fontSize ? fontSize : "text-xl"
      } ${
        bgColor ? bgColor : "bg-white"
      } rounded-2xl flex xl:flex-row flex-col items-center justify-center font-bold`}
    >
      {!isDesktop && title && (
        <div
          className={`${
            mobilBgColor ? mobilBgColor : "bg-yellow-600"
          } rounded-t-2xl w-full flex items-center justify-center text-lg text-white p-2`}
        >
          {title}
        </div>
      )}
      <div className={`${cellHeight} flex items-center justify-center`}>
        {children}
        {tooltip && <Tooltip tooltip={tooltip} />}
        {notice && (
          <small className="transform -translate-y-3 pl-1 font-semibold">
            ({notice})
          </small>
        )}
      </div>
    </div>
  );
}

export function BigWhiteCell({ children, color, className = "" }) {
  return (
    <div
      className={`${
        color ? "text-" + color : "text-gray-900"
      }  bg-white rounded-2xl flex xl:flex-row flex-col ${className}`}
    >
      <div className="w-full flex flex-col justify-center">{children}</div>
    </div>
  );
}

export function Row({ label, children, sideContent = null }) {
  const childrenArray = React.Children.toArray(children);
  const nbColumns = childrenArray.length;
  return (
    <div className="grid xl:grid-cols-7 bg-gray-900 rounded-2xl text-white px-12 py-10">
      <div className="col-span-3">
        <div className="text-xl font-semibold">{label}</div>
        {sideContent}
      </div>
      <div className="col-span-4">
        <div className={`py-3 grid xl:grid-cols-${nbColumns} gap-4 xl:gap-0`}>
          {map(childrenArray, (child, key) => (
            <div
              key={key}
              className="xl:px-5 xl:border-r border-white border-solid last:border-0 last:pr-0 first:pl-0"
            >
              {child}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export function SwitchAmount({ amount, initialValue = false, onChange }) {
  const [checked, setChecked] = useState(initialValue);
  const handleChange = () => {
    const toggle = !checked;
    setChecked(toggle);
    onChange(toggle);
  };

  return (
    <div className={`switchAmount ${checked ? "checked" : ""}`}>
      <label>
        <input onChange={handleChange} type="checkbox" checked={checked} />
        {amount}
      </label>
    </div>
  );
}
