import * as Yup from "yup";

import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { get, map, size } from "lodash";
import { getAgencyInformations, getCsrfToken, registration } from "../api";

import { BasicError } from "../components/inputs/Error";
import { SimulatorButton as Button } from "../components/Button";
import { GradientInput } from "../components/inputs/BasicInput";
import LoginPage from "../layouts/LoginPage";
import Modal from "../components/Modal";
import queryString from "query-string";

function Registration(props) {
  const [errors, setError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { lastname, firstname, email } = queryString.parse(
    props.location.search
  );
  let agencySlug = props.match.params.agencySlug;

  const [agency, setAgency] = useState(null);

  useEffect(() => {
    async function agencyInformations() {
      try {
        // Here, if you remove the "if", it produce an infinite call loop :-/
        if (agency === null) {
          const { data } = await getAgencyInformations(agencySlug);
          setAgency(data);
        }
      } catch (error) {
        console.error(error);
      }
    }
    agencyInformations();
  });

  const RegistrationSchema = Yup.object().shape({
    lastname: Yup.string().required("Requis"),
    firstname: Yup.string().required("Requis"),
    email: Yup.string()
      .email("E-mail invalide")
      .matches(
        agency !== null
          ? new RegExp("@" + agency.emailDomain)
          : /@banquepostale\.fr/,
        {
          message:
            "Votre " +
            "email semble ne pas être un email de " +
            (agency !== null ? agency.name : "la banque postale"),
        }
      )
      .required("Requis"),
  });

  return (
    <LoginPage>
      <p
        css={`
          font-size: 18px;
          margin-bottom: 20px;
        `}
      >
        Création de votre compte {agency !== null ? " - " + agency.name : null}
      </p>
      <Formik
        initialValues={{
          firstname: firstname || "",
          lastname: lastname || "",
          email: email || "",
          bankingAgencySlug: agencySlug || "",
        }}
        validationSchema={RegistrationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            setError(false);
            const { token: csrfToken } = await getCsrfToken("registration");
            await registration({
              data: {
                ...values,
                "_token": csrfToken,
              },
            });
            setShowModal(true);
          } catch (error) {
            setError(
              get(error, `response.data.Registration_form.errors`, false)
            );
            setSubmitting(false);
          }
        }}
        render={() => {
          return (
            <Form>
              <input
                type="hidden"
                name="bankingAgencySlug"
                value={agencySlug}
              />
              <GradientInput
                type="lastname"
                name="lastname"
                placeholder="Nom de famille"
              />

              <GradientInput
                type="firstname"
                name="firstname"
                placeholder="Prénom"
              />

              <GradientInput
                type="email"
                name="email"
                placeholder="Adresse e-mail"
              />

              {errors && size(errors) > 0
                ? map(errors, (error, i) => (
                    <BasicError key={i} message={error} />
                  ))
                : null}

              <div
                css={`
                  text-align: center;
                  margin-top: 50px;
                `}
              >
                <Button type="submit">
                  Demander l'envoi de l'email de confirmation
                </Button>
              </div>
            </Form>
          );
        }}
      />

      <Modal open={showModal} lightStyle={true}>
        <span
          css={`
            text-align: center;
          `}
        >
          Une invitation pour créer votre mot de passe vient de vous être envoyé
          par email.
        </span>
        <Button
          css={`
            margin-top: 30px;
            background: ;
          `}
          onClick={async () => {
            props.history.push(`/login`);
          }}
        >
          Fermer
        </Button>
      </Modal>
    </LoginPage>
  );
}

export default Registration;
