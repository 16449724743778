import React, { useContext } from "react";
import styled from "styled-components/macro";
import Title from "../components/Title";
import SubTitle from "../components/SubTitle";
import Block from "../components/Block";
import Sidebar from "../components/Sidebar";

import { LiteContext } from "../App";

const PageWrapper = styled.main`
  margin-left: ${(props) => {
    return props.noSidebar === true ? "0" : `${props.lite ? "300px" : "100px"}`;
  }};
  padding: 50px;
  min-height: calc(100vh - 50px);
  position: relative;
  transition: margin-left 250ms;
  &::before {
    content: "";
    display: ${(props) => {
      return props.noBackground === true ? "none" : "block";
    }};
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    height: 300px;
    background: ${(props) => props.theme.mainGradient};
    z-index: -1;
  }

  + ${Sidebar} {
    display: ${(props) => {
      return props.noSidebar === true ? "none" : "flex";
    }};
  }

  > ${Block} {
    min-height: 90vh;
  }

  > ${Title}, ${Title}.pageTitle {
    color: #fff;
    margin-bottom: 45px;
    + ${SubTitle} {
      margin-top: -30px;
    }
  }
  > ${SubTitle} {
    color: #fff;
    margin-bottom: 45px;
  }
`;

function Page({ children }) {
  const { lite } = useContext(LiteContext);

  return <PageWrapper lite={lite.open}>{children}</PageWrapper>;
}

export default Page;
