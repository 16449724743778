import reset from "styled-reset";
import { createGlobalStyle } from "styled-components";

import SourceSansPro from "./fonts/SourceSansPro-Regular.woff2";
import SourceSansProBold from "./fonts/SourceSansPro-Bold.woff2";
import ProximaNovaLight from "./fonts/ProximaNovaT-Thin.woff2";
import ProximaNova from "./fonts/ProximaNova-Regular.woff2";
import ProximaNovaSemiBold from "./fonts/ProximaNova-Semibold.woff2";
import ProximaNovaBold from "./fonts/ProximaNova-Bold.woff2";

import SourceSansProAlt from "./fonts/SourceSansPro-Regular.woff";
import SourceSansProBoldAlt from "./fonts/SourceSansPro-Bold.woff";
import ProximaNovaLightAlt from "./fonts/ProximaNovaT-Thin.woff";
import ProximaNovaAlt from "./fonts/ProximaNova-Regular.woff";
import ProximaNovaSemiBoldAlt from "./fonts/ProximaNova-Semibold.woff";
import ProximaNovaBoldAlt from "./fonts/ProximaNova-Bold.woff";

const GlobalStyle = createGlobalStyle`
${reset}

@font-face {
    font-family: 'Source Sans Pro';
    src: url(${SourceSansPro}) format('woff2'), url(${SourceSansProAlt}) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url(${SourceSansProBold}) format('woff2'), url(${SourceSansProBoldAlt}) format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'ProximaNova';
    src: url(${ProximaNovaBold}) format('woff2'), url(${ProximaNovaBoldAlt}) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'ProximaNova';
    src: url(${ProximaNovaSemiBold}) format('woff2'), url(${ProximaNovaSemiBoldAlt}) format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'ProximaNova';
    src: url(${ProximaNovaLight}) format('woff2'), url(${ProximaNovaLightAlt}) format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'ProximaNova';
    src: url(${ProximaNova}) format('woff2'), url(${ProximaNovaAlt}) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}

textarea, input {
    font-size: 17px;
    font-family: 'ProximaNova', sans-serif;
}

* {
  box-sizing: border-box;
  &:before, &:after {
    box-sizing: border-box;
  }
}
html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}
body {
    font-family: 'ProximaNova', sans-serif;
    font-size: 1rem;
    color: #3B3F42;
    background-color: #F7F7F8;
}
img {
  max-width: 100%;
  height: auto;
}
svg {
  pointer-events: none;
}
em {
    font-style: italic;
}

strong, b {
    font-weight: bold;
}
.text-center {
    text-align: center !important;
}

.m-t-1 {
    margin-top: 20px;
}

.wysiwyg {
    font-size: 17px;
    color: #7C7C7D;
    line-height: 24px;
    
    h1, h2, h3, h4 {
        margin-bottom: 22px;
    }

    h2 {
        display: inline-block;
        background: #00B5BB;
        padding: 10px;
        color: #fff;
        font-size: 20px;
    }
    h3 {
        color: #00B5BB;
        font-size: 20px;
        font-weight: 600;
    }
    h4 {
        font-size: 18px;
        color:  #2E384B;
        display: inline-block;
        padding: 4px 8px;
        font-weight: 600;

        &:before {
            content: "";
            display: inline-block;
            margin-right: 12px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 5px 0 5px 8.7px;
            border-color: transparent transparent transparent #00c4c6;
        }
    }

    p {
        margin-bottom: 20px;
    }

    a {
        color: rgb(0, 181, 187);
    }
    ul {
        list-style: none;
        margin: 20px 0;

        li {

            &:before {
            content: "";
            display: inline-block;
            width: 11px;
            height: 3px;
            background: #00c4c6;
            vertical-align: middle;
            margin-right: 10px;
      }
        }
    }
    i, em {
        font-style: italic;
    }
    .alignleft {
        float: left;
        margin: 0.5em 1em 0.5em 0;
    }
    .alignright {
        float: right;
        margin: 0.5em 0 0.5em 1em;
    }
    .aligncenter {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

.react-tiny-popover-container {
    z-index: 10;
}

.step-1-orange {
  color: #FF8B5A;
}
span.step-1-orange:after {
  content: '*';
}

.step-1-bottom {
  margin-top:2rem;
}
`;

export default GlobalStyle;
