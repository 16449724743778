import React from "react";
import styled from "styled-components/macro";
import ReactSVG from "react-svg";

import Tooltip from "./Tooltip";

const FieldSetIntroTitle = styled.div`
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
  text-align: center;

  @media (min-width: 1300px) {
    font-size: 18px;
  }
`;

const FieldSetIntroToolTip = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const FieldSetIntroIcon = styled.div`
  svg {
    width: 80px;
    height: 80px;
  }
`;

const FieldSetIntro = styled.div`
  width: 100%;

  border-radius: 10px;
  background: ${props =>
    props.active ? props.theme.mainGradient : props.theme.color.light};

  position: relative;
  margin-bottom: 30px;
  cursor: ${props => (props.onClick ? "pointer" : "inherit")};

  &::after {
    content: "";
    padding-bottom: 65%;
    display: block;
  }

  > div {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  svg {
    fill: ${props => (props.active ? "#fff" : props.theme.color.primary)};
    stroke: ${props => (props.active ? "#fff" : props.theme.color.primary)};
  }

  ${FieldSetIntroToolTip} {
    svg,
    path {
      fill: ${props => (props.active ? "#fff" : "#9E9E9E")};
    }
  }

  ${FieldSetIntroTitle} {
    color: ${props => (props.active ? "#fff" : props.theme.color.dark)};
  }
`;

function Intro({ className, icon, title, tooltip, active, handleClick }) {
  return icon ? (
    <FieldSetIntro active={active} onClick={handleClick} className={className}>
      <div>
        {tooltip ? (
          <FieldSetIntroToolTip onClick={(e) => e.stopPropagation()}>
            <Tooltip tooltip={tooltip} />
          </FieldSetIntroToolTip>
        ) : null}
        <FieldSetIntroIcon>
          <ReactSVG src={icon} />
        </FieldSetIntroIcon>
        <FieldSetIntroTitle>{title}</FieldSetIntroTitle>
      </div>
    </FieldSetIntro>
  ) : null;
}

export default styled(Intro)``;
