import React from "react";
import styled from "styled-components/macro";

import LibraryBg from "../svgs/libraryBG.svg";
import playWhiteIcon from "../svgs/play-white.svg";
import ReactSVG from "react-svg";

const Wrapper = styled.div`
background-color: #96CDE2;
background-image: url('${LibraryBg}');
background-size: cover;
background-position: center center;
display: block;
height: 205px;
border-radius: 10px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
color: #fff;
text-decoration: none;
text-align: center;
font-size: 20;
line-height: 24px;
strong {
  text-transform: uppercase;
  font-size: 30px;
  line-height: 37px;
  display: block;
  font-weight: bold;
}

svg {
    width: 64px;
    height: 64px;
}
`;

function LibraryBanner({ className, lite }) {
  return (
    <Wrapper className={className}>
      <ReactSVG src={playWhiteIcon} />
      {!lite ? "Accèdez à la" : null} <strong>BIbliothèque vidéos</strong>
    </Wrapper>
  );
}

export default styled(LibraryBanner)``;
