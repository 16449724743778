import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { map } from "lodash";
import { Row, Col } from "styled-bootstrap-grid";

import Page from "../../layouts/Page";
import Block from "../../components/Block";
import Title from "../../components/Title";
import SubTitle from "../../components/SubTitle";
import LibraryBanner from "../../components/LibraryBanner";
import VideoCard from "../../components/VideoCard";
import Loader from "../../components/Loader";

import { getTrainingLibrary } from "../../api";

const Category = styled.div`
  margin-top: 40px;
  margin-bottom: 55px;
`;

const CategoryTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 20px;
  img {
    margin-right: 14px;
  }
`;

function Library() {
  const [data, setData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const { data } = await getTrainingLibrary();
      setData(data);
    }
    fetchData();
  }, []);

  return (
    <Page>
      <Title>Formations</Title>
      <SubTitle>
        <strong
          css={`
            text-transform: uppercase;
          `}
        >
          Bibliothèque videos
        </strong>
      </SubTitle>
      <Block>
        <LibraryBanner
          css={`
            margin-bottom: 40px;
          `}
          lite
        />
        <div>
          {!data ? (
            <Loader />
          ) : (
            map(data, category => {
              return (
                <Category key={category.id}>
                  <CategoryTitle>
                    <img src={category.url_img} alt="" />
                    {category.title}
                  </CategoryTitle>
                  <Row>
                    {map(category.trainings, training => {
                      return (
                        <Col md="4">
                          <VideoCard
                            image={training.url_img_thumbnail}
                            typeImage={training.url_img_topic}
                            link={`/training/stage-details/${training.id}`}
                            title={training.title}
                            completion={training.completion}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                </Category>
              );
            })
          )}
        </div>
      </Block>
    </Page>
  );
}

export default Library;
