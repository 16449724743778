import React from "react";
import styled from "styled-components/macro";
import SubTitle from "./SubTitle";
import { Line } from "rc-progress";

const CustomSubTitle = styled(SubTitle)`
  display: flex;
`;

const LoadingBar = styled.div`
  margin-left: 50px;
  margin-top: -15px;
  width: 180px;
  font-size: 18px;
  font-weight: bold;
  > svg {
    height: 10px;
  }
`;

const LoadingBarPercentage = styled.div`
  text-align: center;
`;

function TrainingSubtitle({ children, completion }) {
  return (
    <CustomSubTitle>
      <div>{children}</div>
      {completion > -1 ? (
        <LoadingBar>
          <LoadingBarPercentage>{completion} %</LoadingBarPercentage>
          <Line
            percent={completion}
            strokeWidth={6}
            trailWidth={6}
            strokeColor="#FFFFFF"
            trailColor="#9181DA"
          />
        </LoadingBar>
      ) : null}
    </CustomSubTitle>
  );
}

export default TrainingSubtitle;
