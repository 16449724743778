import React, { useState } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Helmet from "react-helmet";
import { createBrowserHistory } from "history";
import styled, { ThemeProvider } from "styled-components/macro";
import { GridThemeProvider } from "styled-bootstrap-grid";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BaseCSS } from "styled-bootstrap-grid";
import "./styles/main.css";
import TagManager from "react-gtm-module";
import { map } from "lodash";
import theme from "./theme";
import Routes from "./routes";
import GlobalStyle from "./globalStyle";
import Sidebar from "./components/Sidebar";
import ScrollToTop from "./components/ScrollToTop";
import Footer from "./components/Footer";
import { QueryCache, ReactQueryCacheProvider } from "react-query";

export const history = createBrowserHistory();

const queryCache = new QueryCache();

const AppStyled = styled.div`
  -webkit-font-smoothing: antialiased;
`;

const gridTheme = {
  col: {
    padding: 10,
  },
};

export const LiteContext = React.createContext();

TagManager.initialize({
  gtmId: "GTM-5V6VGW8",
});

function PrivateRoute({ component: Component, title, ...rest }) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Route
        {...rest}
        render={(props) => {
          if (
            !window.localStorage.getItem("token") ||
            window.localStorage.getItem("token") === null
          ) {
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location },
                }}
              />
            );
          }

          return <Component {...props} />;
        }}
      />
    </>
  );
}

function NormalRoute({ component: Component, title, ...rest }) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Route
        {...rest}
        render={(props) => {
          if (
            (window.localStorage.getItem("token") ||
              window.localStorage.getItem("token") !== null) &&
            props.location.pathname === "/login"
          ) {
            return (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: props.location },
                }}
              />
            );
          }
          return <Component {...props} />;
        }}
      />
    </>
  );
}

function App() {
  const [lite, setLite] = useState(true);

  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <AppStyled>
        <GlobalStyle />
        <BaseCSS />
        <ToastContainer />
        <LiteContext.Provider value={{ lite, setLite }}>
          <ThemeProvider theme={theme}>
            <GridThemeProvider gridTheme={gridTheme}>
              <Router history={history}>
                <ScrollToTop>
                  <Switch>
                    {map(Routes(), (route, index) => {
                      return route !== undefined && route.private === false ? (
                        <NormalRoute key={index} {...route} />
                      ) : (
                        <PrivateRoute key={index} {...route} />
                      );
                    })}
                  </Switch>
                </ScrollToTop>
                <Sidebar />
                <Footer />
              </Router>
            </GridThemeProvider>
          </ThemeProvider>
        </LiteContext.Provider>
      </AppStyled>
    </ReactQueryCacheProvider>
  );
}

export default App;
