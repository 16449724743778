import {CheckboxInput, CheckboxLabel} from "./inputs/Checkbox";
import React, {useState} from "react";
import styled from "styled-components/macro";
import Button from "./Button";
import Modal from "./Modal";
import learningEnd from "../images/learning-end.png";
import {sendLearningCompletionEmail} from "../api";
import {Link} from "react-router-dom";

const CustomButton = styled(Button)`
    background: none;
    border: 1px solid black;
    color: black;
    border-radius: 0;
    margin-top: 1rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    cursor: pointer;
    
    &:disabled {
      background: #9c9c9c;
      cursor: default;
      color: #4a4a4a;
    }
`;

const CustomModal = styled(Modal)`
    background: white !important;
    color: dark;
`;

function LearningEndModal() {
  const [showModal, setShowModal] = useState(true);

  let customButton = React.createRef();

  return (
    <CustomModal open={showModal} lightStyle={true}>
      <h1 style={{textAlign:'center', fontSize:'3rem'}}>BRAVO !</h1>
      <img src={learningEnd} alt="Fin de votre formation" style={{width:'100%',height:'100%'}}/>
      <p style={{textAlign:'center', marginTop:'1rem', marginBottom:'1rem'}}>
        Vous venez de terminer votre formation ! Recevez votre certification par email et participez au tirage au sort
        pour peut-être gagner un des 5 vélos électriques en jeu.
      </p>

      <CheckboxInput
        type="checkbox"
        id="conditionsOfParticipation"
        name="conditionsOfParticipationCheckbox"
        onChange={ e => {
          if (e.target.checked) {
            customButton.current.disabled = false;
          } else {
            customButton.current.disabled = true;
          }
        } }
      />
      <CheckboxLabel htmlFor="conditionsOfParticipation">
        <span>J'ai lu et j'accepte <Link to={`/content/conditions-de-participation`}>les Conditions de participations</Link> au tirage au sort.</span>
      </CheckboxLabel>

      <CustomButton ref={customButton} onClick={() => {
        sendLearningCompletionEmail();
        setShowModal(false);
      }}>
        Envoyez
      </CustomButton>
    </CustomModal> );
}

export default LearningEndModal;