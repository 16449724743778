import React from "react";
import { Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components/macro";
import { map } from "lodash";

import { trainingTheme } from "../../theme";

import Routes from "../../routes";

function Training() {
  return (
    <ThemeProvider theme={trainingTheme}>
      <Switch>
        {map(Routes().training.routes, (route, index) => {
          return (
            <Route
              exact={route.exact}
              key={index}
              path={route.path}
              component={route.component}
            />
          );
        })}
      </Switch>
    </ThemeProvider>
  );
}

export default Training;
