import React from "react";
import styled from "styled-components/macro"; // eslint-disable-line
import Modal from "react-modal";
import ReactSVG from "react-svg";
import crossIcon from "../svgs/cross.svg";

const customStyles = {
  overlay: {
    background: "rgba(46, 56, 75, 0.86)",
    zIndex: 100,
  },
  content: {
    color: "#fff",
    border: 0,
    background: "#2E384B",
    padding: "60px",
    borderRadius: "30px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    maxWidth: "560px",
  },
};

const lightStyles = {
  ...customStyles,
  content: {
    ...customStyles.content,
    background: "#fff",
    color: "inherit",
  },
};

export default function ResumeModal({
  open,
  lightStyle = false,
  simulatorTheme = false,
  children,
}) {
  let specTheme = null;

  if (lightStyle) {
    specTheme = simulatorTheme
      ? { ...lightStyles, content: { ...customStyles.content, padding: "0px" } }
      : lightStyles;
  }

  return (
    <Modal
      isOpen={open}
      contentLabel="reprendre simulation"
      style={specTheme ? specTheme : customStyles}
    >
      {children}
    </Modal>
  );
}

export function ClassicModal({
  open,
  contentLabel,
  children,
  overflow,
  closeModal,
  title = null,
  bgColor,
  textColor,
}) {
  return (
    <Modal
      overlayClassName="flex items-center justify-center fixed inset-0 z-50 bg-gray-900 bg-opacity-80 max-h-screen"
      className={`${overflow ? "overflow-y-scroll" : ""} ${
        bgColor ? bgColor : "bg-gray-900"
      } ${
        textColor ? textColor : "text-white"
      } rounded-2xl border-0  max-w-screen-xl max-h-9/10`}
      isOpen={open}
      contentLabel={contentLabel}
    >
      <div
        className={`p-2 relative ${
          title ? "orange-gradient rounded-t-2xl" : ""
        }`}
      >
        {title && <div className="px-2 text-white">{title}</div>}
        <div className="absolute inset-0 flex justify-end p-2">
          <button onClick={closeModal}>
            <ReactSVG src={crossIcon} />
          </button>
        </div>
      </div>
      <div className="px-8 py-4">{children}</div>
    </Modal>
  );
}
