import { round } from "lodash";

export function formatAmountPrice(amount) {
  return typeof amount === 'number' ? amount.toLocaleString("fr-FR") + " €" : "- €";
}

export function formatAmountPerMonth(amount) {
  return amount.toLocaleString("fr-FR") + " /mois";
}

export function getNumberPercent(numerator, denominator) {
  return round((numerator / denominator) * 100);
}

export function roundAndFormatNumber(number, precision) {
  return round(number, precision).toLocaleString("fr-FR");
}
