import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ReactSVG from "react-svg";
import styled from "styled-components/macro";
import { get, map, some } from "lodash";
import {
  Tab as BaseTab,
  Tabs as BaseTabs,
  TabList as BaseTabList,
  TabPanel as BaseTabPanel
} from "react-tabs";
import { Row, Col } from "styled-bootstrap-grid";

import Page from "../layouts/Page";

import Block from "../components/Block";
import Title from "../components/Title";
import SubTitle from "../components/SubTitle";
import Content from "../components/Content";
import Loader from "../components/Loader";

import pdfIcon from "../svgs/pdf.svg";
import ecoPetzLogo from "../svgs/ecoPetzLogo.svg";
import IconBlueThumb from "../svgs/icon-blue-thumb.svg";
import anah from "../svgs/anah.svg";
import citeIcon from "../svgs/cite.svg";
import cross from "../svgs/cross.svg";

import { getToolboxInfos } from "../api";

const PDF = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-bottom: 15px;
  svg {
    margin-right: 10px;
    width: 22px;
    height: 29px;
  }
`;

const Image = styled.div`
  width: 170px;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #f7f7f8;
  margin-bottom: 10px;
  border-radius: 10px;

  img {
    height: auto;
    max-height: 100%;
    width: 100%;
  }
`;

const Decoration = styled.div`
  margin-top: -120px;
  margin-left: 15px;
  margin-bottom: 15px;
  float: right;
  a {
    color: #fff;
    text-decoration: none;
  }
`;

const Tabs = styled(BaseTabs)`
  margin-top: 70px;
`;

const TabList = styled(BaseTabList)`
  display: flex;
  flex-wrap: wrap;
  margin-top: -70px;
`;

const TabPanel = styled(BaseTabPanel)`
  margin-top: 0;
`;

const Tab = styled(BaseTab)`
  background: #f7f7f8;
  padding: 15px;
  border-radius: 25px;
  min-width: 250px;
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;

  &:empty {
    display: none;
  }

  &:not(:last-child) {
    margin-right: 22px;
  }

  &.react-tabs__tab--selected {
    background: #00acb4;
    color: #fff;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 5px;
  margin-top: 0;
`;

const Th = styled.th`
  background: #00b5bb;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  vertical-align: middle;
  text-align: center;
  padding: 20px;
  border-right: 1px solid rgba(255, 255, 255, 0.44);
  position: sticky;
  top: 0;
  &:first-child {
    text-align: left;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-right: 0 none;
  }
`;
const Td = styled.td`
  width: ${({ width }) => (width ? width : "auto")};
  padding: 20px;
  background-color: #f7f7f8;
  text-align: center;
  border-right: 1px solid rgba(151, 151, 151, 0.35);
  vertical-align: middle;
  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-right: 0 none;
  }
`;

const Badge = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff715a;
  box-shadow: 1px 3px 4px 0 rgba(0, 0, 0, 0.2);

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    width: 13px;
    height: 13px;
  }
`;

const Partner = styled.div`
  background: #f6f6f6;
  border-radius: 10px;
  padding: 40px 16px 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 240px;
  }
`;

const CITEContent = styled(Content)`
  font-size: 12px;
  strong {
    font-size: 18px;
  }
`;

const TopContent = styled(Content)`
  padding-right: 200px;
  img {
    vertical-align: middle;
  }
`;

function TableWrapper({ lines }) {
  const tableRef = useRef(null);

  if (!lines) return null;
  const displayEcoPTZ = some(lines, line => line.eco_ptz !== true);
  const displayAnah = some(lines, line => line.anah !== true);

  return (
    <div
      css={`
        overflow-x: auto;
        width: 100%;
        height: 80vh;
      `}
    >
      <Table ref={tableRef}>
        <thead>
          <tr>
            <Th>Travaux</Th>
            <Th>Coût moyen*</Th>
            <Th>Eco d'énergie moyenne/an**</Th>
            {displayEcoPTZ ? <Th>Eco PTZ</Th> : null}
            <Th>CITE</Th>
            <Th>
              Coup
              <br />
              de
              <br />
              pouce
            </Th>
            {displayAnah ? (
                <Th>
                  Anah
                  <br />
                  Habiter
                  <br />
                  Mieux
                  <br />
                  Agilité
                </Th>
            ): null}
            <Th>Prérequis avant travaux</Th>
          </tr>
        </thead>
        <tbody>
          {map(lines, (line, index) => {
            return (
              <tr key={index}>
                <Td>
                  <strong
                    dangerouslySetInnerHTML={{
                      __html: line.title
                    }}
                  />
                </Td>
                <Td
                  css={`
                    white-space: nowrap;
                  `}
                >
                  <strong>{line.average_cost}</strong>
                </Td>
                <Td
                  css={`
                    white-space: nowrap;
                  `}
                >
                  <strong>{line.eco_energy}</strong>
                </Td>
                {displayEcoPTZ ? (
                  <Td>
                    {line.eco_ptz ? (
                      <ReactSVG src={ecoPetzLogo} />
                    ) : (
                      <Badge>
                        <ReactSVG src={cross} />
                      </Badge>
                    )}
                  </Td>
                ) : null}
                <Td
                  css={`
                    min-width: 250px;
                  `}
                >
                  {line.cite && line.cite.available ? (
                    <>
                      <ReactSVG src={citeIcon} />
                      <CITEContent
                        dangerouslySetInnerHTML={{
                          __html: line.cite.content
                        }}
                      />
                    </>
                  ) : (
                    <Badge>
                      <ReactSVG src={cross} />
                    </Badge>
                  )}
                </Td>
                <Td>
                  {line.boost ? (
                    <ReactSVG src={IconBlueThumb} />
                  ) : (
                    <Badge>
                      <ReactSVG src={cross} />
                    </Badge>
                  )}
                </Td>
                {displayAnah ? (
                    <Td>
                      {line.anah ? (
                        <ReactSVG src={anah} />
                      ) : (
                        <Badge>
                          <ReactSVG src={cross} />
                        </Badge>
                      )}
                    </Td>
                ) : null}
                <Td
                  css={`
                    text-align: left;
                  `}
                >
                  <strong
                    dangerouslySetInnerHTML={{
                      __html: line.before_work
                    }}
                  />
                </Td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

function ToolBoxContent({
  match: {
    params: { type, id }
  }
}) {
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getToolboxInfos(type, id);
        setData(data);
      } catch (error) {
        console.error(error.response);
      }
    };
    fetchData();
  }, [type, id]);

  return (
    <Page>
      {data ? (
        <>
          <Title>{get(data, "title") || get(data, "type_of_work.label")}</Title>
          <SubTitle>
            <Link to={`/toolbox`}>Boite à outils</Link> |{" "}
            <strong>
              {get(data, "title") || get(data, "type_of_work.label")}
            </strong>
          </SubTitle>
        </>
      ) : null}
      <Block
        css={`
          position: relative;
        `}
      >
        {!data ? (
          <Loader />
        ) : (
          <>
            <Decoration>
              {data.pdf ? (
                <PDF>
                  <ReactSVG src={pdfIcon} />
                  <a
                    href={get(data, "pdf.url")}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Télécharger la fiche
                  </a>
                </PDF>
              ) : null}
              <Image>
                <img
                  src={get(data, "img.url")}
                  alt={get(data, "img.title", "")}
                />
              </Image>
            </Decoration>

            <TopContent
              className="wysiwyg"
              dangerouslySetInnerHTML={{ __html: data.content }}
            />

            <TableWrapper lines={get(data, "table", false)} />

            {data.page ? (
              <Tabs>
                <TabList>
                  {map(data.page, page => (
                    <Tab key={page.id}>{page.title}</Tab>
                  ))}
                </TabList>

                {map(data.page, page => {
                  return (
                    <TabPanel key={page.id}>
                      <Content
                        className="wysiwyg"
                        dangerouslySetInnerHTML={{ __html: page.content }}
                      />
                      <TableWrapper
                        lines={get(page, "table_type_of_works", false)}
                      />
                      {get(page, "partners") ? (
                        <Row
                          css={`
                            margin-top: 50px;
                          `}
                        >
                          {map(get(page, "partners"), partner => {
                            return (
                              <Col
                                md="3"
                                css={`
                                  display: flex;
                                  margin-bottom: 35px;
                                `}
                              >
                                <Partner key={partner.id}>
                                  <img src={partner.url} alt="" />
                                </Partner>
                              </Col>
                            );
                          })}
                        </Row>
                      ) : null}
                    </TabPanel>
                  );
                })}
              </Tabs>
            ) : null}
          </>
        )}
      </Block>
    </Page>
  );
}

export default ToolBoxContent;
