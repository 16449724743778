import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components/macro";
import ReactSVG from "react-svg";
import { Link } from "react-router-dom";
import { map, debounce } from "lodash";

import { SidebarRoutes as sidebarRoutes } from "../routes";
import { SimulatorButton, SidebarButton, LightButton } from "./Button";
import logo from "../svgs/logo.svg";
import logoLite from "../svgs/pepz-logo-lite.svg";
import logoutIcon from "../svgs/logout.svg";
import collapseIcon from "../svgs/collapse.svg";
import {logout, useGetLimitedLearning} from "../api";
import Modal from "../components/Modal";
import { LiteContext } from "../App";

import parseJwt from "../utils/parseJwt";

const Wrapper = styled.nav`
  background-color: ${props => props.theme.color.dark};
  height: 100vh;
  width: ${props => (props.lite ? "300px" : "100px")};
  position: fixed;
  top: 0;
  left: 0;
  flex-direction: column;
  transition: width 250ms;
`;
const Logo = styled(Link)`
  display: block;
  padding: ${props => (props.lite ? "55px 85px 40px" : "50px 40px")};
`;

const ButtonSide = styled(SidebarButton)`
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.06) 100%
  );
  color: #fff;
  margin-left: 20px;
  margin-right: 20px;
  justify-content: ${props => (props.lite ? "flex-start" : "center")};

  svg {
    margin-right: ${props => (props.lite ? "17px" : 0)};
  }
`;


const Logout = styled.div`
  position: absolute;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
  color: #626568;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  display: flex;
  flex-direction: column;

  @media screen and (max-height: 665px) {
    position: static;
    transform: none;
    margin: 10px 20px 0;
    display: flex;
    justify-content: center;
  }
`;

const LogoutBtn = styled.button`
  display: inline-flex;
  margin-top: 10px;
  align-items: center;
  background: transparent;
  color: #fff;
  font-size: 14px;
  border: 0;
  cursor: pointer;

  &:hover {
    > div {
      background: ${props => props.theme.color.primary};
    }
  }

  > div {
    width: 28px;
    height: 28px;
    background: #3b4456;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    & + span {
      margin-left: 13px;
    }
    > div {
      height: 100%;
      display: flex;
      align-items: center;
    }
    svg {
      width: 15px;
      height: 15px;
    }
  }
`;
const CollapseBtn = styled.button`
  background: transparent;
  border: 0;
  padding: 15px;
  transform: ${props => (props.lite ? "" : "rotate(180deg)")};
  margin: 0 auto;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 1;
  svg {
    margin-right: 5px;
  }
`;

function Sidebar({ className }) {
  const { data:limitedLearning } = useGetLimitedLearning();

  const { lite, setLite } = useContext(LiteContext);

  const [showModal, setShowModal] = useState(false);

  const userEmail = parseJwt(window.localStorage.getItem("token")) ? parseJwt(window.localStorage.getItem("token"))['username'] : null;

  useEffect(() => {
    const cb = debounce(e => {
      const y = window.pageYOffset;
      if (lite.y !== y && lite.open === true) {
        setLite({ ...lite, open: false, y });
      }
    }, 200);
    document.addEventListener("wheel", cb);
    return () => {
      document.removeEventListener("wheel", cb);
    };
  }, [setLite, lite]);

  return (
      <Wrapper
          className={className}
          lite={lite.open}
          onClick={function(e) {
            setLite({
              ...lite,
              open: true
            });
          }}
      >
        <CollapseBtn
            onClick={e => {
              e.stopPropagation();
              setLite({
                ...lite,
                open: !lite.open
              });
            }}
            lite={lite.open}
        >
          <ReactSVG src={collapseIcon} />
          {lite.open ? "Réduire le menu" : null}
        </CollapseBtn>

        <Logo to="/" lite={lite.open}>
          <ReactSVG src={lite.open ? logo : logoLite} />
        </Logo>
        {map(sidebarRoutes(), (route, index) => {
          if ((limitedLearning === null || limitedLearning === true) && route.path === "/simulator") {
            return null;
          }
          return (
            <ButtonSide
              lite={lite.open}
              key={index}
              to={route.path}
              activeClassName="active"
              exact={route.exact || false}
              background={route.background}
            >
              {route.icon ? <ReactSVG src={route.icon} /> : null}
              {lite.open ? route.label : null}
            </ButtonSide>
          );
        } )}
        <Logout>
          {lite.open ?
              <div className="user-infos">
                  <p>{userEmail}</p>
              </div> : null }
          <LogoutBtn onClick={() => {
            setShowModal(true);
          }}>
            <ReactSVG src={logoutIcon} />
            {lite.open ? <span>Déconnexion</span> : null}
          </LogoutBtn>
        </Logout>

        <Modal open={showModal}>
          <SimulatorButton
              onClick={() => {
                setShowModal(false);
                logout();
              }}
          >
            Oui, je me déconnecte
          </SimulatorButton>
          <LightButton
              css={`
            margin-top: 30px;
            color: #ff8b5a;
          `}
              onClick={() => {
                setShowModal(false);
              }}
          >
            non, je retourne sur le site
          </LightButton>
        </Modal>
      </Wrapper>
  );
}

export default styled(Sidebar)``;
