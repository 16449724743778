import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { map, get } from "lodash";

const Item = styled(NavLink)`
  background: #dc6e55;
  color: #fff;

  padding: 20px 30px;
  text-decoration: none;
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
  line-height: 22px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d6583b;

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: 0;
  }

  @media (min-width: 1300px) {
    font-size: 12px;
    border-radius: 4px;
    border-bottom: 0;
  }

  &::before {
    content: "";
    display: inline-block;
    height: 12px;
    width: 12px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiI+ICA8ZGVmcz4gICAgPGNsaXBQYXRoIGlkPSJjbGlwLXBhdGgiPiAgICAgIDxyZWN0IHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0ibm9uZSIvPiAgICA8L2NsaXBQYXRoPiAgPC9kZWZzPiAgPGcgaWQ9IlBpY3Rvc19DaGVjayIgZGF0YS1uYW1lPSJQaWN0b3MvQ2hlY2siIGNsaXAtcGF0aD0idXJsKCNjbGlwLXBhdGgpIj4gICAgPHBhdGggaWQ9IkZpbGxfMSIgZGF0YS1uYW1lPSJGaWxsIDEiIGQ9Ik0uMjE5LDguNzEyQTEuMjI3LDEuMjI3LDAsMCwxLDAsNy45NTgsMS4yMjcsMS4yMjcsMCwwLDEsLjIxOSw3LjJMMS4zNzUsNS43YS42NC42NCwwLDAsMSwuNTQ3LS4zMzVBLjcxNi43MTYsMCwwLDEsMi41LDUuN0w2LDEwLjM4NywxMy41LjMzNUEuNzE2LjcxNiwwLDAsMSwxNC4wNzgsMGEuNjQxLjY0MSwwLDAsMSwuNTQ3LjMzNWwxLjE1NiwxLjUwOEExLjIyNiwxLjIyNiwwLDAsMSwxNiwyLjZhMS4yMjYsMS4yMjYsMCwwLDEtLjIxOS43NTRMNi41NjIsMTUuNjY1YS42MzkuNjM5LDAsMCwxLTEuMTI1LDBaIiBmaWxsPSIjZmZmIi8+ICA8L2c+PC9zdmc+);
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 5px;
  }

  ${props =>
    props.disabled
      ? `
    background: #fff;
    opacity: 0.5;
    color: #262626;
    pointer-events: none;
    &::before {
      content: none;
    }
`
      : ""}
`;

export const StepList = styled.nav`
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 50px;
  box-shadow: 1px 2px 20px 0 rgba(47,59,82,0.08);
  background: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  
  @media (min-width: 1300px) {
    display: flex;
    box-shadow: none;
    background: none;
  }

  ${Item} {
    &.active {
      opacity: 1;
      background: #fff;
      color: #262626;
      &::before {
        content: none;
      }
    }
  }
`;

function StepListComponent({ steps, available }) {
  return (
    <StepList>
      {map(steps, (step, index) => (
        <Item
          key={index}
          to={step.path}
          activeClassName="active"
          disabled={!get(available, `${step.key}`, false)}
        >
          {step.label}
        </Item>
      ))}
    </StepList>
  );
}

export default StepListComponent;
