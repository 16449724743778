import Block from "./Block";
import React from "react";

export default function BlockWithTitle({ title, children }) {
  return (
    <Block>
      <div className="text-3xl leading-6 pb-8 uppercase font-bold">{title}</div>
      <div className="flex flex-col gap-4">{children}</div>
    </Block>
  );
}
