import { get, map, toArray } from "lodash";
import Input from "./Input";
import Loader from "react-loader-spinner";
import React, { useEffect, useState } from "react";
import Select from "./Select";
import { useTownWithZipCode } from "../../api";
import styled from "styled-components/macro";
import { connect } from "formik";
import { ClassicModal } from "../Modal";

const InputGrid = styled.div`
  display: flex;
  flex-wrap: wrap;

  > ${Input} {
    width: 50%;
    padding: 0 40px;
    text-align: left;
  }
`;

const TownContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;

  [class*="singleValue"] {
    overflow: visible;
  }
`;

const TownLoader = styled.div`
  margin-top: 3.5rem;
`;

const TownSelect = styled(Select)`
  width: 100% !important;
`;

const SelectZipCodeAndTown = connect(
  ({
    properties,
    formik: {
      values: { clientINSEECode, buildingZipCode },
      setFieldValue,
    },
  }) => {
    const { data: towns, isLoading } = useTownWithZipCode(buildingZipCode);

    useEffect(() => {
      // Auto select town if only 1 town for the zip code
      if (
        towns.length === 1 &&
        clientINSEECode !== towns[0]["insee"] &&
        !["2A", "2B"].includes(towns[0]?.department)
      ) {
        setFieldValue("clientINSEECode", towns[0]["insee"]);
      }
    }, [towns, clientINSEECode, setFieldValue]);

    return (
      <InputGrid>
        {get(properties, "buildingZipCode") ? (
          <Input
            placeholder="Merci d'indiquer votre code postal"
            type={properties.buildingZipCode.type}
            field={properties.buildingZipCode}
            title={properties.buildingZipCode.title}
            name="buildingZipCode"
          />
        ) : null}
        <TownContainer>
          {get(properties, "clientINSEECode") ? (
            isLoading ? (
              <TownLoader>
                <Loader
                  type="ThreeDots"
                  color="#2E384B"
                  height="30"
                  width="50"
                />
              </TownLoader>
            ) : (
              <TownsSelect properties={properties} towns={towns} />
            )
          ) : null}
        </TownContainer>
      </InputGrid>
    );
  }
);

function TownsSelect({ properties, towns }) {
  let isCorsica = false;
  if (["2A", "2B"].includes(towns[0]?.department)) {
    isCorsica = true;
  }
  const [showModal, setShowModal] = useState(isCorsica);
  return (
    <>
      <TownSelect
        field={properties.clientINSEECode}
        name="clientINSEECode"
        title={properties.clientINSEECode.title}
        className="clientINSEECode"
        placeholder="Sélectionnez une commune"
        noOptionsMessage={() =>
          isCorsica
            ? "Merci de renseigner un autre Code Postal du lieu des travaux"
            : "Merci de renseigner le champ Code postal du lieu des travaux"
        }
        choices={
          towns && !isCorsica
            ? toArray(
                map(towns, (option) => {
                  return {
                    value: option.insee,
                    label: option.townName,
                  };
                })
              )
            : []
        }
      />

      <ClassicModal
        open={showModal}
        title={<div className="font-bold">Attention</div>}
        bgColor="bg-white"
        closeModal={() => {
          setShowModal(false);
        }}
      >
        <div className="text-center text-gray-900 pb-6 pt-2">
          La Corse ne fait malheureusement pas partie des régions concernées par
          le programme Pepz.
        </div>
        <div className="flex justify-center">
          <button
            className={`orange-gradient p-2 rounded-md min-w-10`}
            onClick={() => {
              setShowModal(false);
            }}
          >
            OK
          </button>
        </div>
      </ClassicModal>
    </>
  );
}

export default SelectZipCodeAndTown;
