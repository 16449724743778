import React, { useContext } from "react";

import { Link } from "react-router-dom";
import { LiteContext } from "../App";
import styled from "styled-components/macro";

const FooterLink = styled(Link)`
  color: #fff;
  margin: 0 15px;
  text-decoration: none;

  &:not(:last-child) {
    &::after {
      content: "|";
      margin-left: 15px;
    }
  }
`;

const Wrapper = styled.div`
  background: gray;
  color: #fff;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${(props) => {
    return props.noSidebar === true ? "0" : `${props.lite ? "300px" : "100px"}`;
  }};
`;

function Footer({ className }) {
  const { lite } = useContext(LiteContext);
  return (
    <Wrapper lite={lite.open} className={className}>
      <FooterLink to={`/content/mentions-legales`}>Mentions légales</FooterLink>
      <FooterLink to={`/content/conditions-generales-dutilisation`}>
        CGU
      </FooterLink>
      <FooterLink to={`/content/qui-sommes-nous`}>Qui sommes nous ?</FooterLink>
      <FooterLink to={""} onClick={() => window.cookiesFunction()}>
        Politique Cookies
      </FooterLink>
    </Wrapper>
  );
}

export default styled(Footer)``;
