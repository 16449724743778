import React from "react";
import styled from "styled-components/macro";
import ReactSVG from "react-svg";
import { Link } from "react-router-dom";
import { get, map, filter } from "lodash";
import Routes from "../routes";

import Accordion from "./Accordion";
import BigLink from "./BigLink";
import Loader from "./Loader";

// import formIcon from "../svgs/form.svg";
import bookIcon from "../svgs/book.svg";

const ExtraLinks = styled.div`
  display: flex;
  margin-top: 45px;
  ${BigLink} {
    width: 300px;
    + ${BigLink} {
      margin-left: 25px;
    }

    &:last-child {
      svg {
        height: 60px;
      }
    }
  }
`;

function FAQ({ data, location }) {
  const dataWithIds = map(get(data, "questions_frequentes"), (el, id) => {
    return {
      ...el,
      id: el.id || id
    };
  });

  const filteredData = filter(dataWithIds, el => {
    if (location.pathname === "/help/tool") {
      return el.categorie === "aide-outil";
    }
    if (location.pathname === "/help/faq") {
      return el.categorie === "faq";
    }

    return false;
  });

  return (
    <div>
      {data ? <Accordion items={filteredData} /> : <Loader />}
      <ExtraLinks>
        {/* 
        <BigLink as={Link} to={"/"}>
          <ReactSVG src={formIcon} />
          <span>Formulaires et démarches</span>
        </BigLink>
        */}
        <BigLink as={Link} to={Routes().glossary.path}>
          <ReactSVG src={bookIcon}/>
          <span>Glossaire technique</span>
        </BigLink>
      </ExtraLinks>
    </div>
  );
}

export default FAQ;
