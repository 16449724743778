import Error from "../inputs/Error";
import { Field } from "formik";
import React, { useEffect } from "react";
import ReactSelect from "react-select";
import styled from "styled-components/macro";
import { find, get, filter } from "lodash";
export const SelectWrapper = styled.div`
  margin-top: 25px;
  label {
    display: block;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 10px;
  }
`;

const customStyles = {
  control: (provided) => ({
    ...provided,
    padding: 7,
  }),
};

export const SelectField = ({
  choices,
  field,
  form: { setFieldValue, setErrors, errors },
  placeholder,
  ...props
}) => {
  if (null === placeholder) {
    placeholder = "Sélectionner...";
  }
  const selectValue = find(choices, (option) => option.value === field.value);
  const choicesString = JSON.stringify(choices);
  useEffect(() => {
    if (!selectValue?.value) {
      setFieldValue(field.name, null);
    }
  }, [choicesString, selectValue?.value, setFieldValue, field.name]);

  useEffect(() => {
    if (!!get(errors, `${field.name}`, false) && !!selectValue) {
      setErrors(
        filter(errors, (e, fieldName) => {
          return fieldName !== field.name;
        })
      );
    }
  }, [selectValue, errors, field.name, setErrors]);

  return (
    <ReactSelect
      styles={customStyles}
      options={choices}
      name={field.name}
      value={choices ? selectValue ?? "" : ""}
      onChange={(option) => setFieldValue(field.name, option.value)}
      onBlur={field.onBlur}
      placeholder={placeholder}
      {...props}
    />
  );
};

function Select({
  title,
  name,
  className,
  choices,
  placeholder,
  field,
  ...props
}) {
  return (
    <SelectWrapper className={`select-${name} ${className ? className : ""}`}>
      {title ? (
        <label htmlFor={name}>
          <div dangerouslySetInnerHTML={{ __html: title }} />
        </label>
      ) : null}
      <Field
        name={name}
        component={SelectField}
        choices={choices}
        placeholder={placeholder}
        {...props}
      />
      <Error name={name} />
    </SelectWrapper>
  );
}

export default Select;
