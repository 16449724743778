import styled from "styled-components/macro";

const Content = styled.div`
  color: #7c7c7d;
  font-size: 17px;
  line-height: 24px;

  ol {
    display: block;
    list-style-type: decimal;
    padding-left: 20px;
  }
`;

export default Content;
