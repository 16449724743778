import styled from "styled-components/macro";

const Block = styled.div`
  background: #ffffff;
  box-shadow: 1px 2px 20px 0 rgba(47, 59, 82, 0.08);
  border-radius: 10px;
  padding: 45px;
  min-height: 100%;
`;

export default Block;
