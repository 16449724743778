import { Col, Row } from "styled-bootstrap-grid";
import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";

import Block from "../components/Block";
import ContentSidebar from "../components/ContentSidebar";
import Page from "../layouts/Page";
import Routes from "../routes";
import Title from "../components/Title";
import { getHelp } from "../api";
import { map } from "lodash";

function Help(props) {
  const [data, setData] = useState(null);

  useEffect(() => {
    async function getData() {
      try {
        const { data } = await getHelp();
        setData(data);
      } catch (error) {
        console.error(error);
      }
    }
    getData();
  }, []);

  if (props.match.path === Routes().help.path && props.match.isExact) {
    props.history.push("/help/faq");
  }

  return (
    <Page>
      <Title>Besoin d'aide</Title>
      <Block>
        <Row>
          <Col md="3">
            <ContentSidebar items={Routes().help.routes} />
          </Col>
          <Col md="9">
            <Switch>
              {map(Routes().help.routes, (route, index) => {
                const C = route.component;
                return (
                  <Route
                    key={index}
                    path={route.path}
                    render={(props) => <C {...props} data={data} />}
                  />
                );
              })}
            </Switch>
          </Col>
        </Row>
      </Block>
    </Page>
  );
}

export default Help;
