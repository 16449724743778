import React, { useEffect, useState } from "react";
import ReactSVG from "react-svg";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { get, size, map } from "lodash";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import queryString from "query-string";

import LoginPage from "../layouts/LoginPage";
import { SimulatorButton as Button } from "../components/Button";
import { GradientInput } from "../components/inputs/BasicInput";

import { BasicError } from "../components/inputs/Error";

import lock from "../svgs/lock.svg";

import { getCsrfToken, login } from "../api";
import {
  generateApiScript,
} from "../utils/googleReCaptcha";

const SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;

const LoginSchema = Yup.object().shape({
  password: Yup.string().required("Requis"),
  email: Yup.string().email("E-mail invalide").required("Requis"),
});

const ResetPasswordLink = styled(Link)``;

const ResetPassword = styled.div`
  margin-top: 10px;
  text-align: right;
  ${ResetPasswordLink} {
    color: #fff;
    text-decoration: none;
    opacity: 0.34;
  }
`;

function Login(props) {
  const [errors, setError] = useState(false);
  const { email } = queryString.parse(props.location.search);
  useEffect(() => {
    generateApiScript();
  }, []);
  return (
    <LoginPage>
      <p
        css={`
          font-size: 18px;
          margin-bottom: 20px;
        `}
      >
        Connexion à votre compte
      </p>
      <Formik
        initialValues={{
          email: email || "",
          password: "",
        }}
        validationSchema={LoginSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            setError(false);
            const { token: csrfToken } = await getCsrfToken("login");
            const token = window.grecaptcha.getResponse();
            await login({
              data: {
                ...values,
                "_token": csrfToken,
                "g-recaptcha-response": token,
              },
            });
            setSubmitting(false);
            props.history.push("/");
            window.location.reload(false);
          } catch (error) {
            setError(get(error, `response.data.login_form.errors`, false));
          }
        }}
        render={() => {
          return (
            <Form>
              <GradientInput
                type="email"
                name="email"
                placeholder="Adresse e-mail"
              />

              <GradientInput
                name="password"
                type="password"
                placeholder="Mot de passe"
                suffix={<ReactSVG src={lock} />}
              />

              <ResetPassword>
                <ResetPasswordLink to="/password">
                  Mot de passe oublié
                </ResetPasswordLink>
              </ResetPassword>

              {errors && size(errors) > 0
                ? map(errors, (error, i) => (
                    <BasicError key={i} message={error} />
                  ))
                : null}
              <div className="g-recaptcha" data-sitekey={SITE_KEY}></div>

              <div
                css={`
                  text-align: center;
                  margin-top: 50px;
                `}
              >
                <Button type="submit">connexion</Button>
              </div>
            </Form>
          );
        }}
      />
    </LoginPage>
  );
}

export default Login;
