import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { map } from "lodash";
import { Row, Col } from "styled-bootstrap-grid";

import BaseBlock from "./Block";
import ContentDate from "./ContentDate";
import NewsLabel from "./NewsLabel";

import { formatDate } from "../helpers";

const Block = styled(BaseBlock)`
  padding: 18px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const BlockTitle = styled.div`
  font-size: 20px;
  color: #262626;
  margin-bottom: 18px;
  line-height: 22px;
  width: 100%;

  &::after {
    content: "";
    display: block;
    width: 70px;
    height: 1px;
    background: ${props => props.theme.mainGradient};
    margin-top: 10px;
  }
`;
const BlockImg = styled.div`
  min-height: 193px;
  background-size: cover;
  background-position: center;
  width: 100%;
`;

const BlockTop = styled.div`
  padding: 0 18px;
`;

function NewsBlock({ id, date, title, image, labels, children }) {
  return (
    <Link
      to={`/news/${id}`}
      css={`
        text-decoration: none;
      `}
    >
      <Block>
        <BlockTop>
          <Row>
              <Col sm={4}>
                <ContentDate>{formatDate(new Date(date))}</ContentDate>
              </Col>
              <Col
                sm={8}
                css={`
                  text-align: right;
                `}
              >
                {map(labels, (label, index) => (
                  <NewsLabel color={label.color} key={index}>
                    {label.title}
                  </NewsLabel>
                ))}
              </Col>
         </Row>
            <BlockTitle>{title}</BlockTitle>
          </BlockTop>
          <BlockImg style={{backgroundImage: `url('${image}')`}}  />
        {children ? <div>{children}</div> : null}
      </Block>
    </Link>
  );
}

export default NewsBlock;
