import Error from "../inputs/Error";
import { Field } from "formik";
import React from "react";
import Tooltip from "../Tooltip";
import styled from "styled-components/macro";

export const Suffix = styled.div`
  padding: 15px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0,-50%);
  pointer-events: none;
`;

export const InputWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  position: relative;
  color: #3B3F42;
  font-size: 17px;
  line-height: 18px;
  display: flex;
  align-items: center;

  input {
    height: 50px;
    padding: 15px 58px 15px 20px;
    border: 0;
    border-radius: 4px;
    width: 100%;
    font-size: 17px;
    line-height: 18px;
  }
  
  .customIcon {
    right: 20px;
    
    & + ${Suffix} {
      right: 40px !importnt;
    }
  }
`;

export const BasicInputWrapper = styled.div`
  margin-top: 25px;
  label {
    display: block;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 10px;
    
    > div { display: inline-block; }
    
    ${Tooltip} {
      margin-left: 5px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .customIcon {
    color: ${props => props.theme.color.primary};
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0,-50%);
    width: 14px;
    
    &+ ${Suffix} {
      right: 50px;
    }
  }
`;

export const HelpMessage = styled.div`
  opacity: 0.34;
  margin: 7px 0 30px;
  font-size: 14px;
  line-height: 17px;
`;

function BasicInput({
  key,
  label,
  placeholder,
  name,
  type,
  suffix,
  className,
  helpMessage,
  validate,
  readOnly,
  handleChange,
  tooltip,
  customIcon,
  displayError = true,
  ...rest
}) {
  function handleWheel(e) {
    if (e.target.type === "number") {
      e.target.blur();
    }
  }

  return (
    <BasicInputWrapper
      key={key}
      className={`input-${name} ${className ? className : ""}`}
    >
      {label ? (
        <label htmlFor={name}>
          <div dangerouslySetInnerHTML={{ __html: label }} />
          {tooltip ? <Tooltip tooltip={tooltip} /> : ""}
        </label>
      ) : null}

      <div>
        <InputWrapper>
          <Field name={name}>
            {({ field }) => (
              <input
                {...field}
                validate={validate}
                type={type}
                name={name}
                value={field.value}
                placeholder={placeholder}
                readOnly={readOnly}
                onChange={e => {
                  if (handleChange !== undefined) {
                    handleChange(e);
                  }
                  field.onChange(e);
                }}
                onWheel={handleWheel}
                onKeyDown={e => {
                  if (e.target.type === "number" && e.keyCode === 69) {
                    e.preventDefault();
                  }
                }}
                onFocus={e => {
                  if (e.target.value === "0") {
                    e.target.value = "";
                    field.onChange(e);
                  }
                }}
              />
            )}
          </Field>
          {customIcon ? (
            <svg className="customIcon" viewBox="0 0 512 512">
              <path fill="currentColor"
                d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM124.1 339.9c-5.5-5.5-5.5-14.3 0-19.8l154-154c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8l-154 154c-5.5 5.5-14.3 5.5-19.8 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z"></path>
            </svg>
          ) : null}
          <Suffix>
            {typeof suffix === "string" ? (
              <div dangerouslySetInnerHTML={{ __html: suffix }} />
            ) : (
              suffix
            )}
          </Suffix>

        </InputWrapper>

        {displayError && (<Error name={name} />)}
        {helpMessage && <HelpMessage>{helpMessage}</HelpMessage>}
      </div>
    </BasicInputWrapper>
  );
}

export const GradientInput = styled(BasicInput)`
  margin-top: 10px;
  ${InputWrapper} {
    border-color: transparent;
    background-image: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.04) 100%
    );

    input {
      color: #ffffff;
      background: transparent;
      &::placeholder {
        color: #fff;
      }
    }
  }
  ${Suffix} {
    color: #fff;
  }
`;

export default BasicInput;
