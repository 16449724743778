import React from "react";
import BlockStat, {
  NumberStat,
  PercentageBlock,
  PercentageLine,
} from "./BlockStat";
import traingIcon from "../../svgs/training.svg";
import { map, upperCase } from "lodash";

const textColor = "text-purple-500";

export default function DirectionLearningBlock({ learnings }) {
  const {
    timeSpent = 0,
    nbAgencyWithAdvisorTrained = 0,
    fullVideos = 0,
    globalCompletion = 0,
    stagesCompletion = [],
  } = learnings;
  return (
    <BlockStat title="Formation" textColor={textColor} icon={traingIcon}>
      <div className="grid lg:grid-cols-2 gap-4">
        <NumberStat
          textColor={textColor}
          text={
            timeSpent > 1
              ? "minutes passées en moyenne"
              : "minute passée en moyenne"
          }
          number={timeSpent}
        />
        <NumberStat
          textColor={textColor}
          text={`agence${
            nbAgencyWithAdvisorTrained > 1 ? "s" : ""
          } avec au moins un conseiller formé`}
          number={nbAgencyWithAdvisorTrained}
        />
      </div>
      <LearningPercentageBlock percent={globalCompletion} number={fullVideos} />
      {map(stagesCompletion, ({ completion, title, fullVideos }, key) => (
        <LearningPercentLine
          key={key}
          stageTitle={title}
          percent={completion}
          nbVideos={fullVideos}
        />
      ))}
    </BlockStat>
  );
}

function LearningPercentLine({ stageTitle, percent, nbVideos }) {
  return (
    <PercentageLine
      text={"Parcours " + upperCase(stageTitle)}
      percent={percent}
      bgColor="bg-purple-800"
      subtitle={`${nbVideos} terminée${nbVideos > 1 ? "s" : ""}`}
    />
  );
}

function LearningPercentageBlock({ percent, number }) {
  const Subtitle = (
    <>
      formations terminées
      <br />({percent} %)
    </>
  );
  return (
    <PercentageBlock
      bgColor="bg-gradient-to-br from-purple-400 to-purple-800"
      percent={percent}
      text={number}
      subtitle={Subtitle}
    />
  );
}
