import React from "react";
import styled from "styled-components/macro";

const Prev = styled.span``;
const Next = styled.span``;
const Infos = styled.span`
  font-size: 15px;
  margin: 0 10px;
`;

const Wrapper = styled.div`
  ${Prev}, ${Next} {
    color: #00b5bb;
    font-size: 12px;
    cursor: pointer;
  }
`;

function Paging({ className, current, total, onNext, onPrev }) {
  return (
    <Wrapper className={className}>
      <Prev onClick={onPrev}>◀</Prev>
      <Infos>
        {current}/ {total}
      </Infos>
      <Next onClick={onNext}>▶</Next>
    </Wrapper>
  );
}

export default styled(Paging)``;
