import { Col, Row } from "styled-bootstrap-grid";
import React, { useEffect, useState } from "react";
import { get, map } from "lodash";
import {
  getLearningCompletion,
  getTraining,
  useGetLimitedLearning,
} from "../../api";

import Block from "../../components/Block";
import BtnLibrary from "../../components/BtnLibrary";
import LearningEndModal from "../../components/LearningEndModal";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
import Page from "../../layouts/Page";
import ReactSVG from "react-svg";
import Title from "../../components/Title";
import TrainingSubtitle from "../../components/TrainingSubtitle";
import VideoCard from "../../components/VideoCard";
import pdfIcon from "../../svgs/PDF-white.svg";
import styled from "styled-components/macro";

const IframeWrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  margin-bottom: 40px;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

const Description = styled.div`
  background: #f7f7f8;
  border-radius: 10px;
  padding: 40px;
`;

const DescriptionTitle = styled.h2`
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
`;

const Pdf = styled.a`
  display: block;
  text-decoration: none;
  background: ${(props) => props.theme.mainGradient};
  color: #fff;
  border-radius: 10px;
  padding: 30px 15px;
  text-align: center;

  svg {
    margin-bottom: 15px;
  }
`;

const SidebarTitle = styled.div`
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 15px;
`;

function DiscoverDetails({
  match: {
    params: { id },
  },
}) {
  const [data, setData] = useState(null);

  const [showFinalModal, setShowFinalModal] = useState(null);
  const { data: limitedLearning } = useGetLimitedLearning();

  useEffect(() => {
    async function fetchStage() {
      try {
        const { data } = await getTraining(id);
        setData(data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchStage();
  }, [id]);

  useEffect(() => {
    async function getGlobalCompletion() {
      try {
        const { data } = await getLearningCompletion();
        setShowFinalModal(data.showFinalModal);
      } catch (error) {
        console.error(error);
      }
    }
    getGlobalCompletion();
  }, []);

  return (
    <Page>
      <Title>Formations</Title>

      <TrainingSubtitle completion={get(data, "completion")}>
        {limitedLearning !== null && !limitedLearning && (
          <Link to={"/training"}>Formations | </Link>
        )}
        <Link to={`/training/stage/${get(data, "stage_id", "")}`}>
          {get(data, "stage_title", "")}
        </Link>{" "}
        | <strong>{get(data, "title")}</strong>
      </TrainingSubtitle>

      <BtnLibrary />

      <Block>
        {data ? (
          <Row>
            <Col md="9">
              <IframeWrapper>
                <iframe
                  src={get(data, "url_video")}
                  frameBorder="0"
                  title={get(data, "title")}
                ></iframe>
              </IframeWrapper>
              <Row>
                <Col md={get(data, "url_pdf") ? "9" : "12"}>
                  {get(data, "description") ? (
                    <Description>
                      <DescriptionTitle>Support de cours</DescriptionTitle>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: get(data, "description"),
                        }}
                      />
                    </Description>
                  ) : null}
                </Col>
                {get(data, "url_pdf") ? (
                  <Col md="3">
                    <Pdf href={get(data, "url_pdf")}>
                      <ReactSVG src={pdfIcon} />
                      Télécharger <br /> le PDF
                    </Pdf>
                  </Col>
                ) : null}
              </Row>
            </Col>
            <Col md="3">
              <SidebarTitle>
                Continuez le parcours <strong>{data.stage_title}</strong>
              </SidebarTitle>
              {map(get(data, "next_training"), (training, index) => {
                return (
                  <VideoCard
                    key={index}
                    image={training.url_img_thumbnail}
                    link={`/training/stage-details/${training.id}`}
                    title={training.title}
                    subtitle={training.stage_title}
                    completion={training.completion}
                  />
                );
              })}
            </Col>
          </Row>
        ) : (
          <Loader />
        )}
      </Block>
      {showFinalModal ? <LearningEndModal /> : null}
    </Page>
  );
}

export default DiscoverDetails;
