import React from "react";
import { AidRowTable, HeaderTable, RowTable } from "./TableParts";
import Legend from "./Legend";
import { map } from "lodash";
import { useGetAidsPdf } from "../../api";

export default function NationalHelpTable({ table, code, className = "" }) {
  const { data: dataPdf, isFetched: isFetchedPdf } = useGetAidsPdf();
  const pdf = getPdf(dataPdf, code, isFetchedPdf);
  const captions = getCaption(dataPdf, code, isFetchedPdf);
  return table ? (
    <div>
      <table className="mt-4 NationalHelpTable">
        <HeaderTable hasMpr={table.hasMpr} pdf={pdf} />
        <tbody>
          {map(table.aidTypes, (aidType) => (
            <AidRowTable key={aidType.id} aid={aidType.label} className={className}>
              {map(aidType.aids, (aid) => (
                <RowTable
                  key={aid.id}
                  workType={aid.workType}
                  cost={aid.cost}
                  className={className}
                  cee={aid.cee}
                  mpr={table.hasMpr ? aid.mpr : false}
                />
              ))}
            </AidRowTable>
          ))}
        </tbody>
      </table>
      <Legend mpr={table.hasMpr} min15 captions={captions} />
    </div>
  ) : (
    <div>Aucun tableau disponible.</div>
  );
}

function getPdf(dataPdf, code, isFetched) {
  if (!isFetched) {
    return false;
  }
  return dataPdf?.pdfFiles?.[`pdf-${code}`] ?? null;
}

function getCaption(dataPdf, code, isFetched) {
  if (!isFetched) {
    return false;
  }
  return dataPdf?.captions?.[`caption-${code}`] ?? null;
}
