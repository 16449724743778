import { aidEstimate, getCsrfToken, savingEstimate } from "../../api";
import React, { useEffect, useState } from "react";
import Loader from "../Loader";
import { get, reduce, map } from "lodash";
import Tooltip from "../Tooltip";
import {
  AccordionCell,
  WhiteCell,
  Row,
  SwitchAmount,
} from "./parts/step5Parts";
import AdvantageList from "../AdvantageList";
import {
  calculEstimate,
  calculTotalNoCondition,
  calculTotalWithCondition,
} from "../../utils/simulator";
import { formatAmountPrice } from "../../utils/formatAmount";
import Button, { LightButton } from "../Button";
import Chevron from "../../svgs/chevronSimulator.svg";
import LoaderBase from "react-loader-spinner";
import { useDesktop } from "../../utils/useDesktop";
import CellLocalAids from "./LocalAidsCell";

export default function Step5({ history, handleNoticeFooter }) {
  const [estimation, setEstimation] = useState({});
  const [currentFundings, setCurrentFundings] = useState(null);
  const [nbFundingWC, setNbFundingWC] = useState(0);
  const [totalBonifications, setTotalBonifications] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [bonifications, setBonifications] = useState({
    thermalStrain: 0,
    LEB: 0,
  });
  const [selectedLocalAids, setSelectedLocalAids] = useState([]);
  const [totalLocalAids, setTotalLocalAids] = useState({});
  const fetchData = async () => {
    try {
      const { data = null } = await aidEstimate();

      return new Promise((resolve, reject) => {
        !data ? reject() : resolve(data);
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData()
      .then((response) => {
        if (response === undefined) return;
        setEstimation(response);
        setCurrentFundings(response.fundings);
        const totalFundingWC = reduce(
          get(response, "fundings.with_conditions", []),
          (result, scenario) => (scenario.eligible ? ++result : result),
          0
        );
        setNbFundingWC(totalFundingWC);
      })
      .catch(() => {
        console.log("error");
      });
  }, []);

  useEffect(() => {
    if (bonifications.LEB > 0 && bonifications.thermalStrain > 0) {
      setCurrentFundings(estimation.fundings_both_bonif);
    } else if (bonifications.LEB > 0) {
      setCurrentFundings(estimation.fundings_bonif_LEB);
    } else if (bonifications.thermalStrain > 0) {
      setCurrentFundings(estimation.fundings_bonif_thermalStrain);
    } else {
      setCurrentFundings(estimation.fundings);
    }

    setTotalBonifications(reduce(bonifications, (result, b) => result + b, 0));
  }, [
    bonifications,
    estimation.fundings,
    estimation.fundings_both_bonif,
    estimation.fundings_bonif_LEB,
    estimation.fundings_bonif_thermalStrain,
  ]);

  useEffect(() => {
    handleNoticeFooter({
      isEligibleCond: nbFundingWC > 0,
      isBonif: totalBonifications !== 0,
    });
  }, [nbFundingWC, totalBonifications, handleNoticeFooter]);

  const [estimateNoCondition, estimateWithConditions] = calculEstimate(
    estimation?.globalCost,
    totalBonifications > 0
  );

  function handleBonification(type, value) {
    if (type === "LEB") {
      setBonifications((prevState) => ({
        ...prevState,
        LEB: value ? estimation?.BonifLEBAmount : 0,
      }));
    } else if (type === "thermalStrain") {
      setBonifications((prevState) => ({
        ...prevState,
        thermalStrain: value ? estimation?.BonifThermalStrainAmount : 0,
      }));
    }
  }

  const handleSubmit = async ({
    fundingConditions,
    fundingNumber,
    value,
    aids,
    localAids = 0,
    selectedLocalAids = [],
    bonifType,
  }) => {
    if (typeof value !== "number" || !isFinite(value)) return;
    setButtonLoading(true);
    const { token: csrfToken } = await getCsrfToken("saving-estimate");
    const dataToSend = {
      method: "post",
      data: {
        condition: fundingConditions,
        number: fundingNumber,
        value: value,
        aids: aids,
        localAidsValue: localAids,
        selectedLocalAids: selectedLocalAids,
        bonifType: bonifType,
        "_token": csrfToken,
      },
    };

    try {
      await savingEstimate(value, dataToSend);
      history.push("/simulator/6");
    } catch (error) {
      console.error(error);
    }
    setButtonLoading(null);
  };
  const {
    smallest,
    totalNoCondition,
    totalWithCondition,
  } = getTotalAndSmallest({
    currentFundings,
    bonif: totalBonifications > 0,
    estimationGlobalCost: estimation.globalCost,
    totalLocalAids,
  });
  const isDesktop = useDesktop();
  const bonifType = getBonifType(bonifications);
  const nbLocalAids = getNbLocalAids(currentFundings);
  return currentFundings ? (
    <>
      <div className="flex flex-col gap-y-8">
        <Header nbFundingWC={nbFundingWC} tooltips={estimation.tooltips} />
        {isDesktop && (
          <>
            {/* ROW Estimation du total du projet TTC */}
            <Row label="Estimation du total du projet TTC">
              <TotalProjectCell
                totalBonifications={totalBonifications}
                estimation={formatAmountPrice(estimateNoCondition)}
              />
              {currentFundings.with_conditions
                .filter((scenario, key) => scenario.eligible)
                .map((s, key) => (
                  <TotalProjectCell
                    key={key}
                    totalBonifications={totalBonifications}
                    estimation={formatAmountPrice(estimateWithConditions)}
                    withConditions
                  />
                ))}
            </Row>
            {/* ROW Estimation du total des aides nationales */}
            <Row
              label="Estimation du total des aides nationales"
              sideContent={
                <Bonifications
                  estimation={estimation}
                  handleBonification={handleBonification}
                />
              }
            >
              <TotalNationAids
                scenario={get(currentFundings, "no_conditions[0]")}
                notice={totalBonifications > 0 ? 3 : null}
              />
              {currentFundings.with_conditions
                .filter((scenario) => scenario.eligible)
                .map((scenario, key) => (
                  <TotalNationAids key={key} scenario={scenario} />
                ))}
            </Row>
            {/* ROW  Aides locales */}
            {nbLocalAids > 0 && (
              <Row label="Ajouter un montant d'aides locales potentielles">
                <CellLocalAids
                  scenario={get(currentFundings, "no_conditions[0]")}
                  setSelectedLocalAids={setSelectedLocalAids}
                  selectedAids={selectedLocalAids}
                  prefix="nc"
                  estimation={estimateNoCondition}
                  onChangeTotal={(total) => {
                    setTotalLocalAids((prevState) => ({
                      ...prevState,
                      "nc": total,
                    }));
                  }}
                />
                {currentFundings.with_conditions.map(
                  (scenario, i) =>
                    scenario.eligible && (
                      <CellLocalAids
                        key={i}
                        estimation={estimateWithConditions}
                        scenario={scenario}
                        prefix={`c${i}`}
                        selectedAids={selectedLocalAids}
                        setSelectedLocalAids={setSelectedLocalAids}
                        onChangeTotal={(total) => {
                          setTotalLocalAids((prevState) => ({
                            ...prevState,
                            [`c${i}`]: total,
                          }));
                        }}
                      />
                    )
                )}
              </Row>
            )}
            {/*ROW Estimation du reste à payer*/}
            <Row label="Estimation du reste à payer">
              {totalNoCondition !== null ?  (
                <LeftToPay smallest={smallest}>{totalNoCondition}</LeftToPay>
              ) : null}
              {totalWithCondition.map((scenario, key) => (
                <LeftToPay key={key} smallest={smallest}>
                  {scenario.total}
                </LeftToPay>
              ))}
            </Row>
            {/* FOOTER */}
            <div className="grid grid-cols-7 px-12">
              <div className="col-span-3 flex items-center justify-center">
                <LightButton
                  type="button"
                  onClick={() => history.push("/simulator/4")}
                >
                  Etape précédente
                </LightButton>
              </div>
              <div className="col-span-4">
                <div className={`py-3 grid grid-cols-${nbFundingWC + 1}`}>
                  <OptionChoice
                    buttonLoading={buttonLoading}
                    handleSubmit={handleSubmit}
                    bonifType={bonifType}
                    scenario={get(currentFundings, "no_conditions[0]")}
                    fundingNumber={0}
                    localAids={totalLocalAids}
                    selectedLocalAids={selectedLocalAids}
                  />
                  {currentFundings.with_conditions.map(
                    (scenario, index) =>
                      scenario.eligible && (
                        <OptionChoice
                          buttonLoading={buttonLoading}
                          handleSubmit={handleSubmit}
                          bonifType={bonifType}
                          scenario={scenario}
                          fundingNumber={index}
                          withConditions={true}
                          localAids={totalLocalAids}
                          selectedLocalAids={selectedLocalAids}
                        />
                      )
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        {!isDesktop && (
          <>
            <Row label="Financement sans accompagnement technique">
              <TotalProjectCell
                totalBonifications={totalBonifications}
                estimation={formatAmountPrice(estimateNoCondition)}
              />
              <TotalNationAids
                scenario={get(currentFundings, "no_conditions[0]")}
                notice={totalBonifications > 0 ? 3 : null}
              />
              {totalNoCondition && (
                <LeftToPay smallest={smallest}>{totalNoCondition}</LeftToPay>
              )}
              <OptionChoice
                buttonLoading={buttonLoading}
                handleSubmit={handleSubmit}
                bonifType={bonifType}
                scenario={get(currentFundings, "no_conditions[0]")}
                fundingNumber={0}
                localAids={totalLocalAids}
                selectedLocalAids={selectedLocalAids}
              />
            </Row>
            {currentFundings.with_conditions.map(
              (scenario, key) =>
                scenario.eligible && (
                  <Row
                    label={`Financement avec accompagnement technique n°${
                      key + 1
                    }`}
                  >
                    <TotalProjectCell
                      totalBonifications={totalBonifications}
                      estimation={formatAmountPrice(estimateWithConditions)}
                      withConditions
                    />
                    <TotalNationAids scenario={scenario} />
                    <LeftToPay smallest={smallest}>
                      {get(totalWithCondition, `[${key}].total`)}
                    </LeftToPay>

                    <OptionChoice
                      buttonLoading={buttonLoading}
                      handleSubmit={handleSubmit}
                      bonifType={bonifType}
                      scenario={scenario}
                      fundingNumber={key + 1}
                      withConditions={true}
                      localAids={totalLocalAids}
                      selectedLocalAids={selectedLocalAids}
                    />
                  </Row>
                )
            )}
          </>
        )}
      </div>
    </>
  ) : (
    <div className="flex items-center justify-center min-h-min-h-90vh">
      <Loader />
    </div>
  );
}

function TotalProjectCell({
  totalBonifications,
  estimation,
  withConditions = false,
}) {
  return (
    <WhiteCell
      notice={withConditions ? 2 : totalBonifications > 0 ? 3 : null}
      title="Estimation du total du projet TTC"
    >
      {estimation}
    </WhiteCell>
  );
}

function LeftToPay({ children: total, smallest }) {
  return (
    <WhiteCell
      title="Estimation du reste à payer"
      color={smallest === total ? "text-white" : false}
      bgColor={smallest === total ? "bg-apple-500" : false}
    >
      {formatAmountPrice(total)}
    </WhiteCell>
  );
}

function TotalNationAids({ notice, scenario }) {
  return (
    <div>
      <AccordionCell
        title="Estimation du total des aides nationales
"
        text={get(scenario, "total.formatted")}
        notice={notice}
      >
        <DetailsNationalAids aids={get(scenario, "aids")} />
      </AccordionCell>
      <AdvantageList advantage={get(scenario, "labels")} />
    </div>
  );
}

function getBonifType(bonifications) {
  if (bonifications.LEB > 0 && bonifications.thermalStrain > 0) {
    return "bothBonifs";
  } else if (bonifications.LEB > 0) {
    return "bonifLEB";
  } else if (bonifications.thermalStrain > 0) {
    return "bonifThermalStrain";
  }
  return "noBonif";
}

function OptionChoice({
  buttonLoading,
  fundingNumber,
  scenario,
  bonifType,
  handleSubmit,
  localAids,
  selectedLocalAids,
  withConditions = false,
}) {
  const [currentButtonLoading, setCurrentButtonLoading] = useState(false);
  return (
    <div className="flex flex-col items-center gap-4">
      <img alt="chevron" src={Chevron} className="chevronIcon" width="160" />
      <Button
        className="fixedWidth"
        disabled={buttonLoading}
        onClick={() => {
          setCurrentButtonLoading(true);
          handleSubmit({
            fundingConditions: withConditions
              ? "with_conditions"
              : "no_conditions",
            fundingNumber: fundingNumber,
            value: get(scenario, "total.value"),
            aids: reduce(
              get(scenario, "aids"),
              function (result, value, key) {
                if (value.eligible && value.value > 0) {
                  result[key] = value;
                }
                return result;
              },
              {}
            ),
            index: 1,
            bonifType: bonifType,
            localAids: withConditions
              ? localAids[`c${fundingNumber}`]
              : localAids["nc"],
            selectedLocalAids: selectedLocalAids,
          });
        }}
      >
        {currentButtonLoading ? (
          <LoaderBase
            type="ThreeDots"
            color="#ffffff"
            height="16"
            width="100"
          />
        ) : (
          "Choisir cette option"
        )}
      </Button>
    </div>
  );
}

function getNbLocalAids(currentFundings) {
  const ncLocalAids = get(
    currentFundings,
    "no_conditions[0].local_aids.nb_aides",
    0
  );
  const wcLocalAids = reduce(
    get(currentFundings, "with_conditions", []),
    (result, scenario) => result + get(scenario, "local_aids.nb_aides", 0),
    0
  );
  return ncLocalAids + wcLocalAids;
}

function getTotalAndSmallest({
  estimationGlobalCost,
  bonif,
  currentFundings,
  totalLocalAids,
}) {
  if (!currentFundings) return {};
  const totalNoCondition = calculTotalNoCondition({
    estimationGlobalCost,
    bonif,
    currentFundingsNoCondTotal: currentFundings.no_conditions[0]?.total,
    localAids: totalLocalAids?.nc,
  });
  let smallest = totalNoCondition;
  const totalWithCondition = get(currentFundings, "with_conditions", []).reduce(
    (result, scenario, key) => {
      if (scenario.eligible) {
        const total = calculTotalWithCondition({
          estimationGlobalCost,
          scenarioTotal: scenario.total.value,
          localAids: totalLocalAids[`c${key}`],
        });
        if (smallest > total) smallest = total;
        result.push({
          total,
        });
      }
      return result;
    },
    []
  );
  return {
    smallest,
    totalNoCondition,
    totalWithCondition,
  };
}

function DetailsNationalAids({ aids }) {
  return (
    <div className="flex flex-col justify-center items-center gap-4">
      {map(aids, ({ eligible, name, value, formatted, tooltip }, index) => {
        return eligible && value !== 0 ? (
          <div
            key={index}
            className="flex flex-col justify-center items-center font-bold"
          >
            <span>Aides potentielles</span>
            <span>{name}</span>
            <div className="text-apple-500 text-base flex gap-2">
              {formatted}
              {tooltip ? <Tooltip tooltip={tooltip} /> : null}
            </div>
          </div>
        ) : null;
      })}
    </div>
  );
}

function SingleBonification({ tooltips, amount, onChange, label }) {
  return (
    <>
      <div className="text-sm">
        <div className="font-semibold">Bonification</div>
        <div className="flex gap-x-2">
          <span>{label}</span>
          {tooltips && <Tooltip tooltip={tooltips} />}
        </div>
      </div>
      <SwitchAmount amount={amount} onChange={onChange} />
    </>
  );
}

function Bonifications({
  estimation: {
    fundings_bonif_LEB,
    BonifLEBAmount,
    fundings_bonif_thermalStrain,
    BonifThermalStrainAmount,
    tooltips,
  },
  handleBonification,
}) {
  return (
    <div className="grid gap-y-5 grid-cols-2 pt-5">
      {fundings_bonif_LEB && BonifLEBAmount > 0 && (
        <SingleBonification
          amount={BonifLEBAmount}
          onChange={(value) => {
            handleBonification("LEB", value);
          }}
          label="Batiment basse consommation"
          tooltips={tooltips.lowConsumptionBuildingBonif}
        />
      )}
      {fundings_bonif_thermalStrain && BonifThermalStrainAmount > 0 && (
        <SingleBonification
          amount={BonifThermalStrainAmount}
          onChange={(value) => {
            handleBonification("thermalStrain", value);
          }}
          label="Sortie de passoire thermique"
          tooltips={tooltips.thermalStrainBuildingBonif}
        />
      )}
    </div>
  );
}

function Header({ nbFundingWC, tooltips }) {
  return (
    <div className="grid xl:grid-cols-7">
      <div className="col-start-4 col-span-4">
        <div className="border-b-2 border-gray-900 border-solid flex justify-center p-3 text-lg">
          {nbFundingWC + 1}
          {nbFundingWC > 0
            ? " scénarios de financements possibles"
            : " scénario de financements possible"}
          <sup>(1)</sup>
        </div>
        <div className={`py-3 grid grid-cols-${nbFundingWC + 1}`}>
          <div
            className={`p-3 ${
              nbFundingWC ? "border-r" : ""
            } border-gray-900 border-solid flex items-center text-center`}
          >
            Financement sans accompagnement technique
            {tooltips?.noConditions && (
              <span className="ml-2">
                <Tooltip tooltip={tooltips?.noConditions} />
              </span>
            )}
          </div>
          {nbFundingWC > 0 && (
            <div className={`p-3 col-span-${nbFundingWC} flex items-center`}>
              Financement avec accompagnement technique
              {tooltips?.withConditions && (
                <span className="ml-2">
                  <Tooltip tooltip={tooltips?.withConditions} />
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
