import React, { useState } from "react";
import { map, reduce, size } from "lodash";

import FieldSetIntro from "../FieldSetIntro";
import { GetMatchingWidget } from "./helpers";

function Nested({
  field: { icon, tooltip },
  fields,
  values,
  name,
  title,
  simpleTitle,
  active,
  parent
}) {
  const [fieldSetIntroActive, setFieldSetIntroActive] = useState(
    (reduce(values[name], (acc, next) => acc + size(next), 0) && true) || (active && true)
  );

  const handleFieldSetIntroClick = () => {
    if (fieldSetIntroActive) {
      values[name] = "";
    }
    setFieldSetIntroActive(!fieldSetIntroActive);
  };

  return (
    <div>
      <FieldSetIntro
        tooltip={tooltip}
        icon={icon}
        title={title}
        active={fieldSetIntroActive}
        handleClick={handleFieldSetIntroClick}
      />
      <div>
        {fields && fieldSetIntroActive ?
            map(fields, (field, fieldKey) => {
              return GetMatchingWidget({
                field,
                fieldKey: `${name}[${fieldKey}]`,
                values,
                simpleTitle,
                parent,
                active: fieldSetIntroActive,
                checkPrimaryWorks: fields.primary || null
              });
            })
        : null}
      </div>
    </div>
  );
}

export default Nested;
