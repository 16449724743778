import React from "react";
import ReactSVG from "react-svg";
import styled from "styled-components/macro";
import { map } from "lodash";

import { SidebarButton } from "./Button";

const Link = styled(SidebarButton)`
  svg {
    width: 44px;
    height: 44px;
    fill: #c1c1c1;
    stroke: #c1c1c1;
  }

  &:hover,
  &.active {
    svg {
      fill: #f7f7f8;
      stroke: #f7f7f8;
    }
  }
`;

function ContentSidebar({ className, items }) {
  return (
    <div className={className}>
      {map(items, (item, index) => {
        return (
          <Link
            key={index}
            to={item.path}
            activeClassName="active"
            exact={true}
          >
            <ReactSVG src={item.icon} />
            {item.label}
          </Link>
        );
      })}
    </div>
  );
}

export default ContentSidebar;
