import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import * as Sentry from "@sentry/browser";
import * as serviceWorker from "./serviceWorker";

import App from "./App";
import Modal from "react-modal";
import React from "react";
import ReactDOM from "react-dom";

if (process.env.NODE_ENV !== "production") {
  Sentry.init({
    dsn: "https://f84b0741470d4f1babdaac6dd8f36f71@sentry.io/1826431",
  });
}

Modal.setAppElement("#root");

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
