import React from "react";
import styled from "styled-components/macro";

const Wrapper = styled.div`
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 0.5rem;
`;

function Step5Footer({ noticeFooter = {} }) {
  return (
    <>
      <Wrapper>
        <sup>1</sup> Tous les coûts et chiffres annoncés ici sont des valeurs
        estimatives destinées à fournir des éléments de choix. Ils n’ont pas
        valeur de devis et ne peuvent engager la responsabilité de l’entreprise
        Economie d’Energie SAS.
      </Wrapper>
      {noticeFooter?.isEligibleCond && (
        <Wrapper>
          <sup>2</sup> Ce scénario de financement implique l'intervention d'un
          opérateur d'assistance à maîtrise d'ouvrage dont le montant moyen des
          frais est estimé à 2 000 € TTC ; il implique également la réalisation
          d'une évaluation de la performance énergétique du logement dont le
          montant moyen est estimé à 850 € TTC. Ces montants s'ajoutent ainsi à
          l'estimation du montant total des travaux.
        </Wrapper>
      )}
      {noticeFooter?.isBonif && (
        <Wrapper>
          <sup>3</sup> Ce scénario de financement implique la réalisation d'une
          évaluation de la performance énergétique du logement dont le montant
          moyen est estimé à 850 € TTC et dont l'aide MaPrimeRénov'
          correspondante varie de 0 à 500 € selon le revenus du ménage ; ces
          deux montants s'ajoutent respectivement à l'estimation du montant
          total du projet et à l'estimation du montant total des aides
          nationales.
        </Wrapper>
      )}
    </>
  );
}

export default Step5Footer;
