export function calculGlobalCost(
  { value, AMO, EnergeticAudience },
  { isAMO = false, bonif = false }
) {
  if (isAMO) {
    return parseInt(value) + parseInt(AMO) + parseInt(EnergeticAudience);
  }
  return parseInt(value) + (bonif ? parseInt(EnergeticAudience) : 0);
}

export function calculTotalNoCondition({
  estimationGlobalCost,
  bonif,
  currentFundingsNoCondTotal,
  localAids = 0,
}) {
  const total =
    parseInt(estimationGlobalCost.value) +
    (bonif ? estimationGlobalCost.EnergeticAudience : 0) -
    currentFundingsNoCondTotal.value -
    localAids;
  return total > 0 ? total : 0;
}

export function calculTotalWithCondition({
  estimationGlobalCost: { value: estimationGlobalCost, AMO, EnergeticAudience },
  scenarioTotal,
  localAids = 0,
}) {
  const total =
    estimationGlobalCost + AMO + EnergeticAudience - scenarioTotal - localAids;
  return total > 0 ? total : 0;
}

export function calculEstimate(globalCost, bonif) {
  if (!globalCost) return [0, 0];
  const totalNoCond = calculGlobalCost(globalCost, { bonif: bonif });
  const totalWithCond = calculGlobalCost(globalCost, {
    isAMO: true,
    bonif: bonif,
  });
  return [totalNoCond, totalWithCond];
}
