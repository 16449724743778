import React, { useState } from "react";
import { Field } from "formik";
import { size, map, includes } from "lodash";

import FieldSetIntro from "../FieldSetIntro";
import SimpleTitle from "../SimpleTitle";
import { CheckboxInput, CheckboxLabel } from "../inputs/Checkbox";
import Error from "../inputs/Error";

import Tooltip from "../Tooltip";

function Choice({
  field: { icon, tooltip },
  choices,
  values,
  name,
  title,
  required,
  simpleTitle,
  active,
}) {
  const [fieldSetIntroActive, setFieldSetIntroActive] = useState(
    (size(values[name]) && true) || (active && true)
  );

  const handleFieldSetIntroClick = () => {
    if (fieldSetIntroActive) {
      values[name] = "";
    }
    setFieldSetIntroActive(!fieldSetIntroActive);
  };

  return (
    <div>
      {!simpleTitle ? (
        <FieldSetIntro
          tooltip={tooltip}
          icon={icon}
          title={title}
          active={fieldSetIntroActive}
          handleClick={handleFieldSetIntroClick}
        />
      ) : (
        <SimpleTitle>{title}</SimpleTitle>
      )}

      {choices && fieldSetIntroActive
        ? map(choices, (choice) => {
            return (
              <Field key={choice.value} name={name}>
                {({ field }) => {
                  const labelID = `${field.name}_${choice.value}`;
                  return (
                    <div key={labelID} className="flex justify-between">
                      <div className="flex items-baseline">
                        <CheckboxInput
                          {...field}
                          type="checkbox"
                          id={labelID}
                          name={field.name}
                          checked={includes(values[field.name], choice.value)}
                          onChange={() => {
                            const set = new Set(field.value);
                            if (set.has(choice.value)) {
                              set.delete(choice.value);
                            } else {
                              set.add(choice.value);
                            }
                            field.onChange(field.name)(Array.from(set));
                          }}
                        />
                        <CheckboxLabel htmlFor={labelID}>
                          <span className={choice.class}>{choice.label}</span>
                        </CheckboxLabel>
                      </div>

                      {choice.tooltip ? (
                        <Tooltip tooltip={choice.tooltip} />
                      ) : null}
                    </div>
                  );
                }}
              </Field>
            );
          })
        : null}
      <Error name={name} />
    </div>
  );
}

export default Choice;
