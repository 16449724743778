import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { Row, Col as BaseCol } from "styled-bootstrap-grid";
import { map, get } from "lodash";

import Page from "../../layouts/Page";
import Block from "../../components/Block";
import Title from "../../components/Title";
import LearningCard from "../../components/LearningCard";
import LibraryBanner from "../../components/LibraryBanner";
import Loader from "../../components/Loader";

import { getTrainings } from "../../api";

const Col = styled(BaseCol)`
  display: flex;
`;

function Landing() {
  const [data, setData] = useState(null);

  useEffect(() => {
    async function fetchTrainings() {
      try {
        const { data } = await getTrainings();
        setData(data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchTrainings();
  }, []);

  return (
    <Page>
      <Title>Formations</Title>
      <Block>
        {data ? (
          <>
            <Row>
              {map(get(data, "stages"), stage => {
                return (
                  <Col xl="4" key={stage.id}>
                    <LearningCard
                      link={`/training/stage/${stage.id}`}
                      title={stage.title}
                      subtitle={stage.sub_title}
                      image={stage.url_img}
                      duration={stage.approximate_duration}
                      percentage={stage.completion}
                    />
                  </Col>
                );
              })}
            </Row>

            <Link
              to={`/training/library`}
              css={`
                text-decoration: none;
                margin-top: 30px;
                display: block;
              `}
            >
              <LibraryBanner />
            </Link>
          </>
        ) : (
          <Loader />
        )}
      </Block>
    </Page>
  );
}

export default Landing;
