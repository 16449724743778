import React from "react";
import styled from "styled-components/macro";
import BasicInput from "../inputs/BasicInput";

function Input({
  className,
  error,
  value,
  name,
  title,
  type,
  validate,
  suffix,
  placeholder,
  readOnly,
  handleChange,
  tooltip,
  customIcon,
  displayError,
}) {
  return (
    <BasicInput
      className={className}
      name={name}
      label={title}
      value={value}
      type={type}
      error={error}
      validate={validate}
      suffix={suffix}
      placeholder={placeholder}
      readOnly={readOnly}
      handleChange={handleChange}
      tooltip={tooltip}
      customIcon={customIcon}
      displayError={displayError}
    />
  );
}

export default styled(Input)``;
