import React, { useState } from "react";
import styled from "styled-components/macro";
import ReactSVG from "react-svg";
import Popover, { ArrowContainer } from "react-tiny-popover";

import QuestionIcon from "../svgs/question.svg";

const IconWrapper = styled.div`
  svg {
    width: 16px;
    height: 16px;
  }
  &:hover,
  &:focus {
    path {
      fill: ${(props) => props.theme.color.dark};
    }
  }
`;

const CustomToolTip = styled.div`
  max-width: 250px;
  background: #2e384b;
  color: #fff;
  padding: 15px;
  border-radius: 3px;
  font-size: 15px;
  opacity: 0.9;
  font-family: "Source Sans Pro";
  a {
    color: #ff8b5a;
    text-decoration: none;
  }

  &.founding {
    background: #fff;
    color: #2e384b;
  }
`;

const CustomToolTipTravaux = styled(CustomToolTip)`
  max-width: 440px;
  text-align: left;
  opacity: 1;
  line-height: 140%;
  padding: 15px 21px;
  strong {
    display: block;
    font-family: "Source Sans Pro";
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 1rem;
    margin-bottom: 10px;
    color: #fff;
  }
`;

function Tooltip({ tooltip, extra, className, id = "", founding }) {
  const [open, setOpen] = useState(false);

  return (
    <div className={className}>
      <Popover
        isOpen={open}
        position={id === "choix-travaux" ? "bottom" : "top"}
        onClickOutside={() => setOpen(false)}
        disableReposition={id === "choix-travaux" ? true : false}
        content={({ position, targetRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            targetRect={targetRect}
            popoverRect={popoverRect}
            arrowColor={"#2e384b"}
            arrowSize={5}
            arrowStyle={{ opacity: "0.9" }}
          >
            {id === "choix-travaux" ? (
              <CustomToolTipTravaux
                dangerouslySetInnerHTML={{ __html: tooltip }}
              />
            ) : (
              <CustomToolTip
                className={founding ? "founding" : null}
                dangerouslySetInnerHTML={{ __html: tooltip }}
              />
            )}
          </ArrowContainer>
        )}
      >
        <IconWrapper
          onClick={() => {
            setOpen(!open);
          }}
        >
          <ReactSVG src={QuestionIcon} />
        </IconWrapper>
      </Popover>
    </div>
  );
}

export default styled(Tooltip)``;
