import { simulatorTheme, trainingTheme } from "../theme";

import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";

const Button = styled.button`
  border: 0;
  border-radius: 26px;
  padding: 18px 45px;
  background: ${props => props.theme.mainGradient};
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  font-weight: bold;
  line-height: 120%;

  &:focus {
    outline: none;
  }

  &.fixedWidth {
    max-width: 10rem;
    margin: 0 auto;
    min-height: 70px;
    padding: 18px;
    border-radius: 12px;
  }

  &.chosen {
    filter: brightness(70%);
  }
`;

export const SimulatorButton = styled(Button)`
  background: ${simulatorTheme.mainGradient};
`;

export const TrainingButton = styled(Button)`
  background: ${trainingTheme.mainGradient};
`;

export const LightButton = styled(Button)`
  background: #fff;
  border: 1px solid #e3e3e3;
  color: #262626;
  
`;

const BasicLink = styled(NavLink)``;

export const SidebarButton = styled(BasicLink)`
  display: flex;
  align-items: center;
  color: ${props => props.theme.color.dark};
  background: #f7f7f8;
  text-decoration: none;

  padding: 5px 10px;
  border-radius: ${props => props.theme.borderRadius};
  min-height: 50px;

  + ${BasicLink} {
    margin-top: 10px;
  }

  &:hover,
  &.active {
    background: ${props =>
      props.background ? props.background : props.theme.mainGradient};
    color: #fff;
  }

  svg {
    margin-right: 17px;
    fill: #fff;
    stroke: #fff;
    width: 32px;
    height: 32px;
  }
`;

export default Button;
