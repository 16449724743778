import styled from "styled-components/macro";

const ContentTitle = styled.div`
  font-size: 28px;
  margin-bottom: 10px;

  a {
    color: inherit;
    text-decoration: none;
  }
`;

export default ContentTitle;
