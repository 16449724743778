import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";

import LoginPage, { Wrapper } from "../layouts/LoginPage";
import Title from "../components/Title";
import Block from "../components/Block";
import Loader from "../components/Loader";
import Content from "../components/Content";
import { get, head } from "lodash";

import { getPageBySlug } from "../api";

const Page = styled(LoginPage)`
  ${Title} {
    color: #000;
  }
  ${Wrapper} {
    width: 90%;
    max-width: 960px;
    position: relative;
  }
  ${Content} {
    p {
      margin-bottom: 15px;
    }
  }
`;

const Button = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  border-radius: 10px;
  background: ${props => props.theme.color.gray};
  height: 40px;
  width: 40px;
  color: #fff;
  font-size: 25px;
  cursor: pointer;
  border: none;
  &:hover {
    background: ${props => props.theme.color.primary};
  }
`;

function ContentPage({
  history,
  match: {
    params: { slug }
  }
}) {
  const [page, setPage] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const { data } = await getPageBySlug(slug);
      setPage(head(data));
    };
    getData();
  }, [slug]);

  return (
    <Page
      css={`
        height: auto;
        min-height: calc(100vh - 50px);
      `}
    >
      <Block
        css={`
          margin-bottom: 50px;
          position: relative;
        `}
      >
        <Button onClick={() => history.push("/")}>✕</Button>
        <Title>
          {get(page, "title.rendered") ? get(page, "title.rendered") : ""}
        </Title>
        {page ? (
          <Content
            dangerouslySetInnerHTML={{ __html: get(page, "content.rendered") }}
          />
        ) : (
          <Loader />
        )}
      </Block>
    </Page>
  );
}

export default ContentPage;
