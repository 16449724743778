import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import Button from "./Button";
import Input from "./FormWidgets/Input";
import Textarea from "./FormWidgets/Textarea";
import Modal from "../components/Modal";
import { contact, getCsrfToken } from "../api";
import { generateApiScript } from "../utils/googleReCaptcha";
import { toast } from "react-toastify";

const SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;

function Contact(props) {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    generateApiScript();
  }, []);
  function validText(value) {
    let error;
    if (!value) {
      error = "ce champs est requis";
    }
    return error;
  }

  async function formSubmit(val) {
    try {
      const token = window.grecaptcha.getResponse();
      const { token: csrfToken } = await getCsrfToken("contact");
      const response = await contact({ ...val, token, csrfToken });
      if (response?.data) {
        setShowModal(true);
      } else {
        toast.error("Une erreur c'est produite");
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Formik
      initialValues={{
        subject: "",
        message: "",
      }}
      onSubmit={(values) => {
        formSubmit(values);
      }}
      render={({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <p>
            Contactez le service par email en utilisant le formulaire ci-dessous
            ou par téléphone au 01 81 69 30 76 (numéro non surtaxé).
          </p>
          <Input
            component="input"
            name="subject"
            title="Sujet"
            type="string"
            validate={validText}
          />
          <Textarea
            component="textarea"
            name="message"
            title="Message"
            type="string"
            validate={validText}
            css={`
              display: block;
              width: 100%;
              margin-bottom: 1rem;
            `}
          />
          <div className="g-recaptcha" data-sitekey={SITE_KEY}></div>
          <div
            css={`
              text-align: center;
              margin-top: 1rem;
            `}
          >
            <Button type="submit" disabled={isSubmitting}>
              Envoyer le mail
            </Button>
          </div>
          <Modal open={showModal}>
            <span
              css={`
                text-align: center;
              `}
            >
              Votre message a bien été envoyé
            </span>
            <Button
              css={`
                margin-top: 30px;
                background: ;
              `}
              onClick={async () => {
                props.history.push("/help");
              }}
            >
              Fermer
            </Button>
          </Modal>
        </form>
      )}
    />
  );
}

export default Contact;
