import Button, { LightButton } from "../components/Button";
import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { get, map } from "lodash";

import Block from "../components/Block";
import Modal from "../components/Modal";
import Page from "../layouts/Page";
import Routes from "../routes";
import StepList from "../components/StepList";
import { ThemeProvider } from "styled-components/macro";
import Title from "../components/Title";
import { getSimulationStatus } from "../api";
import queryString from "query-string";
import { simulatorTheme } from "../theme";

function Simulator(props) {
  const [showModal, setShowModal] = useState(false);
  const [simulationStatus, setSimulationStatus] = useState({});
  const [inSimulation, setInSimulation] = useState(false);
  const [noticeFooter, setNoticeFooter] = useState(null);
  const qs = queryString.parse(props.location.search);
  const reset = get(qs, "reset");
  const continu = get(qs, "continue");

  const { simulationPending = null, availableSteps } = simulationStatus;
  useEffect(() => {
    async function PendingSimulation() {
      try {
        const { data } = await getSimulationStatus();
        setSimulationStatus(data);
        setInSimulation(true);
      } catch (error) {
        console.error(error);
      }
    }
    PendingSimulation();
  }, [props.location.pathname]);

  useEffect(() => {
    if (reset === "1" || continu === "1") {
      setShowModal(false);
      setSimulationStatus({});
      return;
    }

    if (simulationPending === true && !inSimulation) {
      setShowModal(true);
    }
  }, [
    continu,
    simulationPending,
    props.location.pathname,
    reset,
    inSimulation,
  ]);

  if (props.match.path === Routes().simulator.path && props.match.isExact) {
    props.history.push("/simulator/1");
  }

  return (
    <ThemeProvider theme={simulatorTheme}>
      <Page>
        <Title>Simulateur d'aides</Title>
        <StepList
          steps={Routes().simulator.routes}
          available={availableSteps}
        />
        <Block>
          <Modal open={showModal}>
            <LightButton onClick={() => setShowModal(false)}>
              Reprendre la simulation en cours
            </LightButton>
            <Button
              css={`
                margin-top: 30px;
              `}
              onClick={async () => {
                props.history.push("/simulator/1?reset=1");
              }}
            >
              Démarrer une nouvelle simulation
            </Button>
          </Modal>
          <Switch>
            {map(
              Routes().simulator.routes,
              ({ component: RouteComponent, ...route }, index) => {
                return (
                  <Route
                    key={index}
                    {...route}
                    render={(props) => (
                      <RouteComponent
                        {...props}
                        handleNoticeFooter={setNoticeFooter}
                      />
                    )}
                  />
                );
              }
            )}
          </Switch>
        </Block>
        <Switch>
          {map(Routes().simulator.routes, (route, index) => {
            const FooterComponent = route.footer;
            return route.footer ? (
              <Route key={index} path={route.path}>
                <div
                  key={index}
                  css={`
                  margin-top: 50px;
                `}
                >
                  <FooterComponent noticeFooter={noticeFooter} />
                </div>
              </Route>
            ) : null;
          })}
        </Switch>
      </Page>
    </ThemeProvider>
  );
}

export default Simulator;
