import React, { useState, useEffect } from "react";
import styled, { ThemeProvider } from "styled-components/macro";
import ReactSVG from "react-svg";
import { Row, Col } from "styled-bootstrap-grid";

import { simulatorTheme, trainingTheme } from "../theme";

import {
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import Page from "../layouts/Page";
import Block from "../components/Block";
import Button from "../components/Button";
import Title from "../components/Title";

import BigLink from "../components/BigLink";

import piggybank from "../images/piggybank.png";
import computer from "../images/computer.png";
import toolboxThin from "../svgs/toolbox-thin.svg";
import radioThin from "../svgs/radio-thin.svg";

import {getSimulationCount, getLearningCompletion, useGetLimitedLearning} from "../api";
import Routes from "../routes";
import LastNews from "../components/LastNews";

const SimulatorBlock = styled(Block)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #fff;
  background-image: url(${piggybank});
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 55%;
  ${Button} {
    max-width: 330px;
    width: 100%;
    padding: 18px 5px;

    margin-bottom: 10px;

    @media (max-width: 1300px) {
      max-width: 100%;
    }
  }
`;
const FeaturedNumber = styled.div`
  text-align: center;
  width: 50%;
  margin: 60px 0;
  font-size: 18px;
  line-height: 20px;
  span {
    font-size: 42px;
    font-weight: 600;
    line-height: 55px;
    color: ${props => props.theme.color.primary};
    display: block;
  }
`;

const TrainingBlock = styled(Block)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #fff;
  background-image: url(${computer});
  background-repeat: no-repeat;
  background-position: right 60%;
  background-size: 50%;
  height: 100%;
  ${Button} {
    width: 100%;
    @media screen {
    }
  }
`;

const TrainingBlockTop = styled.div``;

const TrainingBlockNumber = styled.div`
  width: 50%;
  max-width: 210px;
  height: 210px;
  margin-top: 50px;
  > div {
    height: 100%;
  }
`;

const Links = styled(Col)`
  display: flex;
  flex-direction: column;

  ${BigLink} {
    flex: 1;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

function SimulatorBLock({ history }) {
  const [count, setCount] = useState(null);

  useEffect(() => {
    async function getCount() {
      try {
        const { data } = await getSimulationCount();
        setCount(data);
      } catch (error) {
        console.error(error);
      }
    }
    getCount();
  }, []);

  return (
    <ThemeProvider theme={simulatorTheme}>
      <SimulatorBlock className="col-md-6">
        <Title>Simulateur d'aides</Title>
        <Button
          onClick={() => {
            history.push("/simulator/1?reset=1");
          }}
        >
          Démarrer une nouvelle simulation
        </Button>
        <Button
          onClick={() => {
            history.push("/simulator/1?continue=1");
          }}
        >
          Reprendre la simulation en cours
        </Button>
        {count ? (
          <FeaturedNumber>
            <span>{count}</span>
            simulations <br />
            effectuées
          </FeaturedNumber>
        ) : null}
      </SimulatorBlock>
    </ThemeProvider>
  );
}

function LearningBlock({ history, limited = null }) {
  const [globalCompletion, setGlobalCompletion] = useState(null);

  useEffect(() => {
    async function getGlobalCompletion() {
      try {
        const { data } = await getLearningCompletion();
        setGlobalCompletion(Math.floor(data.globalCompletion));
      } catch (error) {
        console.error(error);
      }
    }
    getGlobalCompletion();
  }, []);

  return (
      <ThemeProvider theme={trainingTheme}>
        <TrainingBlock>
          <TrainingBlockTop>
            <Title>Formation</Title>
            <Button
                onClick={() => {
                  history.push("/training");
                }}
            >
              Accèder à la formation
            </Button>
          </TrainingBlockTop>
          {globalCompletion ? (
              <TrainingBlockNumber>
                <CircularProgressbarWithChildren
                    value={globalCompletion}
                    styles={buildStyles({
                      pathColor: "#9488CB",
                      trailColor: "#E3E3E3"
                    })}
                >
                  <FeaturedNumber>
                    <span>{globalCompletion}%</span>
                    de formation <br/>
                    réalisée
                  </FeaturedNumber>
                </CircularProgressbarWithChildren>
              </TrainingBlockNumber>
          ) : null}
        </TrainingBlock>
      </ThemeProvider>
  );
}

function Home({ history }) {
  const { data:limitedLearning } = useGetLimitedLearning();

  return (
    <Page noBackground>
      <Row style={{ marginTop: "-20px" }}>
        {undefined === limitedLearning || true === limitedLearning.limited ? null
        : (
            <Col className="m-t-1" xl="5" lg="6">
              <SimulatorBLock history={history} />
            </Col>
          )}

        <Col className="m-t-1" xl={undefined === limitedLearning || limitedLearning.limited === true ? 10 : 5} lg="6">
          <LearningBlock history={history} limited={undefined !== limitedLearning ? limitedLearning.limited : true} />
        </Col>
        <Links className="m-t-1" xl="2">
          <ThemeProvider theme={simulatorTheme}>
            <BigLink to="/toolbox">
              <ReactSVG src={toolboxThin} />
              <span>Boite à outils</span>
            </BigLink>
          </ThemeProvider>
          {
              <ThemeProvider theme={trainingTheme}>
                <BigLink to="/news">
                  <ReactSVG src={radioThin} />
                  <span>Actualités</span>
                </BigLink>
              </ThemeProvider>
          }
          <BigLink to={Routes().help.path}>
            <ReactSVG src={Routes().help.icon} />
            <span>{Routes().help.label}</span>
          </BigLink>
        </Links>
      </Row>

      {
          <LastNews />
      }
    </Page>
  );
}

export default Home;
