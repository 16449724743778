import styled from "styled-components/macro";

import iconCheckMark from "../../svgs/checkmark.svg";

export const CheckboxInput = styled.input`
  /* Base for label styling */
  &:not(:checked),
  &:checked {
    position: absolute;
    left: -9999px;
  }
  &:not(:checked) + label,
  &:checked + label {
    position: relative;
    padding-left: 1.95em;
    cursor: pointer;
    margin-bottom: 10px;
    flex: 1;
  }

  /* checkbox aspect */
  &:not(:checked) + label:before,
  &:checked + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 2px solid #e3e3e3;
    background: #fff;
    border-radius: 2px;
  }
  /* checked mark aspect */
  &:not(:checked) + label:after,
  &:checked + label:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    color: ${props => props.theme.color.primary};
    transition: all 0.2s;
    background-image: url(${iconCheckMark});
    background-size: 90%;
    background-position: center center;
  }
  /* checked mark aspect changes */
  &:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }
  &:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }
  /* disabled checkbox */
  &:disabled:not(:checked) + label:before,
  &:disabled:checked + label:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #ddd;
  }
  &:disabled:checked + label:after {
    color: #999;
  }
  &:disabled + label {
    color: #aaa;
  }
`;

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 17px;
  line-height: 20px;
  color: ${props => props.theme.color.gray};
  margin-right: 10px;
  span {
    width: 100%;
    vertical-align: middle;
    [class^="Tooltip-"] {
      margin-left: 3px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  svg {
    width: 16px;
    height: 16px;
  }
  path {
    fill: #9e9e9e;
  }
`;
