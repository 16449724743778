import styled from "styled-components/macro";
import { Link } from "react-router-dom";

const BigLink = styled(Link)`
  background: ${props => props.theme.mainGradient};
  border-radius: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 20px;
  line-height: 22px;
  padding: 22px 10px;
  text-decoration: none;
  margin-bottom: 20px;
  letter-spacing: -0.1;

  svg {
    width: 52px;
    fill: #fff;
    stroke: #fff;
  }

  .graphThin svg {
    width: 70px;
  }
  .bookIcon svg {
    width: 70px;
    stroke: none;
  }
  .formIcon svg {
    width: 60px;
    stroke: none;
  }

  span {
    margin-top: 15px;
    text-align: center;
  }
`;

export default BigLink;
