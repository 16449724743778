import Error from "../inputs/Error";
import { Field } from "formik";
import Radio from "../inputs/Radio";
import React from "react";
import Tooltip from "../Tooltip";
import { map } from "lodash";
import styled from "styled-components/macro";

export const RadioGroupLabel = styled.div`
  font-size: 18px;
  line-height: 22px;
  text-align: center;
`;

export const RadioGroupItems = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const RadioGroup = styled.div`
    margin-top: 60px;

    ${Radio} + ${Radio} {
        margin-left: 15px;
    }
    &.m-0 {
      margin-top: 0px;
    }
    &.smallField {
      
      ${Radio} {
        margin-top: 10px;
        
        input + label {
          padding: 1rem 2rem;
          border-radius: 60px;
        }
      }
    }
    
    ${Error} {
      max-width: 450px;
    }
    
    ${Tooltip} {
      margin-left: 3px;
      display: inline-block;
      vertical-align: middle;
    }
`;



export const SwitchRadio = styled(Radio)``;

function SwitchGroup({ choices, name, title, className, tooltip = "", classNameLabel = ""}) {
  return (
    <RadioGroup className={`switch-${name} ${className ? className : ""}`}>
      <RadioGroupLabel className={classNameLabel}>{title} {tooltip ? <Tooltip tooltip={tooltip} /> : ""}</RadioGroupLabel>
      <RadioGroupItems>
        {map(choices, choice => (
          <Field
            key={choice.value}
            component={SwitchRadio}
            name={name}
            id={choice.value}
            label={choice.label}
            icon={choice.icon}
          />
        ))}
      </RadioGroupItems>
      <Error name={name} />
    </RadioGroup>
  );
}

export default styled(SwitchGroup)``;
