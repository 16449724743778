import React from "react";
import { map, drop } from "lodash";
import { formatAmountPrice, getNumberPercent } from "../../utils/formatAmount";
import pdfIcon from "../../svgs/PDF-white.svg";
import ReactSVG from "react-svg";
import Loader from "../Loader";

const headers = [
  { label: "Types de travaux" },
  { label: "Coût moyen équipement et pose (TTC)", notice: "*" },
  {
    label: "Primes CEE",
    subtitle: "pour les logements de plus de 2 ans",
    notice: "**",
  },
  {
    label: "MaPrimeRénov'",
    subtitle: "pour les logements de plus de 15 ans",
    notice: "***",
    mpr: true,
  },
  {
    label: "Pourcentage d'aide sur le montant des travaux",
    percent: true,
  },
  { label: "Reste à charge estimé" },
];

export function HeaderTable({ hasMpr, pdf }) {
  return (
    <thead>
      <tr>
        <th>
          {pdf === false && <Loader />}
          {pdf && (
            <div className="flex justify-center">
              <a
                href={pdf}
                target="_blank"
                rel="noopener noreferrer"
                className="cyan-gradient py-4 px-10 rounded-md text-white flex flex-col items-center"
              >
                <ReactSVG src={pdfIcon} />
                <span className="text-sm mt-2">
                  Télécharger
                  <br />
                  le tableau
                </span>
              </a>
            </div>
          )}
        </th>
        {map(headers, (header, key) =>
          !hasMpr && header.mpr ? null : (
            <th
              key={key}
              className={
                header.percent ? "NationalHelpTable-percentTitle" : " "
              }
            >
              <div>
                {header.label}
                {header.notice && <sup>{header.notice}</sup>}
              </div>
              {header.subtitle && (
                <div className="text-xs">{header.subtitle}</div>
              )}
            </th>
          )
        )}
      </tr>
    </thead>
  );
}

export function AidRowTable({ aid, children, className = "" }) {
  const childrenArray = React.Children.toArray(children);

  return (
    <>
      <tr>
        <td className={`${className} NationalHelpTable-aid`} rowSpan={childrenArray.length}>
          {aid}
        </td>
        {childrenArray[0]}
      </tr>

      {map(drop(childrenArray), (currentChild, key) => (
        <tr key={key}>{currentChild}</tr>
      ))}
    </>
  );
}

export function RowTable({
  workType,
  cost,
  cee,
  mpr = false,
  noticeMpr,
  noticeCee,
  className = ""
}) {
  const percent = calculPercent(cost, cee, mpr);
  const remain = calculRemain(cost, cee, mpr);
  return (
    <>
      <td className="font-bold">{workType}</td>
      <td>{formatValueZero(cost, formatAmountPrice(cost))}</td>
      <td className={`${className} NationalHelpTable-price`}>
        {formatValueZero(cee, formatAmountPrice(cee))}{" "}
        {noticeCee && <sup>{noticeCee}</sup>}
      </td>
      {mpr !== false && (
        <td className={`${className} NationalHelpTable-price`}>
          {formatValueZero(mpr, formatAmountPrice(mpr))}{" "}
          {noticeMpr && <sup>{noticeMpr}</sup>}
        </td>
      )}
      <td className={`${calculColor(percent)}`}>
        {formatValueZero(percent, `${percent}%`)}
      </td>
      <td>{formatValueZero(remain, formatAmountPrice(remain))}</td>
    </>
  );
}

function calculColor(percent) {
  const opacity =
    percent >= 60
      ? "bg-lime-600"
      : percent >= 30
      ? "bg-lime-500"
      : "bg-lime-300";

  return `${percent !== 0 ? `font-bold text-white ${opacity}` : ""}`;
}

export function GroupRowTable({
  aid,
  workType,
  cost,
  cee,
  noticeCee,
  title,
  occupant,
  lessor,
}) {
  return (
    <>
      <tr>
        <td rowSpan={3} className="NationalHelpTable-aid">
          {aid}
        </td>
        <td rowSpan={3} className="font-bold">
          {workType}
        </td>
        <td rowSpan={3}>{formatValueZero(cost, formatAmountPrice(cost))}</td>
        <td rowSpan={3} className="NationalHelpTable-price">
          {formatValueZero(cee, formatAmountPrice(cee))}{" "}
          {noticeCee && <sup>{noticeCee}</sup>}
        </td>
        <td className="font-bold" colSpan={3}>
          {title}
        </td>
      </tr>
      <SubRowTable
        {...occupant}
        cee={cee}
        cost={cost}
        subtitle="pour les propriétaires occupants"
      />
      <SubRowTable
        {...lessor}
        cee={cee}
        cost={cost}
        subtitle="pour les propriétaires bailleurs"
      />
    </>
  );
}

function SubRowTable({ mpr, cost, cee, subtitle }) {
  const percent = calculPercent(cost, cee, mpr);
  const remain = calculRemain(cost, cee, mpr);
  return (
    <tr>
      <td className="NationalHelpTable-price">
        {formatAmountPrice(mpr)}
        <sup>5</sup>
        <div>
          <small>{subtitle}</small>
        </div>
      </td>
      <td className={`${calculColor(percent)}`}>{percent}%</td>
      <td>{formatAmountPrice(remain)}</td>
    </tr>
  );
}

function calculPercent(cost, cee, mpr) {
  const totalEstim = mpr ? cee + mpr : cee;
  return !cost ? 0 : getNumberPercent(totalEstim, cost);
}
function calculRemain(cost, cee, mpr) {
  const totalEstim = mpr ? cee + mpr : cee;
  return !cost ? 0 : cost - totalEstim;
}

function formatValueZero(value, final) {
  return value === 0 ? "/" : final;
}
