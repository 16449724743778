import React, { useState } from "react";
import styled from "styled-components/macro"; // eslint-disable-line

import { Field } from "formik";
import {map, get, size} from "lodash";

import FieldSetIntro from "../FieldSetIntro";
import SimpleTitle from "../SimpleTitle";
import { CheckboxInput, CheckboxLabel } from "../inputs/Checkbox";
import Error from "../inputs/Error";

import Tooltip from "../Tooltip";
import Modal from "../Modal";
import { LightButton } from "../Button";

function Choice({
  field: { icon, tooltip },
  choices,
  values,
  name,
  title,
  required,
  simpleTitle,
  active,
  parent,
  checkPrimaryWorks
}) {
  const [fieldSetIntroActive, setFieldSetIntroActive] = useState(
      (size(values[name]) && true) || (active && true)
  );
  const [shouldShowModal, setShouldShowModal] = useState(false);

  const handleFieldSetIntroClick = () => {
    if (fieldSetIntroActive) {
      values[name] = "";
    }
    setFieldSetIntroActive(!fieldSetIntroActive);
  };

  return (
    <div>
      <Modal open={shouldShowModal}>
        <div
          css={`
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 30px;
            max-width: 300px;
            text-align: center;
          `}
        >
          Vous ne pouvez pas sélectionner le même système de chauffage en
          principal et secondaire
        </div>

        <div
          css={`
            display: flex;
            justify-content: center;
          `}
        >
          <LightButton onClick={() => setShouldShowModal(false)}>
            Fermer
          </LightButton>
        </div>
      </Modal>
      {!simpleTitle ? (
        <FieldSetIntro
          tooltip={tooltip}
          icon={icon}
          title={title}
          active={fieldSetIntroActive}
          handleClick={handleFieldSetIntroClick}
        />
      ) : (
        <SimpleTitle>{ name === 'heatingWork[secondary]' && !checkPrimaryWorks ? '' : title}</SimpleTitle>
      )}

      {choices && fieldSetIntroActive
        ? map(choices, choice => {
            return (
              <Field key={choice.value} name={name}>
                {({ field }) => {
                  const labelID = `${field.name}_${choice.value}`;

                  return (
                    <div
                      css={`
                        display: flex;
                        align-items: flex-start;
                      `}
                    >
                      <CheckboxInput
                        {...field}
                        type="checkbox"
                        id={labelID}
                        name={field.name}
                        required={required}
                        checked={field.value === choice.value}
                        onChange={e => {
                          const val =
                            choice.value === field.value ? "" : choice.value;

                          // UGLY FIX FOR EDGE CASE - two same heatingWork cannot be selected, even spread across two field
                          let toReset = null;

                          if (
                              get(values, "heatingWork[primary]") ===
                              choice.value && field.name === "heatingWork[secondary]"
                          ) {
                            if (!get(values, field.name)) {
                              toReset = "heatingWork[primary]";
                            } else if (get(values, field.name) !== choice.value) {
                              toReset = "heatingWork";
                            }
                          } else if (
                              get(values, "heatingWork[secondary]") ===
                              choice.value && field.name === "heatingWork[primary]"
                          ) {
                            if (!get(values, field.name)) {
                              toReset = "heatingWork[secondary]";
                            } else if (get(values, field.name) !== choice.value) {
                              toReset = "heatingWork";
                            }
                          }

                          if (toReset) {
                            setShouldShowModal(true);
                            field.onChange(toReset)("");
                            return;
                          }
                          // UGLY FIX FOR EDGE CASE

                          field.onChange(field.name)(val);
                        }}
                      />
                      <CheckboxLabel htmlFor={labelID}>
                        <span className={choice.class}>{choice.label} </span>
                      </CheckboxLabel>
                      {choice.tooltip ? (
                        <div
                          css={`
                            margin-top: 3px;
                          `}
                        >
                          <Tooltip tooltip={choice.tooltip} />
                        </div>
                      ) : null}
                    </div>
                  );
                }}
              </Field>
            );
          })
        : null}
      <Error name={name} />
    </div>
  );
}

export default Choice;
