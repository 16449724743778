import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import ReactSvg from "react-svg";
import { Line } from "rc-progress";

import playIcon from "../svgs/play.svg";

const CardType = styled.div`
  color: ${props => props.theme.color.primary};
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.2px;
`;

const CardTitle = styled.div`
  font-size: 26px;
  line-height: 35px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: -0.2px;

  @media (min-width: 1300px) {
    font-size: 40px;
    line-height: 49px;
  }

  @media (min-width: 1300px) and (max-width: 1560px) {
    min-height: 98px;
  }
`;
const CardSubTitle = styled.div`
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.4px;
  min-height: 90px;

  @media (min-width: 1300px) {
    min-height: 72px;
  }
`;

const Card = styled(Link)`
  text-decoration: none;
  box-shadow: 1px 2px 20px rgba(47, 59, 82, 0.08);
  border-radius: 10px;
  padding: 25px 40px;
  font-weight: 600;
  flex: 1;
  color: inherit;
  display: flex;
  flex-direction: column;
  min-height: 550px;
`;

const CardImage = styled.div`
  margin-top: 15px;
`;

const Duration = styled.div`
  color: ${props => props.theme.color.primary};
  display: flex;
  align-items: center;
  > span {
    font-size: 18px;
    margin-left: 15px;
  }
`;

const PercentageNumber = styled.div`
  color: ${props => props.theme.color.primary};
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  margin-bottom: 10px;
`;

const CardTop = styled.div`
  flex: 1 0 50%;
`;

const Percentage = styled.div`
  margin: 50px 0 35px;
  display: flex;
  flex-direction: column;
  text-align: center;

  > svg {
    height: 10px;
    max-width: 180px;
    margin: 0 auto;
  }
`;

function LearningCard({
  link,
  type,
  title,
  subtitle,
  duration,
  percentage,
  image
}) {
  return (
    <Card to={link}>
      <CardTop>
        <CardType>{type}</CardType>
        <CardTitle>{title}</CardTitle>
        <CardSubTitle>{subtitle}</CardSubTitle>
        {duration ? (
          <Duration>
            <ReactSvg src={playIcon} />
            <span>{duration} Mins</span>
          </Duration>
        ) : null}
        {image ? (
          <CardImage>
            <img src={image} alt="" />
            {/* <ReactSvg src={discoverIllustration} /> */}
          </CardImage>
        ) : null}
      </CardTop>
      {percentage > -1 ? (
        <Percentage>
          <PercentageNumber>{percentage} %</PercentageNumber>
          <Line
            percent={percentage}
            strokeWidth={6}
            strokeColor="#D7BBEF"
            trailColor="#9181DA"
            trailWidth={6}
          />
        </Percentage>
      ) : null}
    </Card>
  );
}

export default LearningCard;
