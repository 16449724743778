import React from "react";
import styled from "styled-components/macro";
import ReactSvg from "react-svg";
import { ErrorMessage } from "formik";
import icon from "../../svgs/warning.svg";

export const ErrorStyle = styled.div`
  margin: 10px auto;
  background: #fb5951;
  padding: 10px 17px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: #fff;
  max-width: 100%;
  text-align: left;
  font-size: 14px;
  svg {
    margin-right: 17px;
    width: 24px;
    height: 24px;
    opacity: 0.5;
  }
`;

export function BasicError({ className, message }) {
  return (
    <ErrorStyle className={className}>
      <ReactSvg src={icon} />
      <span>{message}</span>
    </ErrorStyle>
  );
}

export function Error({ name, message, className }) {
  return (
    <ErrorMessage name={name}>
      {message => {
        return <BasicError className={className} message={message} />;
      }}
    </ErrorMessage>
  );
}

export default styled(Error)``;
