import React from "react";
import ReactSVG from "react-svg";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";

import playIcon from "../svgs/videos.svg";
import videoCompleteIcon from "../svgs/video-complete.svg";
import videoPendingIcon from "../svgs/video-pending.svg";
import pdfIcon from "../svgs/PDF-purple.svg";

const Image = styled(({ complete, ...props }) => <Link {...props} />)`
  display: block;
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
  &:before {
    content: ${props =>
      props.status === "complete" || props.status === "pending"
        ? '""'
        : "none"};
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(
      125,
      109,
      212,
      ${props => (props.status === "complete" ? "0.7" : "0")}
    );
    z-index: 14;
  }

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    width: 100%;

    &.notCover {
      width: auto;
    }
  }

  .video-status-icon {
    position: absolute;
    bottom: 14px;
    right: 14px;
    z-index: 15;
  }
`;

const Wrapper = styled.div`
  background: #f1efff;
  border-radius: 10px;
  margin-bottom: 30px;
  position: relative;
`;

const Icon = styled(ReactSVG)`
  position: absolute;
  z-index: 15;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Text = styled.div`
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    width: 30px;
    height: 30px;
  }
`;

const TypeImage = styled.div`
  margin-right: 10px;
  img {
    width: 45px;
    height: 45px;
    object-fit: contain;
  }
`;

const Subtitle = styled.div`
  opacity: 0.6;
  font-size: 10px;
  line-height: 22px;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

function getStatus(completion = 0) {
  if (completion === 100) {
    return "complete";
  } else if (completion >= 1 && completion <= 99) {
    return "pending";
  }

  return "new";
}

function VideoCard({
  title,
  subtitle,
  link,
  image,
  typeImage,
  pdf,
  completion,
  classImage
}) {
  const status = getStatus(completion);
  return (
    <Wrapper>
      <Image to={link} status={status}>
        <img src={image} alt="" />
        <Icon src={playIcon} />
        {status === "complete" ? (
          <ReactSVG src={videoCompleteIcon} className="video-status-icon" />
        ) : null}
        {status === "pending" ? (
          <ReactSVG src={videoPendingIcon} className="video-status-icon" />
        ) : null}
      </Image>
      <Text>
        <div
          css={`
            display: flex;
            align-items: center;
          `}
        >
          <TypeImage>
            {typeImage ? (
              <img src={typeImage} alt="" className={classImage} />
            ) : null}
          </TypeImage>
          <div>
            <div>{title}</div>
            <Subtitle>{subtitle}</Subtitle>
          </div>
        </div>
        {pdf ? (
          <a href={pdf} target="_blank" rel="noopener noreferrer">
            <ReactSVG src={pdfIcon} />
          </a>
        ) : null}
      </Text>
    </Wrapper>
  );
}

export default VideoCard;
