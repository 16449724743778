import { get, map, without, reduce } from "lodash";
import React, { useEffect, useState } from "react";
import { BigWhiteCell, SwitchAmount, WhiteCell } from "./parts/step5Parts";
import { ClassicModal } from "../Modal";
import { formatAmountPrice } from "../../utils/formatAmount";

export default function CellLocalAids({
  scenario,
  prefix,
  setSelectedLocalAids,
  selectedAids,
  estimation,
  onChangeTotal,
}) {
  // const nbAids = get(scenario, "local_aids.nb_aides", 0);
  const [open, setOpen] = useState(false);
  const [selectedAid, setSelectedAid] = useState(false);
  const [validTotal, setValidTotal] = useState(0);
  const aids = map(get(scenario, "local_aids.aides", []), (aid) => {
    const typeAid = getTypeLocalAid(get(aid, "montants[0]"), estimation);
    return { ...aid, amount: typeAid };
  });

  let nbAids = 0;
  for (let i = 0; i < aids.length; ++i) {
    if (aids[i].amount !== null) {
      ++nbAids;
    }
  }
  const [isOpenModalInformation, setOpenModalInformation] = useState(
    nbAids > 1
  );
  const addAid = (idAid) => {
    setSelectedLocalAids((prevState) => [...prevState, idAid]);
  };

  const removeAid = (idAid) => {
    setSelectedLocalAids((prevState) => without(prevState, idAid));
  };

  const validateTotal = (totalAids) => {
    onChangeTotal(totalAids);
    setValidTotal(totalAids);
  };
  useEffect(() => {
    if (!open) {
      setOpenModalInformation(nbAids > 1);
    }
  }, [open, nbAids]);
  const totalAids = getTotal(aids, selectedAids, prefix);
  const notAllowed = totalAids > scenario.local_aids_limit;

  const messageAid = !nbAids
    ? "Aucune aide disponible"
    : nbAids === 1
    ? "l'aide disponible"
    : `les ${nbAids} aides disponibles`;
  return (
    <>
      {validTotal > 0 ? (
        <div className="text-center">
          <WhiteCell>{formatAmountPrice(validTotal)}</WhiteCell>
          <button
            onClick={() => {
              !!nbAids && setOpen(true);
            }}
            className="text-sm underline cursor-pointer"
          >
            {!!nbAids && "Revoir "}
            {messageAid}
          </button>
        </div>
      ) : (
        <button
          className={`block w-full ${!nbAids ? "cursor-default" : ""}`}
          disabled={!nbAids}
          onClick={() => {
            !!nbAids && setOpen(true);
          }}
        >
          <WhiteCell
            fontSize="text-sm"
            color={!nbAids && "text-gray-700"}
            bgColor={!nbAids && "bg-gray-300"}
          >
            {!!nbAids && "Voir "} {messageAid}
          </WhiteCell>
        </button>
      )}

      <ClassicModal
        open={open}
        overflow
        contentLabel="Aides locales"
        closeModal={() => {
          setOpen(false);
        }}
      >
        <div className="text-center font-bold">
          Ajouter des aides locales potentielles
        </div>
        <div className="grid grid-cols-7 p-4">
          <div className="col-span-5 text-center font-bold">
            {nbAids} aide{nbAids > 1 && "s"} disponible{nbAids > 1 && "s"}
          </div>
        </div>
        {map(aids, (aid, key) => {
          const typeAid = aid.amount;
          const aidId = `${prefix}-${aid.id}`;
          const isSelected = selectedAids.includes(aidId);
          return (
            typeAid && (
              <div key={key} className="grid grid-cols-7 gap-5 p-4">
                <div className="col-span-5">
                  <BigWhiteCell className="xl:h-24">
                    <div className="grid grid-cols-7 p-2 gap-3">
                      <div className="col-span-4 flex flex-col justify-center">
                        <div className="font-bold">{aid.libelle}</div>
                        <div>{aid.libelle_programme}</div>
                      </div>
                      <div className="col-span-2 flex flex-col justify-center">
                        {typeAid && (
                          <>
                            <div className="font-bold text-2xl text-center">
                              {typeAid.value}
                            </div>
                            <div className="orange-gradient text-white text-center p-1 rounded-md">
                              {typeAid.post}
                            </div>
                          </>
                        )}
                      </div>
                      <div className="flex flex-col justify-center">
                        <button
                          onClick={() => {
                            setSelectedAid(aid);
                          }}
                        >
                          Voir le détail
                        </button>
                      </div>
                    </div>
                  </BigWhiteCell>
                </div>
                <div>
                  <WhiteCell height={24}>
                    {formatAmountPrice(typeAid.euroValue)}
                  </WhiteCell>
                </div>
                <div className="flex-col-center gap-1">
                  <div>{isSelected ? "Retirer" : "Ajouter"}</div>
                  <SwitchAmount
                    initialValue={isSelected}
                    onChange={(checked) => {
                      if (checked) {
                        addAid(aidId);
                      } else {
                        removeAid(aidId);
                      }
                    }}
                  />
                </div>
              </div>
            )
          );
        })}

        <div className="grid grid-cols-7 gap-5 p-4">
          <div className="col-span-5  px-4 flex items-center justify-end">
            <div>
              <div className="text-right">
                Total des aides locales potentielles
              </div>
              <div className="text-right">
                (Plafond : {formatAmountPrice(scenario.local_aids_limit)})
              </div>
            </div>
          </div>
          <div>
            <WhiteCell
              mobilBgColor={notAllowed ? "bg-red-700" : "bg-apple-500"}
              color={notAllowed ? "text-red-700" : "text-apple-500"}
              height={24}
            >
              {formatAmountPrice(totalAids)}
            </WhiteCell>
          </div>
        </div>
        <div className="flex justify-center">
          <button
            className={`${
              notAllowed
                ? "bg-gradient-to-r from-gray-400 to-gray-600 cursor-not-allowed "
                : "orange-gradient"
            } p-4 rounded-md min-w-10`}
            disabled={notAllowed}
            onClick={() => {
              setOpen(false);
              validateTotal(totalAids);
            }}
          >
            Valider
          </button>
        </div>
      </ClassicModal>
      <ModalDetails
        aid={selectedAid}
        closeModal={() => {
          setSelectedAid(false);
        }}
      />
      <ModalInformations
        isOpen={open && isOpenModalInformation}
        closeModal={() => {
          setOpenModalInformation(false);
        }}
      />
    </>
  );
}

function ModalDetails({ aid, closeModal }) {
  return (
    <ClassicModal
      open={!!aid}
      contentLabel="Détails Aides locales"
      closeModal={closeModal}
      bgColor="bg-white"
      textColor="text-gray-900"
      title={
        <div className="flex flex-col">
          <span className="text-xl">{aid.libelle}</span>
          <span>{aid.libelle_programme}</span>
        </div>
      }
    >
      <div className="md:max-w-screen-md">
        <div>{aid.description_programme}</div>
        <div className="pt-4 grid grid-cols-3">
          <LittleBlocDetail title="Site web">
            <a target="_blank" rel="noreferrer" href={aid.site}>
              Consulter le site
            </a>
          </LittleBlocDetail>
          <LittleBlocDetail title="Adresse">
            <div>{aid.adresse}</div>
            {aid.adresse2 && <div>{aid.adresse2}</div>}
            <div>
              {aid.code_postal}, {aid.ville}
            </div>
          </LittleBlocDetail>
          <LittleBlocDetail title="Téléphone">{aid.telephone}</LittleBlocDetail>
        </div>
      </div>
    </ClassicModal>
  );
}
function ModalInformations({ isOpen, closeModal }) {
  return (
    <ClassicModal
      open={isOpen}
      contentLabel="Détails Aides locales"
      closeModal={closeModal}
      bgColor="bg-white"
      textColor="text-gray-900"
      title={
        <div className="flex flex-col">
          <span className="text-center font-bold">Attention !</span>
        </div>
      }
    >
      <div className="md:max-w-screen-md">
        <div className="text-center pt-4 pb-6">
          Il est possible que certaines aides locales affichées ne soient pas
          cumulables entre elles, c'est pourquoi nous vous préconisons une
          lecture vigilante de leurs descriptions.
        </div>
        <div className="text-center">
          <button
            onClick={closeModal}
            className="orange-gradient py-2 px-12 rounded-md font-bold text-white"
          >
            OK
          </button>
        </div>
      </div>
    </ClassicModal>
  );
}

function LittleBlocDetail({ title, children }) {
  return (
    <div className="text-sm text-center">
      <div className="text-xl">{title}</div>
      <div>{children}</div>
    </div>
  );
}

function getTotal(aids, selectedAids, prefix) {
  return reduce(
    aids,
    (result, a) => {
      if (selectedAids.includes(`${prefix}-${a.id}`)) {
        result += get(a, "amount.euroValue", 0);
      }
      return result;
    },
    0
  );
}
function getTypeLocalAid(amount, estimation) {
  if (!amount) return null;
  if (amount.type === "FORFAITAIRE") {
    return {
      value: formatAmountPrice(amount.valeur),
      limit: amount.plafond,
      post: amount.poste,
      euroValue: amount.valeur,
    };
  }
  if (amount.type === "POURCENTAGE") {
    const euroValue = (estimation * amount.valeur) / 100;
    const limit = get(amount, "plafond.valeur", null);
    return {
      value: amount.valeur + " %",
      limit: limit,
      post: amount.poste,
      euroValue: limit && euroValue > limit ? limit : euroValue,
    };
  }
  if (amount.type === "FOURCHETTE") {
    const euroValue = (amount.valeur + amount.valeur_max) / 2;
    return {
      value:
        formatAmountPrice(amount.valeur) +
        " à " +
        formatAmountPrice(amount.valeur_max),
      limit: amount.plafond,
      post: amount.poste,
      euroValue: euroValue,
    };
  }
  if (amount.type === "FOURCHETTE_POURCENTAGE") {
    const meanValue = (amount.valeur + amount.valeur_max) / 2;
    const euroValue = (estimation * meanValue) / 100;
    let limit = get(amount, "plafond.valeur", null);
    if (limit !== null) {
      limit = (estimation * limit) / 100;
    }
    return {
      value: amount.valeur + " % à " + amount.valeur_max + " %",
      limit: limit,
      post: amount.poste,
      euroValue: limit && euroValue > limit ? limit : euroValue,
    };
  }

  return null;
}
