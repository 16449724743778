import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { Row, Col } from "styled-bootstrap-grid";

import Page from "../layouts/Page";
import Title from "../components/Title";
import SubTitle from "../components/SubTitle";
import Block from "../components/Block";
import ContentDate from "../components/ContentDate";
import ContentTitle from "../components/ContentTitle";
import ContentSubtitle from "../components/ContentSubtitle";
import { SimulatorButton } from "../components/Button";
import NewsLabel from "../components/NewsLabel";
import Loader from "../components/Loader";

import Routes from "../routes";

import { get, map } from "lodash";

import { getNews } from "../api";
import { formatDate } from "../helpers";

const BtnBlock = styled.div`
  text-align: center;
  margin-top: 65px;
`;

function Article({
  history,
  match: {
    params: { id }
  }
}) {
  const [article, setArticle] = useState(null);

  useEffect(() => {
    async function getArticle() {
      try {
        setArticle(null);
        const { data } = await getNews(id);
        setArticle(data);
      } catch (error) {
        console.error(error);
      }
    }
    getArticle();
  }, [id]);

  return (
    <Page>
      <Title>{Routes().news.label}</Title>
      <SubTitle>
        <Link to={`/news`}>Actualités</Link> |{" "}
        <strong>{get(article, "title.rendered")}</strong>
      </SubTitle>

      <Block>
        {!article ? (
          <Loader />
        ) : (
          <>
            <Row>
              <Col sm={4}>
                <ContentDate>{formatDate(new Date(article.date))}</ContentDate>
              </Col>
              <Col
                sm={8}
                css={`
                  text-align: right;
                `}
              >
                {map(article.categories, (label, index) => (
                  <NewsLabel color={label.color} key={index}>
                    {label.title}
                  </NewsLabel>
                ))}
              </Col>
            </Row>
            <ContentTitle>{get(article, "title.rendered")}</ContentTitle>
            <ContentSubtitle
              className={"wysiwyg"}
              dangerouslySetInnerHTML={{
                __html: get(article, "content.rendered")
              }}
            />

            <BtnBlock>
              <SimulatorButton
                onClick={() => {
                  history.goBack();
                }}
              >
                Retour
              </SimulatorButton>
            </BtnBlock>
          </>
        )}
      </Block>
    </Page>
  );
}

export default Article;
