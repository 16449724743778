import { simulatorTheme, trainingTheme } from "./theme";

import Activation from "./containers/Activation";
import Contact from "./components/Contact";
import Content from "./containers/Content";
import Debug from "./containers/Debug";
import DisabledSimulator from "./containers/DisabledSimulator";
import DiscoverDetails from "./containers/Training/DiscoverDetails";
import FAQ from "./components/FAQ";
import ForgottenPassword from "./containers/ForgottenPassword";
import Glossary from "./containers/Glossary";
import Help from "./containers/Help";
import Home from "./containers/Home";
import Login from "./containers/Login";
import NoMatch from "./containers/NoMatch";
import PasswordOk from "./containers/PasswordOk";
import Registration from "./containers/Registration";
import ResetPassword from "./containers/ResetPassword";
import Simulator from "./containers/Simulator";
import Step1 from "./components/Simulator/Step1";
import Step2 from "./components/Simulator/Step2";
import Step3 from "./components/Simulator/Step3";
import Step4 from "./components/Simulator/Step4";
import Step5 from "./components/Simulator/Step5";
import Step5Footer from "./components/Simulator/Step5Footer";
import Step6 from "./components/Simulator/Step6";
import ToolBoxContent from "./containers/ToolBoxContent";
import ToolBoxHome from "./containers/ToolboxHome";
import Toolbox from "./containers/Toolbox";
import Training from "./containers/Training/Training";
import TrainingDiscover from "./containers/Training/Discover";
import TrainingLanding from "./containers/Training/Landing";
import TrainingLibrary from "./containers/Training/Library";
import accueilIcon from "./svgs/accueil.svg";
import faqIcon from "./svgs/faq.svg";
import helpIcon from "./svgs/besoin-aides.svg";
import radioIcon from "./svgs/radio-thin.svg";
import graphIcon from "./svgs/graph-thin.svg";
import letterIcon from "./svgs/letter.svg";
import simulatorIcon from "./svgs/jaccompagne.svg";
import toolboxIcon from "./svgs/toolbox.svg";
import trainingIcon from "./svgs/training.svg";
import { useGetLimitedLearning } from "./api";
import Article from "./containers/Article";
import News from "./containers/News";
import Statistics from "./containers/Statistics";
import ToolboxTable from "./containers/ToolboxTable";

const home = {
  path: "/",
  label: "Accueil",
  title: "Accueil - PEPZ",
  exact: true,
  component: Home,
  icon: accueilIcon,
  background: simulatorTheme.mainGradient,
};

const login = {
  path: "/login",
  label: "Login",
  title: "Login - PEPZ",
  exact: true,
  component: Login,
  private: false,
};

const registration = {
  path: "/registration/:agencySlug",
  label: "Enregistrement",
  title: "Enregistrement - PEPZ",
  component: Registration,
  private: false,
};

const activation = {
  path: "/activation",
  label: "Activation",
  title: "Activation - PEPZ",
  exact: true,
  component: Activation,
  private: false,
};

const forgottenPassword = {
  path: "/password",
  label: "Mot de passe oublié",
  title: "Mot de passe oublié - PEPZ",
  exact: true,
  component: ForgottenPassword,
  private: false,
};

const passwordOk = {
  path: "/password-ok",
  label: "Mot de passe oublié - confirmation",
  title: "Mot de passe oublié - PEPZ",
  exact: true,
  component: PasswordOk,
  private: false,
};

const resetPassword = {
  path: "/reset-password",
  label: "Nouveau mot de passe",
  exact: true,
  component: ResetPassword,
  private: false,
};

const help = {
  path: "/help",
  label: "Besoin d'aide",
  title: "Besoin d'aide - PEPZ",
  component: Help,
  icon: helpIcon,
  routes: {
    FAQ: {
      path: "/help/faq",
      label: "Questions fréquentes",
      component: FAQ,
      icon: faqIcon,
    },
    tool: {
      path: "/help/tool",
      label: "Utilisation de l'outil",
      component: FAQ,
      icon: toolboxIcon,
    },
    contact: {
      path: "/help/contact",
      label: "Assistance technique",
      component: Contact,
      icon: letterIcon,
    },
  },
};

const toolbox = {
  path: "/toolbox",
  label: "Boite à outils",
  title: "Boite à outils - PEPZ",
  component: Toolbox,
  icon: toolboxIcon,
  routes: {
    home: {
      path: "/toolbox",
      exact: true,
      component: ToolBoxHome,
    },
    table: {
      path: "/toolbox/tableaux-aides",
      exact: true,
      component: ToolboxTable,
    },
    help: {
      path: "/toolbox/:type/:id",
      component: ToolBoxContent,
    },
  },
};

const nomatch = {
  label: "404",
  title: "404 - PEPZ",
  component: NoMatch,
  private: false,
};

const glossary = {
  path: "/glossaire/:letter",
  label: "Glossaire",
  title: "Glossaire - PEPZ",
  component: Glossary,
  icon: helpIcon,
};

const article = {
  path: "/news/:id",
  component: Article,
  icon: helpIcon,
};

const news = {
  path: "/news",
  label: "Actualités",
  title: "Actualités - PEPZ",
  component: News,
  icon: radioIcon,
};

const statistics = {
  path: "/statistiques",
  label: "Statistiques",
  title: "Statistiques - PEPZ",
  component: Statistics,
  icon: graphIcon,
};

const debug = {
  path: "/debug",
  label: "Debug",
  title: "Debug - PEPZ",
  component: process.env.REACT_APP_DEBUG === "true" ? Debug : NoMatch,
};

const content = {
  path: "/content/:slug",
  label: "Contenu",
  private: false,
  exact: true,
  component: Content,
};

function TrainingComponent() {
  return {
    path: "/training",
    label: "Formations",
    title: "Formations - PEPZ",
    background: trainingTheme.mainGradient,
    component: Training,
    icon: trainingIcon,
    routes: {
      index: {
        path: "/training",
        exact: true,
        component: TrainingLanding,
      },
      library: {
        path: "/training/library",
        exact: true,
        component: TrainingLibrary,
      },
      type: {
        path: "/training/stage/:id",
        exact: true,
        component: TrainingDiscover,
      },
      typeDetails: {
        path: "/training/stage-details/:id",
        component: DiscoverDetails,
      },
    },
  };
}

function SimulatorComponent(limitedLearning = true) {
  return {
    path: "/simulator",
    label: "Simulateur d'aide",
    title: "Simulateur d'aide - PEPZ",
    component: Simulator,
    icon: simulatorIcon,
    background: simulatorTheme.mainGradient,
    routes: {
      step1: {
        path: "/simulator/1",
        label: "CARACTÉRISTIQUES DES TRAVAUX",
        component:
          limitedLearning !== null && limitedLearning.limited
            ? DisabledSimulator
            : Step1,
        key: "WorkSpecification",
      },
      step2: {
        path: "/simulator/2",
        label: "Choix des travaux",
        component:
          limitedLearning !== null && limitedLearning.limited
            ? DisabledSimulator
            : Step2,
        key: "WorkChoice",
      },
      step3: {
        path: "/simulator/3",
        label: "ESTIMATION DES COÛTS",
        component:
          limitedLearning !== null && limitedLearning.limited
            ? DisabledSimulator
            : Step3,
        key: "CostEstimate",
      },
      step4: {
        path: "/simulator/4",
        label: "INFORMATIONS PERSONNELLES",
        component:
          limitedLearning !== null && limitedLearning.limited
            ? DisabledSimulator
            : Step4,
        key: "PersonalInformation",
      },
      step5: {
        path: "/simulator/5",
        label: "ESTIMATION DES AIDES",
        component:
          limitedLearning !== null && limitedLearning.limited
            ? DisabledSimulator
            : Step5,
        footer: Step5Footer,
        key: "AidEstimate",
      },
      step6: {
        path: "/simulator/6",
        label: "ESTIMATION DES ECONOMIES",
        component:
          limitedLearning !== null && limitedLearning.limited
            ? DisabledSimulator
            : Step6,
        key: "SavingEstimate",
      },
    },
  };
}

export function SidebarRoutes() {
  const { data: limitedLearning } = useGetLimitedLearning();

  let sidebar = {};

  let simulator = SimulatorComponent(limitedLearning);

  let training = TrainingComponent();

  if (limitedLearning !== undefined && limitedLearning.limited === false) {
    sidebar = {
      home,
      simulator,
      training,
      toolbox,
      statistics,
      news,
      help,
    };
  } else {
    sidebar = {
      home,
      training,
      toolbox,
      statistics,
      news,
      help,
    };
  }

  return sidebar;
}

export default function Routes() {
  const { data: limitedLearning } = useGetLimitedLearning();

  let simulator = SimulatorComponent(limitedLearning);

  let training = TrainingComponent(limitedLearning);

  return {
    home,
    simulator,
    login,
    registration,
    activation,
    forgottenPassword,
    passwordOk,
    resetPassword,
    help,
    article,
    news,
    glossary,
    toolbox,
    statistics,
    debug,
    training,
    content,
    nomatch, // SHOULD ALWAYS BE LAST
  };
}
