export const formatDate = (date) => {
  return date.toLocaleDateString("fr-FR", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const formatDateNumber = (date) => {
  return date.toLocaleDateString("fr-FR");
};
