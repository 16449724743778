import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Formik } from "formik";
import { get, forEach, toArray, map, validate } from "lodash";
import Button, { LightButton } from "../../components/Button";
import { getInitialValues } from "../FormWidgets/helpers";
import Switch from "../FormWidgets/Switch";
import Input from "../FormWidgets/Input";
import Loader from "../Loader";
import { personalInformation } from "../../api";
import LoaderBase from "react-loader-spinner";
import Select from "../FormWidgets/Select";

const FormContainer = styled.form`
  margin-top: 40px;
`;

const FormFields = styled.div`
  ${Input} {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0 40px;
  }

  ${Switch} {
    margin-top: 45px;
    margin-bottom: 45px;
    clear: both;
    width: 100%;

    label {
      max-width: 100%;
    }
  }

  .clientResidenceDepartment {
    max-width: 55%;
    margin: 0 auto;
  }
`;

const InputGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 45px;

  > ${Input} {
    width: 50%;
    padding: 0 40px;
    text-align: left;
  }
`;
const BtnGroup = styled.div`
  clear: both;
  margin-top: 45px;

  ${Button} + ${Button} {
    margin-left: 15px;
  }
`;

function Step4(props) {
  const [formData, setFormData] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await personalInformation();
        setFormData(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {formData ? (
        <Formik
          initialValues={getInitialValues(formData)}
          onSubmit={async (values, actions) => {
            const { title } = formData;
            setButtonLoading(1);

            try {
              await personalInformation({
                method: "post",
                data: {
                  [title]: {
                    ...values,
                    "_token": formData["_token"],
                  },
                },
              });

              props.history.push("/simulator/5");
            } catch (error) {
              setButtonLoading(0);
              forEach(get(error, `response.data.${title}`), (error, key) => {
                actions.setFieldError(key, get(error, `errors`));
              });

              const firstError = document.querySelector('[class^="Error-"]');
              if (firstError && firstError.parentElement) {
                firstError.parentElement.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }
            }
          }}
          render={({ values, handleSubmit }) => {
            const { properties } = formData;

            return (
              <FormContainer onSubmit={handleSubmit}>
                <FormFields>
                  {get(properties, "buildingOccupationType") ? (
                    <Switch
                      field={properties.buildingOccupationType}
                      name="buildingOccupationType"
                      title={properties.buildingOccupationType.title}
                      choices={properties.buildingOccupationType.enum}
                    />
                  ) : null}
                  {(!values.buildingOccupationType || values.buildingOccupationType === 'HOME_OWNER') && get(properties, "buildingUsage") ? (
                    <Switch
                      field={properties.buildingUsage}
                      name="buildingUsage"
                      title={properties.buildingUsage.title}
                      choices={properties.buildingUsage.enum}
                    />
                  ) : null}
                  {values.buildingOccupationType && values.buildingOccupationType === 'OWNER_LESSOR' && get(properties, "buildingUsage") ? (
                    <Switch
                      field={properties.buildingUsage}
                      name="buildingUsage"
                      title={properties.buildingUsage.title}
                      choices={
                        [
                          {
                            "label": "Résidence principale du locataire",
                            "value": "MAIN_RESIDENCE",
                            "tooltip": null
                          },
                          {
                            "label": "Location saisonnière",
                            "value": "SECOND_RESIDENCE",
                            "tooltip": null
                          }
                        ]
                      }
                    />
                  ) : null}
                  {(values.buildingOccupationType === "OWNER_LESSOR" ||
                    (values.buildingOccupationType === "HOME_OWNER" &&
                      values.buildingUsage === "SECOND_RESIDENCE")) &&
                    get(properties, "clientResidenceDepartment") ? (
                    <Select
                      field={properties.clientResidenceDepartment}
                      name="clientResidenceDepartment"
                      title={properties.clientResidenceDepartment.title}
                      placeholder="Sélectionner un département"
                      className="clientResidenceDepartment"
                      choices={toArray(
                        map(
                          properties.clientResidenceDepartment.enum,
                          (option) => {
                            return {
                              value: option.value,
                              label: option.label,
                            };
                          }
                        )
                      )}
                      validate={validate}
                    />
                  ) : null}
                  <InputGrid>
                    {get(formData, "properties.clientFiscalPersonsCount") ? (
                      <Input
                        type={properties.clientFiscalPersonsCount.type}
                        field={properties.clientFiscalPersonsCount}
                        title={properties.clientFiscalPersonsCount.title}
                        name="clientFiscalPersonsCount"
                      />
                    ) : null}
                    {get(
                      formData,
                      "properties.clientIncomeFiscalAmountYearly"
                    ) ? (
                      <Input
                        type={properties.clientIncomeFiscalAmountYearly.type}
                        field={properties.clientIncomeFiscalAmountYearly}
                        title={properties.clientIncomeFiscalAmountYearly.title}
                        name="clientIncomeFiscalAmountYearly"
                      />
                    ) : null}
                  </InputGrid>
                  {get(formData, "properties.enerRenoPtzAlreadyReceived") ? (
                    <Switch
                      field={properties.enerRenoPtzAlreadyReceived}
                      name="enerRenoPtzAlreadyReceived"
                      title={properties.enerRenoPtzAlreadyReceived.title}
                      choices={properties.enerRenoPtzAlreadyReceived.enum}
                    />
                  ) : null}

                  {values.enerRenoPtzAlreadyReceived === "1" ? (
                    <>
                      {get(formData, "properties.ptzAlreadyReceived") ? (
                        <Switch
                          field={properties.ptzAlreadyReceived}
                          name="ptzAlreadyReceived"
                          title={properties.ptzAlreadyReceived.title}
                          choices={properties.ptzAlreadyReceived.enum}
                        />
                      ) : null}
                      {get(formData, "properties.eptzAlreadyReceived") ? (
                        <Switch
                          field={properties.eptzAlreadyReceived}
                          name="eptzAlreadyReceived"
                          title={properties.eptzAlreadyReceived.title}
                          choices={properties.eptzAlreadyReceived.enum}
                        />
                      ) : null}
                      {get(
                        formData,
                        "properties.eptzAlreadyReceivedLastFiveYears"
                      ) && values.eptzAlreadyReceived === "1" ? (
                        <Switch
                          field={properties.eptzAlreadyReceivedLastFiveYears}
                          name="eptzAlreadyReceivedLastFiveYears"
                          title={
                            properties.eptzAlreadyReceivedLastFiveYears.title
                          }
                          choices={
                            properties.eptzAlreadyReceivedLastFiveYears.enum
                          }
                        />
                      ) : null}
                      {get(formData, "properties.formerEptzAmount") &&
                        values.eptzAlreadyReceived === "1" &&
                        values.eptzAlreadyReceivedLastFiveYears === "1" ? (
                        <Input
                          type={properties.formerEptzAmount.type}
                          field={properties.formerEptzAmount}
                          title={properties.formerEptzAmount.title}
                          name="formerEptzAmount"
                        />
                      ) : null}
                      {get(formData, "properties.MPRAlreadyReceived") ? (
                        <Switch
                          field={properties.MPRAlreadyReceived}
                          name="MPRAlreadyReceived"
                          title={properties.MPRAlreadyReceived.title}
                          choices={properties.MPRAlreadyReceived.enum}
                        />
                      ) : null}
                      {get(formData, "properties.MPRBeforeAmount") &&
                        values.MPRAlreadyReceived === "1" ? (
                        <Input
                          type={properties.MPRBeforeAmount.type}
                          field={properties.MPRBeforeAmount}
                          title={properties.MPRBeforeAmount.title}
                          name="MPRBeforeAmount"
                        />
                      ) : null}
                    </>
                  ) : null}
                </FormFields>

                <BtnGroup
                  css={`
                    text-align: right;
                  `}
                >
                  <LightButton
                    type="button"
                    onClick={() => {
                      props.history.push("/simulator/3");
                    }}
                  >
                    Etape Précédente
                  </LightButton>
                  <Button type="submit">
                    {buttonLoading ? (
                      <LoaderBase
                        type="ThreeDots"
                        color="#ffffff"
                        height="16"
                        width="100"
                      />
                    ) : (
                      "Etape Suivante"
                    )}
                  </Button>
                </BtnGroup>
              </FormContainer>
            );
          }}
        />
      ) : (
        <div
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 90vh;
          `}
        >
          <Loader />
        </div>
      )}
    </>
  );
}

export default Step4;
