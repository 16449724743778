import React from "react";
import { Link } from "react-router-dom";
import ReactSVG from "react-svg";
import styled from "styled-components/macro";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import Title from "../components/Title";
import Button from "../components/Button";
import logo from "../svgs/logo.svg";

const Logo = styled(Link)`
  margin-bottom: 25px;
  display: block;
  svg {
    width: 184px;
    height: 80px;
  }
`;

export const Wrapper = styled.div`
  width: 560px;
  max-width: 100%;
  padding: 0 30px;
`;

const LoginPage = styled.main`
  background: ${props => props.theme.color.dark};
  color: #fff;
  height: calc(100vh - 50px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  form   {
    max-width: 100%;
    margin: 30px auto auto;
  }

  ~ ${Footer} {
    margin-left: 0;
  }

  + ${Sidebar} {
    display: none;
  }

  ${Title} {
    text-align: center;
  }

  ${Button} {
    border: none;
  }
`;

const Page = ({ children, className }) => {
  return (
    <LoginPage className={className}>
      <Logo to={"/"}>
        <ReactSVG src={logo} />
      </Logo>
      <Wrapper>{children}</Wrapper>
    </LoginPage>
  );
};

export default styled(Page)``;
