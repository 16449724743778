import styled from "styled-components/macro";

const SubTitle = styled.div`
  font-family: "ProximaNova";
  font-size: 20px;
  margin-bottom: 18px;
  padding-right: 8px;
  font-weight: 400;

  strong {
    font-weight: bold;
  }

  a {
    color: #fff;
    text-decoration: none;
  }
`;  

export default SubTitle;
