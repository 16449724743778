import React from "react";
import styled from "styled-components/macro";
import ReactSVG from "react-svg";

const RadioWrapper = styled.div`
  margin-top: 30px;
  display: inline-block;

  input {
    &:not(:checked),
    &:checked {
      position: absolute;
      left: -9999px;
    }

    + label {
      display: inline-block;
      background: #f7f7f8;
      font-size: 17px;
      line-height: 18px;
      padding: 28px 70px;
      border-radius: 10px;
      cursor: pointer;
      text-align: center;

      span {
        display: block;
      }
      svg + span {
        margin-top: 15px;
      }
    }

    &:checked + label {
      background: ${props => props.theme.mainGradient};
      color: #fff;
      border-color: transparent;

      svg {
        fill: #fff;
        stroke: #fff;
      }
    }
  }

  svg {
    width: 80px;
    height: 80px;
    fill: #c1c1c1;
    stroke: #c1c1c1;
  }
`;

function RadioButton({
  field: { name, value, onChange, onBlur },
  id,
  label,
  className,
  icon,
  ...props
}) {
  return (
    <RadioWrapper className={className}>
      <input
        name={name}
        id={`${name}-${id}`}
        type="radio"
        value={id}
        checked={id === value}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
      />
      <label htmlFor={`${name}-${id}`}>
        {icon ? <ReactSVG src={icon} /> : null}
        <span>{label}</span>
      </label>
    </RadioWrapper>
  );
}

export default styled(RadioButton)``;
