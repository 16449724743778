const SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;

const loadScriptByURL = (id, url, callback) => {
  const isScriptExist = document.getElementById(id);

  if (!isScriptExist) {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    script.id = id;
    script.async = true;
    script.defer = true;
    script.onload = function () {
      if (callback) callback();
    };
    document.body.appendChild(script);
  }

  if (isScriptExist && callback) callback();
};

export const generateApiScript = () => {
  loadScriptByURL(
    "recaptcha-key",
    `https://www.google.com/recaptcha/api.js`,
    function () {
      console.log("Script loaded!");
    }
  );
};

export const getTokenGoogleRecaptcha = () => {
  return new Promise((resolve, reject) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(SITE_KEY, { action: "submit" })
        .then((token) => {
          resolve(token);
        });
    });
  });
};
