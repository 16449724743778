import React, {useState} from "react";
import styled from "styled-components/macro";
import { Formik, Form } from "formik";
import ReactSVG from "react-svg";
import { toast } from "react-toastify";
import { sendMails } from "../../api";

import mailIcon from "../../svgs/mail.svg";
import LoaderBase from "react-loader-spinner";
import Input from "./Input";

const Wrapper = styled.div`
  background: ${props => props.theme.mainGradient};
  border-radius: 10px;
  color: #fff;
  padding: 15px 30px;
`;
const Title = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;
`;
const SubTitle = styled.div``;

const StyledForm = styled(Form)`
  margin-top: 10px;
  display: flex;
  align-items: center;
  & > div {
    margin-top: 0;
    width: 100%;
  }
  input {
    height: 50px;
    font-size: 17px;
    line-height: 18px;
    padding: 15px 17px;
    width: 100%;
    border: 0 none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  button {
    width: 50px;
    height: 50px;
    border: 0 none;
    background: #c65454;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    color: #fff;
    cursor: pointer;

    &:hover {
      background: #983434;
    }
  }
`;

function MailSender({ className, pdfToken, uuid }) {
    const [IsLoadingPDF, setIsLoadingPDF] = useState(false);
  return (
    <Wrapper className={className}>
      <Title>Envoyer par E-mail la simulation et les démarches</Title>
      <SubTitle>
        Pour un envoi multiple, séparer les adresses e-mail d’une virgule
      </SubTitle>
      <Formik
        initialValues={{
            step6EmailPDF: ""
        }}
        onSubmit={async (values, actions) => {
          // Disable PDF
          //return;
            if("" === values.step6EmailPDF) {
                toast.error("Merci de renseigner une adresse e-mail valide", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                return;
            }
            try {
                setIsLoadingPDF(true);
                await sendMails(pdfToken, uuid, values.step6EmailPDF.replace(/\s/g, "")).then(response => {
                    if (response && response.data) {
                        toast.success("Simulation envoyée", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true
                        });
                        setIsLoadingPDF(false);
                    }
                });
            } catch (error) {
                setIsLoadingPDF(false);
            }
        }}
        render={({ values, errors, touched, handleSubmit }) => {
          return (
            <StyledForm>
                <Input
                    type="email"
                    placeholder="Adresses e-mail"
                    name="step6EmailPDF"
                />
              <button type="submit">
                  {IsLoadingPDF ? (
                      <LoaderBase
                          type="ThreeDots"
                          color="#ffffff"
                          height="16"
                          width="20"
                      />
                  ) : (
                      <ReactSVG src={mailIcon} />
                  )}
              </button>
            </StyledForm>
          );
        }}
      />
    </Wrapper>
  );
}

export default styled(MailSender)``;
