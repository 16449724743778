import React from "react";
import styled from "styled-components/macro";
import { Field } from "formik";
import Error from "../inputs/Error";

export const Suffix = styled.div`
  padding: 15px;
  position: absolute;
  top: 0;
  right: 0;
`;

export const TextareaWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  position: relative;
  color: #7c7c7d;
  font-size: 17px;
  line-height: 18px;
  display: flex;
  height: 500px;
  align-items: center;
`;

export const BasicTextareaWrapper = styled.div`
  margin-top: 25px;
  label {
    display: block;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 10px;
  }
  textarea {
    height: 100%;
    padding: 15px 58px 15px 20px;
    border: 0;
    width: 100%;
    font-size: 17px;
    line-height: 18px;
  }
`;

export const HelpMessage = styled.div`
  opacity: 0.34;
  margin: 7px 0 30px;
  font-size: 14px;
  line-height: 17px;
`;

function BasicTextarea({
  key,
  label,
  placeholder,
  name,
  type,
  suffix,
  className,
  helpMessage,
  validate,
  ...rest
}) {
  function handleWheel(e) {
    if (e.target.type === "number") {
      e.target.blur();
    }
  }

  return (
    <BasicTextareaWrapper
      key={key}
      className={`input-${name} ${className ? className : ""}`}
    >
      {label ? (
        <label htmlFor={name}>
          <div dangerouslySetInnerHTML={{ __html: label }} />
        </label>
      ) : null}

      <div>
        <TextareaWrapper>
          <Field
            validate={validate}
            component="textarea"
            type={type}
            name={name}
            placeholder={placeholder}
            onWheel={handleWheel}
            onKeyDown={e => {
              if (e.target.type === "number" && e.keyCode === 69) {
                e.preventDefault();
              }
            }}
          />
          <Suffix>
            {typeof suffix === "string" ? (
              <div dangerouslySetInnerHTML={{ __html: suffix }} />
            ) : (
              suffix
            )}
          </Suffix>
        </TextareaWrapper>

        <Error name={name} />
        {helpMessage && <HelpMessage>{helpMessage}</HelpMessage>}
      </div>
    </BasicTextareaWrapper>
  );
}

export default BasicTextarea;
