import Block from "../Block";
import ReactSVG from "react-svg";
import React from "react";

export default function BlockStat({ title, icon, textColor = "", children }) {
  return (
    <Block>
      <div className="flex gap-4 items-end pb-8">
        <div className={`w-8 max-h-7 ${textColor}`}>
          <ReactSVG src={icon} />
        </div>
        <div className="text-2xl leading-6">{title}</div>
      </div>
      <div className="flex flex-col gap-4">{children}</div>
    </Block>
  );
}

export function NumberStat({ text, number, textColor }) {
  return (
    <div className="bg-gray-100 rounded flex flex-col justify-center items-center p-4">
      <div className={`${textColor} text-4xl font-semibold`}>{number}</div>
      <div className="text-center">{text}</div>
    </div>
  );
}

export function PercentageBlock({ percent, bgColor, text, subtitle }) {
  return (
    <div className={`${bgColor} h-44 flex p-4 rounded relative`}>
      <div
        className="h-full bg-opacity-20 bg-white rounded-md"
        style={{ width: `${percent}%` }}
      />
      <div className="absolute inset-0 lg:pl-20 pl-6 flex items-center text-white text-center">
        <div>
          <div className="text-3xl font-semibold">{text}</div>
          <div className="leading-5">{subtitle}</div>
        </div>
      </div>
    </div>
  );
}

export function PercentageLine({ percent, bgColor, text, subtitle }) {
  return (
    <div>
      <div className="flex gap-2 items-end">
        <div className="flex-1 relative">
          <div className="flex justify-between">
            <div>{text}</div>
            {subtitle && <div>{subtitle}</div>}
          </div>
          <div className={`${bgColor} h-4 flex p-1 rounded-md`}>
            <div
              className="h-full bg-opacity-20 bg-white rounded-md"
              style={{ width: `${percent}%` }}
            />
          </div>
        </div>

        <div className="w-16 flex-shrink-0 font-semibold leading-4">
          {percent} %
        </div>
      </div>
    </div>
  );
}
