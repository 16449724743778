import React from "react";
import styled from "styled-components/macro";
import ReactSVG from "react-svg";
import Tooltip from "./Tooltip";
import grow from '../svgs/grow.svg';
import arrowUp from '../svgs/arrowUp.svg';
import operator from '../svgs/operator.svg';
import euros from '../svgs/euros.svg';

const List = styled.ul`
display: flex;
justify-content: center;
flex-direction: column;
margin-top: 20px;

li {
    border-top: 2px solid ${props => props.theme.color.gray};
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 10px 0;

    >div:first-child {
        min-width: 21px;
    }

    span { 
        margin-left: 0.75rem;
    }

    ${Tooltip} {
        
        path {
            fill :#fff;
        }
    }

    #search {
        stroke :#fff;
    }

    &.space {

        span {
            margin-right: 0.75rem;
        }
    }
}`;

const IconText = styled.div`
    display: flex;
    align-items: center;
`;

function AdvantageList(props) {

    const {advantage} = props;

    function setIcon(iconRef) {
        
        if(iconRef === 'operator') {
            return operator
        }
        else if(iconRef === 'grow') {
            return grow
        }
        else if(iconRef === 'arrowUp') {
            return arrowUp
        }
        else if(iconRef === 'euros') {
            return euros
        }

        return null
    }   

    const list = advantage ? (
        advantage.filter(item => item.icon).map( (item, index) => <li key={index} className={item.tooltip ? 'space' : null}><IconText><ReactSVG src={setIcon(item.icon)}/><span>{item.text}</span></IconText>{item.tooltip ? <Tooltip founding={true} tooltip={item.tooltip} /> : null}</li>)
    ) : null;

    return (
        list ? <List>{list}</List> : null
    )
}

export default AdvantageList;
