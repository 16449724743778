import React, { useState, useEffect } from "react";
import { NavLink, Redirect } from "react-router-dom";
import styled from "styled-components/macro";

import { map } from "lodash";

import Page from "../layouts/Page";

import Block from "../components/Block";
import Title from "../components/Title";
import Accordion from "../components/Accordion";
import Loader from "../components/Loader";

import { getGlossary } from "../api";

const Alphabet = styled.div`
  display: flex;

  align-items: center;
  flex-wrap: wrap;
  margin: -4px -4px 37px;
  &:after {
    content: "";
    display: block;
    width: 100%;
  }
`;

const Letter = styled(NavLink)`
  padding: 10px;
  background: orange;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px;
  text-decoration: none;
  text-transform: uppercase;
  background: #f7f7f8;
  border-radius: 2px;
  font-size: 18px;
  color: #3b3f42;
  text-align: center;
  line-height: 20px;

  &.active {
    background: ${props => props.theme.mainGradient};
    color: #fff;
  }
  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.3;
  }
`;

function Glossary(props) {
  const letter = props.match.params.letter;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    async function getData() {
      try {
        setError(null);
        setData(null);
        setLoading(true);
        const { data, status } = await getGlossary();
        if (status === 204) {
          setError("aucun contenu");
        }
        setData(data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }
    getData();
  }, []);

  if (letter === ":letter") {
    return <Redirect to="/glossaire/a" />;
  }

  return (
    <Page>
      <Title>Glossaire</Title>
      <Block>
        <div>
          {data ? (
            <Alphabet>
              {map(
                [..."abcdefghijklmnopqrstuvwxyz"],

                letter => (
                  <Letter
                    key={letter}
                    to={`/glossaire/${letter}`}
                    className={data[letter] ? "" : "disabled"}
                  >
                    {letter}
                  </Letter>
                )
              )}
            </Alphabet>
          ) : null}
          {!loading ? (
            data && data[letter] ? (
              <Accordion items={data[letter]} />
            ) : (
              error || "Aucun contenu"
            )
          ) : (
            <Loader />
          )}
        </div>
      </Block>
    </Page>
  );
}

export default Glossary;
