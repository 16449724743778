export const baseTheme = {
  mainGradient: "linear-gradient(135deg, #00CFCD 0%, #00A1AD 100%)",
  verticalMainGradient: "linear-gradient(to bottom, #00CFCD 0%, #00A1AD 100%)",
  color: {
    primary: "#1E889C",
    dark: "#2E384B",
    light: "#F7F7F8",
    gray: "#7C7C7D",
    lightGray : '#EDEDED',
    primaryLight: '#00A1AD',
    text : '#3b3f42'
  },
  borderRadius: "4px"
};

export const simulatorTheme = {
  ...baseTheme,
  mainGradient: "linear-gradient(135deg, #fba264 0%, #e36a67 100%)",
  verticalMainGradient: "linear-gradient(to bottom, #fba264 0%, #e36a67 100%)",
  color: {
    ...baseTheme.color,
    primary: "#FF8B5A",
    green : "#9FC64E"
  }
};

export const trainingTheme = {
  ...baseTheme,
  mainGradient: "linear-gradient(135deg, #6A5BBA 0%, #9D92CE 100%)",
  verticalMainGradient: "linear-gradient(to bottom, #9181DA 0%, #7968D2 100%)",
  color: {
    ...baseTheme.color,
    primary: "#956FD6"
  }
};

export default baseTheme;
