import React, { useState } from "react";
import styled from "styled-components/macro"; // eslint-disable-line

import { Formik, Form } from "formik";

import { size, map, get } from "lodash";

import LoginPage from "../layouts/LoginPage";
import { SimulatorButton as Button } from "../components/Button";
import { GradientInput } from "../components/inputs/BasicInput";

import { BasicError, ErrorStyle } from "../components/inputs/Error";

import { passwordReset } from "../api";

const Page = styled(LoginPage)`
  form {
    max-width: 100%;
  }
  ${ErrorStyle} {
    width: 560px;
    max-width: 100%;
  }
`;

function Password(props) {
  const [errors, setErrors] = useState(false);
  return (
    <Page>
      <p
        css={`
          font-size: 18px;
        `}
      >
        Indiquez votre adresse email d'inscription au site pepz.fr
        <br />
        Et recevez à cette adresse un lien pour réinitialiser votre mot de
        passe.
      </p>
      <Formik
        initialValues={{
          email: "",
        }}
        onSubmit={async (values, actions) => {
          setErrors(false);
          try {
            await passwordReset(values.email);

            props.history.push("/password-ok");
          } catch (error) {
            setErrors(
              get(error, `response.data.forgot_password_form.errors`, false)
            );
            actions.setSubmitting(false);
          }
        }}
        render={({ setFieldValue, values }) => {
          return (
            <Form>
              <div
                css={`
                  width: 560px;
                  max-width: 100%;
                  margin: auto;
                `}
              >
                <GradientInput name="email" placeholder="Adresse e-mail" />
              </div>

              {errors && size(errors) > 0
                ? map(errors, (error, i) => (
                    <BasicError key={i} message={error} />
                  ))
                : null}

              <div
                css={`
                  text-align: center;
                  margin-top: 50px;
                `}
              >
                <Button type="submit">Réinitialiser mon mot de passe</Button>
              </div>
            </Form>
          );
        }}
      />
    </Page>
  );
}

export default Password;
