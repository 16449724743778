import React, { Fragment, useEffect, useState } from "react";
import { forEach, get, map, toArray, validate } from "lodash";
import Button from "../../components/Button";
import { Formik } from "formik";
import Input from "../FormWidgets/Input";
import LabelTitle from "../../components/LabelTitle";
import Loader from "../Loader";
import LoaderBase from "react-loader-spinner";
import { RadioGroupItems } from "../FormWidgets/Switch";
import Select from "../../components/FormWidgets/Select";
import SelectZipCodeAndTown from "../FormWidgets/SelectZipCodeAndTown";
import Switch, { RadioGroupLabel } from "../FormWidgets/Switch";
import { getInitialValues } from "../FormWidgets/helpers";
import styled from "styled-components/macro";
import { workSpecification } from "../../api";
import queryString from "query-string";
import ToggleButton from "../inputs/Toggle";
import { Error } from "../inputs/Error";

const FormContainer = styled.form`
  margin-top: 40px;
`;

const FormFields = styled.div`
  .input-buildingLivingArea {
    float: left;
    width: 50%;
    padding: 0 40px;
  }
  .buildingCompletionYear,
  .lientINSEECode {
    float: left;
    width: 50%;
  }
  .HeatingSystemeBfWorkCode {
    display: block;
    width: 50%;
    margin: 60px auto 0;

    label {
      text-align: center;
      margin-bottom: 1rem;
    }
  }
  .input-buildingZipCode {
    float: left;
    clear: both;
    width: 50%;
    padding: 0 40px;
    margin-bottom: 45px;
  }
  .input-localAids {
    width: 205px;
    margin: 25px auto;
    text-align: center;
  }
  .switch-buildingHeatingEnergy {
    margin-top: 45px;
    clear: both;
  }
  .select-clientResidenceDepartment {
    float: left;
    width: 50%;
    padding: 0 40px;
  }

  .wallInsulationStatus,
  .roofInsulationStatus,
  .floorInsulationStatus {
    ${RadioGroupItems} {
      display: grid;

      @media screen and (min-width: 800px) {
        grid-template-columns: 1fr 1fr;
      }

      > div:nth-child(odd) {
        width: 100%;
        @media screen and (min-width: 800px) {
          text-align: right;
          width: auto;
        }
      }
    }
  }

  sup {
    font-size: 0.8rem;
  }
`;

const InputGrid = styled.div`
  display: flex;
  flex-wrap: wrap;

  > ${Input} {
    width: 50%;
    padding: 0 40px;
    text-align: left;
  }
`;

const BtnGroup = styled.div`
  margin-top: 45px;
  ${Button} + ${Button} {
    margin-left: 15px;
  }
`;

export const InputEnergyBillValue = styled(Input)`
  max-width: 355px;
  margin: 0;
`;

export const InlineInputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-top: 60px;
  flex-wrap: wrap;
  position: relative;

  ${RadioGroupLabel} {
    flex: 1 0 100%;
  }
`;

function Step1(props) {
  const reset = get(queryString.parse(props.location.search), "reset");
  const [formData, setFormData] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await workSpecification({
          reset,
        });
        setFormData(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [reset]);
  const { properties } = formData || {};

  return (
    <>
      {formData ? (
        <div>
          <LabelTitle>Caractéristique du logement</LabelTitle>
          <Formik
            initialValues={getInitialValues(formData)}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={async (values, actions) => {
              const { title } = formData;
              setButtonLoading(1);

              try {
                await workSpecification({
                  method: "post",
                  data: {
                    [title]: {
                      ...values,
                      annualEnergyBill: !values.annualEnergyBill,
                      EnergyLabelKnown: values.EnergyLabelBeforeWork && !values.EnergyLabelKnown,
                      buildingPerformancePEBeforeWorksKnown:
                        !values.buildingPerformancePEBeforeWorksKnown,
                      buildingPerformanceFEBeforeWorksKnown:
                        !values.buildingPerformanceFEBeforeWorksKnown,
                      _token: formData["_token"],
                    },
                  },
                });
                props.history.push("/simulator/2");
              } catch (error) {
                setButtonLoading(0);
                forEach(get(error, `response.data.${title}`), (error, key) => {
                  actions.setFieldError(key, get(error, `errors`));
                });
                const firstError = document.querySelector('[class^="Error-"]');
                if (firstError && firstError.parentElement) {
                  firstError.parentElement.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }
              }

              actions.setSubmitting(false);
            }}
            render={({ values, handleSubmit, setFieldValue }) => {
              return (
                <FormContainer onSubmit={handleSubmit}>
                  <FormFields>
                    <InputGrid>
                      {get(properties, "buildingLivingArea") ? (
                        <Input
                          type={properties.buildingLivingArea.type}
                          field={properties.buildingLivingArea}
                          title={properties.buildingLivingArea.title}
                          name="buildingLivingArea"
                        />
                      ) : null}
                      {get(properties, "buildingCompletionYear") ? (
                        <Select
                          field={properties.buildingCompletionYear}
                          name="buildingCompletionYear"
                          title={properties.buildingCompletionYear.title}
                          placeholder="Sélectionner une date"
                          className="buildingCompletionYear"
                          choices={toArray(
                            map(
                              properties.buildingCompletionYear.enum,
                              (option) => {
                                return {
                                  value: option.value,
                                  label: option.label,
                                };
                              },
                            ),
                          )}
                          validate={validate}
                        />
                      ) : null}
                    </InputGrid>
                    <SelectZipCodeAndTown properties={properties} />
                  </FormFields>
                  <FormFields>
                    {get(properties, "buildingType") ? (
                      <Switch
                        field={properties.buildingType}
                        name="buildingType"
                        title={properties.buildingType.title}
                        choices={properties.buildingType.enum}
                      />
                    ) : null}
                    {values.buildingType !== "" ? (
                      <FormFields>
                        {get(properties, "HeatingSystemeBfWorkCode") ? (
                          <Select
                            field={properties.buildingCompletionYear}
                            name="HeatingSystemeBfWorkCode"
                            title={properties.HeatingSystemeBfWorkCode.title}
                            placeholder="Sélectionner le système de chauffage"
                            className="HeatingSystemeBfWorkCode"
                            choices={toArray(
                              map(
                                properties.HeatingSystemeBfWorkCode.enum.filter(
                                  (heating) =>
                                    heating.buildingType ===
                                    values.buildingType ||
                                    heating.buildingType === "BOTH",
                                ),
                                (option) => {
                                  return {
                                    value: option.value,
                                    label: option.label,
                                  };
                                },
                              ),
                            )}
                            validate={validate}
                          />
                        ) : null}

                        <InlineInputWrapper>
                          <RadioGroupLabel>
                            {properties.annualEnergyBillValue.title}
                          </RadioGroupLabel>
                          {get(properties, "annualEnergyBillValue") ? (
                            <InputEnergyBillValue
                              type={properties.annualEnergyBillValue.type}
                              field={properties.annualEnergyBillValue}
                              name="annualEnergyBillValue"
                              displayError={false}
                              className={`m-0 ${values.annualEnergyBill
                                ? "opacity-50 pointer-events-none"
                                : ""
                                }`}
                              suffix="€"
                            />
                          ) : null}
                          {get(properties, "annualEnergyBill") ? (
                            <ToggleButton
                              field={{
                                label: properties.annualEnergyBill.title,
                              }}
                              name="annualEnergyBill"
                            />
                          ) : null}
                        </InlineInputWrapper>
                        <div className="flex">
                          <Error name="annualEnergyBillValue" />
                        </div>
                        <InlineInputWrapper>
                          <RadioGroupLabel>
                            {properties.buildingPerformancePEBeforeWorks.title}
                          </RadioGroupLabel>
                          {get(
                            properties,
                            "buildingPerformancePEBeforeWorks",
                          ) ? (
                            <InputEnergyBillValue
                              type={
                                properties.buildingPerformancePEBeforeWorks.type
                              }
                              field={
                                properties.buildingPerformancePEBeforeWorks
                              }
                              name="buildingPerformancePEBeforeWorks"
                              className={`m-0 ${values.buildingPerformancePEBeforeWorksKnown
                                ? "opacity-50 pointer-events-none"
                                : ""
                                }`}
                              suffix="kWh/m2.an"
                              displayError={false}
                            />
                          ) : null}
                          {get(
                            properties,
                            "buildingPerformancePEBeforeWorksKnown",
                          ) ? (
                            <ToggleButton
                              field={{
                                label:
                                  properties
                                    .buildingPerformancePEBeforeWorksKnown
                                    .title,
                              }}
                              name="buildingPerformancePEBeforeWorksKnown"
                            />
                          ) : null}
                        </InlineInputWrapper>
                        <div className="flex">
                          <Error name="buildingPerformancePEBeforeWorks" />
                        </div>
                        <div className="flex flex-wrap items-center justify-center gap-8 mt-16">
                          <RadioGroupLabel className="w-full">
                            {properties.EnergyLabelBeforeWork.title}
                          </RadioGroupLabel>
                          {get(properties, "EnergyLabelBeforeWork") ? (
                            <div
                              className={
                                values.EnergyLabelKnown
                                  ? "pointer-events-none opacity-50"
                                  : ""
                              }
                            >
                              <Switch
                                field={properties.EnergyLabelBeforeWork}
                                name="EnergyLabelBeforeWork"
                                choices={
                                  properties.EnergyLabelBeforeWork.enum
                                }
                                className="m-0 smallField"
                              />
                            </div>
                          ) : null}
                          {get(properties, "EnergyLabelKnown") ? (
                            <ToggleButton
                              field={{
                                label: properties.EnergyLabelKnown.title,
                              }}
                              name="EnergyLabelKnown"
                            />
                          ) : null}
                        </div>
                        <>
                          <InlineInputWrapper>
                            <RadioGroupLabel>
                              {
                                properties.buildingPerformanceFEBeforeWorks
                                  .title
                              }
                            </RadioGroupLabel>
                            {get(
                              properties,
                              "buildingPerformanceFEBeforeWorks",
                            ) ? (
                              <InputEnergyBillValue
                                type={
                                  properties.buildingPerformanceFEBeforeWorks
                                    .type
                                }
                                field={
                                  properties.buildingPerformanceFEBeforeWorks
                                }
                                name="buildingPerformanceFEBeforeWorks"
                                className={`m-0 ${values.buildingPerformanceFEBeforeWorksKnown
                                  ? "opacity-50 pointer-events-none"
                                  : ""
                                  }`}
                                suffix="kWh/m2.an"
                                displayError={false}
                              />
                            ) : null}
                            {get(
                              properties,
                              "buildingPerformanceFEBeforeWorksKnown",
                            ) ? (
                              <ToggleButton
                                field={{
                                  label:
                                    properties
                                      .buildingPerformanceFEBeforeWorksKnown
                                      .title,
                                }}
                                name="buildingPerformanceFEBeforeWorksKnown"
                              />
                            ) : null}
                          </InlineInputWrapper>
                          <div className="flex">
                            <Error name="buildingPerformanceFEBeforeWorks" />
                          </div>
                        </>

                        {get(properties, "EnerRenoWorksKnown") ? (
                          <Switch
                            field={properties.EnerRenoWorksKnown}
                            name="EnerRenoWorksKnown"
                            title={properties.EnerRenoWorksKnown.title}
                            choices={properties.EnerRenoWorksKnown.enum}
                          />
                        ) : null}
                        {get(properties, "EnerRenoWorksKnown") &&
                          (values.EnerRenoWorksKnown === "0" ||
                            values.EnerRenoWorksKnown === "Partially") ? (
                          <Fragment>
                            {get(properties, "wallInsulationStatus") ? (
                              <Switch
                                field={properties.wallInsulationStatus}
                                name="wallInsulationStatus"
                                className="wallInsulationStatus"
                                title={properties.wallInsulationStatus.title}
                                choices={properties.wallInsulationStatus.enum}
                              />
                            ) : null}
                            {values.buildingType === "HOUSE" ? (
                              <>
                                {get(properties, "roofInsulationStatus") ? (
                                  <Switch
                                    field={properties.roofInsulationStatus}
                                    name="roofInsulationStatus"
                                    className="roofInsulationStatus"
                                    title={
                                      properties.roofInsulationStatus.title
                                    }
                                    choices={
                                      properties.roofInsulationStatus.enum
                                    }
                                  />
                                ) : null}
                                {get(properties, "floorInsulationStatus") ? (
                                  <Switch
                                    field={properties.floorInsulationStatus}
                                    name="floorInsulationStatus"
                                    className="roofInsulationStatus"
                                    title={
                                      properties.floorInsulationStatus.title
                                    }
                                    choices={
                                      properties.floorInsulationStatus.enum
                                    }
                                  />
                                ) : null}
                              </>
                            ) : null}
                          </Fragment>
                        ) : null}
                      </FormFields>
                    ) : null}
                  </FormFields>

                  <BtnGroup
                    css={`
                      text-align: right;
                      clear: both;
                    `}
                  >
                    <Button type="submit">
                      {buttonLoading ? (
                        <LoaderBase
                          type="ThreeDots"
                          color="#ffffff"
                          height="16"
                          width="100"
                        />
                      ) : (
                        "Etape Suivante"
                      )}
                    </Button>
                  </BtnGroup>
                </FormContainer>
              );
            }}
          />
        </div>
      ) : (
        <div
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 90vh;
          `}
        >
          <Loader />
        </div>
      )}
    </>
  );
}

export default Step1;
