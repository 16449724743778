import * as Sentry from "@sentry/browser";

import axios from "axios";
import { get, map } from "lodash";
import { history } from "./App";
import { toast } from "react-toastify";
import { useQuery } from "react-query";

import fileDownload from "js-file-download";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;

const OPENDATASOFT_API_URL = process.env.REACT_APP_GEO_API_URL;

export const logout = async () => {
  try {
    await makeRequest({
      url: `${BASE_API_URL}/logout`,
    });
  } catch (error) {
    throw error;
  }

  window.localStorage.clear();
  history.push("/login");
};

export const makeRequest = async ({
  url,
  method = "get",
  data = {},
  extra = {},
}) => {
  if (!url) {
    throw new Error("no url specified");
  }

  try {
    const token = window.localStorage.getItem("token") || "";
    const { headers = {}, ...restExtra } = extra;

    const res = await axios({
      method,
      url,
      data,
      withCredentials: true,
      headers: { Authorization: "bearer " + token, ...headers },
      ...restExtra,
    });

    if (res.data.reset) {
      history.push("/simulator/1");
    }

    return res;
  } catch (error) {
    if (
      error.response.data.message === "Expired JWT Token" ||
      error.response.data.message === "Invalid JWT Token"
    ) {
      logout();
      return;
    }

    Sentry.withScope((scope) => {
      scope.setExtra("response", get(error, "response.data", error));
      Sentry.captureException(error);
    });

    if (error.response.status > 400) {
      if (
        error.response.status !== 401 &&
        error.response.data.custom === undefined
      ) {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }

      if (error.response.data.custom !== undefined) {
        toast.error(error.response.data.custom, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }

      throw error;
    } else {
      throw error;
    }
  }
};

export const workSpecification = async (options = {}) => {
  try {
    return await makeRequest({
      url: `${BASE_API_URL}/simulator/work-specification${options.reset ? "?reset=1" : ""
        }`,
      ...options,
    });
  } catch (error) {
    throw error;
  }
};

export const workChoice = async (options = {}) => {
  try {
    return await makeRequest({
      url: `${BASE_API_URL}/simulator/work-choice`,
      ...options,
    });
  } catch (error) {
    throw error;
  }
};

export const workChoiceTooltip = async (options = {}) => {
  try {
    return await makeRequest({
      url: `${BASE_API_URL}/simulator/work-choice/tooltip`,
      ...options,
    });
  } catch (error) {
    throw error;
  }
};

export const costEstimate = async (options = {}) => {
  try {
    return await makeRequest({
      url: `${BASE_API_URL}/simulator/cost-estimate`,
      ...options,
    });
  } catch (error) {
    throw error;
  }
};

export const costEstimateValidation = async (id, options = {}) => {
  try {
    const { data } = options;
    if (data.costKnown === "") {
      return { data: null };
    }

    if (
      data.costKnown === "1" &&
      (data.materialCost === "" || data.workforceCost === "")
    ) {
      return { data: null };
    }
    delete data.totalCostGesture;

    return await makeRequest({
      url: `${BASE_API_URL}/simulator/cost-estimate/${id}`,
      ...options,
      method: "post",
    });
  } catch (error) {
    throw error;
  }
};

export const personalInformation = async (options = {}) => {
  try {
    return await makeRequest({
      url: `${BASE_API_URL}/simulator/personal-information`,
      ...options,
    });
  } catch (error) {
    throw error;
  }
};

export const aidEstimate = async (option = null, options = {}) => {
  if (option !== null) {
    try {
      return await makeRequest({
        url: `${BASE_API_URL}/simulator/aid-estimate?localAids=${option}`,
        ...options,
      });
    } catch (error) {
      throw error;
    }
  } else {
    try {
      return await makeRequest({
        url: `${BASE_API_URL}/simulator/aid-estimate`,
        ...options,
      });
    } catch (error) {
      throw error;
    }
  }
};
export const savingEstimate = async (option, options = {}) => {
  try {
    options.method = "post";
    return await makeRequest({
      url: `${BASE_API_URL}/simulator/saving-estimate`,
      ...options,
    });
  } catch (error) {
    throw error;
  }
};

export const addAdditionalLoan = async (option, options = {}) => {
  try {
    return await makeRequest({
      url: `${BASE_API_URL}/simulator/saving-estimate/additional-loan`,
      ...options,
    });
  } catch (error) {
    throw error;
  }
};

export const getSimulationStatus = async (options = {}) => {
  try {
    return await makeRequest({
      url: `${BASE_API_URL}/simulator/simulation/status`,
      ...options,
    });
  } catch (error) {
    throw error;
  }
};

export const login = async (options = {}) => {
  try {
    const res = await makeRequest({
      url: `${BASE_API_URL}/login`,
      ...options,
      method: "post",
    });

    window.localStorage.setItem("token", res.data.token);
    return res;
  } catch (error) {
    throw error;
  }
};

export const emailDomainForBankingAgency = async (options = {}) => {
  try {
    const res = await makeRequest({
      url: `${BASE_API_URL}/banking_agency_`,
      ...options,
      method: "post",
    });

    window.localStorage.setItem("token", res.data.token);
    return res;
  } catch (error) {
    throw error;
  }
};

export const registration = async (options = {}) => {
  try {
    const res = await makeRequest({
      url: `${BASE_URL}/registration`,
      ...options,
      method: "post",
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export const getAgencyInformations = async (slug) => {
  try {
    return await makeRequest({
      url: `${BASE_URL}/registration/banking-agency/${slug}`,
      method: "get",
    });
  } catch (error) {
    throw error;
  }
};

export const passwordReset = async (email) => {
  try {
    return await makeRequest({
      url: `${BASE_URL}/forgot/password?email=${email}`,
      method: "get",
    });
  } catch (error) {
    throw error;
  }
};

export const changePassword = async ({
  token,
  email,
  password,
  passwordRepeat,
  csrfToken,
}) => {
  try {
    return await makeRequest({
      url: `${BASE_URL}/activation/account`,
      method: "post",
      data: {
        invit_token: token,
        email,
        password,
        repeated_password: passwordRepeat,
        "_token": csrfToken,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async ({
  token,
  email,
  password,
  passwordRepeat,
  csrfToken,
}) => {
  try {
    return await makeRequest({
      url: `${BASE_URL}/change/password`,
      method: "post",
      data: {
        forgot_pwd_token: token,
        email,
        password,
        repeated_password: passwordRepeat,
        "_token": csrfToken,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const getHelp = async () => {
  try {
    return await makeRequest({
      url: `${BASE_API_URL}/help`,
      method: "get",
    });
  } catch (error) {
    throw error;
  }
};

export const getGlossary = async () => {
  try {
    return await makeRequest({
      url: `${BASE_API_URL}/glossary`,
      method: "get",
    });
  } catch (error) {
    throw error;
  }
};
export const getSpecificGlossary = async (letter = "a") => {
  try {
    return await makeRequest({
      url: `${BASE_API_URL}/glossary?letter=${letter}`,
      method: "get",
    });
  } catch (error) {
    throw error;
  }
};

export const getNewsCategories = async () => {
  try {
    return await makeRequest({
      url: `${BASE_API_URL}/news/categories`,
      method: "get",
    });
  } catch (error) {
    throw error;
  }
};
export const getAllNews = async (limit = 10, offset = 0, categories = []) => {
  try {
    return await makeRequest({
      url: `${BASE_API_URL}/news?categories=${categories.join(
        ","
      )}&posts_per_page=${limit}&offset=${offset}`,
      method: "get",
    });
  } catch (error) {
    throw error;
  }
};

export const getNews = async (id) => {
  if (!id) return;
  try {
    return await makeRequest({
      url: `${BASE_API_URL}/new?post=${id}`,
      method: "get",
    });
  } catch (error) {
    throw error;
  }
};

export const getFeaturedNews = async () => {
  try {
    return await makeRequest({
      url: `${BASE_API_URL}/news/featured-post`,
      method: "get",
    });
  } catch (error) {
    throw error;
  }
};

export const getToolbox = async () => {
  try {
    return await makeRequest({
      url: `${BASE_API_URL}/toolbox`,
      method: "get",
    });
  } catch (error) {
    throw error;
  }
};

export const useGetAidsPdf = () => {
  const token = window.localStorage.getItem("token") || "";

  return useQuery(
    "getAidsPdf",
    async () => {
      return await axios({
        method: "GET",
        url: `${BASE_API_URL}/toolbox/aides-pdf`,
        withCredentials: true,
        headers: { Authorization: "bearer " + token },
      }).then(({ data }) => data);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      retry: false,
    }
  );
};

export const useGetAidsTables = () => {
  const token = window.localStorage.getItem("token") || "";

  return useQuery(
    "getAidsTables",
    async () => {
      return await axios({
        method: "GET",
        url: `${BASE_API_URL}/toolbox/tableaux-aides`,
        withCredentials: true,
        headers: { Authorization: "bearer " + token },
      }).then(({ data }) => data);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      retry: false,
    }
  );
};

export const getDebug = async () => {
  try {
    return await makeRequest({
      url: `${BASE_API_URL}/simulator/debug`,
      method: "get",
    });
  } catch (error) {
    throw error;
  }
};

export const setDebugSimulation = async ({ simulation, csrfToken }) => {
  try {
    return await makeRequest({
      url: `${BASE_API_URL}/simulator/debug`,
      method: "post",
      data: {
        simulation,
        "_token": csrfToken,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const getSimulationCount = async () => {
  try {
    return await makeRequest({
      url: `${BASE_API_URL}/simulator/simulation/count`,
      method: "get",
    });
  } catch (error) {
    throw error;
  }
};

export const useGetLimitedLearning = () => {
  const token = window.localStorage.getItem("token") || "";

  return useQuery(
    "getLimitedLearning",
    async () => {
      return await axios({
        method: "GET",
        url: `${BASE_API_URL}/learning/is-limited`,
        withCredentials: true,
        headers: { Authorization: "bearer " + token },
      }).then(({ data }) => data);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      retry: false,
    }
  );
};

export const isFirstUserLearning = async () => {
  try {
    return await makeRequest({
      url: `${BASE_API_URL}/learning/is-first-user-learning`,
      method: "get",
    });
  } catch (error) {
    throw error;
  }
};

export const setFirstUserLearningPopupHasBeenRead = async () => {
  try {
    return await makeRequest({
      url: `${BASE_API_URL}/learning/user-has-seen-popup`,
      method: "post",
    });
  } catch (error) {
    throw error;
  }
};

export const getLearningCompletion = async () => {
  try {
    return await makeRequest({
      url: `${BASE_API_URL}/learning/global_completion`,
      method: "get",
    });
  } catch (error) {
    throw error;
  }
};

export const sendLearningCompletionEmail = async () => {
  try {
    return await makeRequest({
      url: `${BASE_API_URL}/learning/learning-completed`,
      method: "post",
    });
  } catch (error) {
    throw error;
  }
};

export const getToolboxInfos = async (type, id) => {
  try {
    return await makeRequest({
      url: `${BASE_API_URL}/toolbox/${type}/${id}`,
      method: "get",
    });
  } catch (error) {
    throw error;
  }
};

export const getTrainings = async () => {
  try {
    return await makeRequest({
      url: `${BASE_API_URL}/learning/stage`,
      method: "get",
    });
  } catch (error) {
    throw error;
  }
};
export const getTraining = async (id) => {
  try {
    return await makeRequest({
      url: `${BASE_API_URL}/learning/training/${id}`,
      method: "get",
    });
  } catch (error) {
    throw error;
  }
};

export const getStage = async (id) => {
  try {
    return await makeRequest({
      url: `${BASE_API_URL}/learning/stage/${id}/training`,
      method: "get",
    });
  } catch (error) {
    throw error;
  }
};

export const getTrainingLibrary = async () => {
  try {
    return await makeRequest({
      url: `${BASE_API_URL}/learning/library`,
      method: "get",
    });
  } catch (error) {
    throw error;
  }
};

export const getPageBySlug = async (slug) => {
  try {
    return await makeRequest({
      url: `${BASE_URL}/page/${slug}`,
      method: "get",
    });
  } catch (error) {
    throw error;
  }
};

export const sendMails = async (pdfToken, uuid, mails) => {
  try {
    return await makeRequest({
      url: `${BASE_API_URL}/pdf/mail/${uuid}/${pdfToken}/${mails}`,
      method: "get",
    });
  } catch (error) {
    throw error;
  }
};

export const getPDFSimulation = async (pdfToken, uuid) => {
  try {
    const response = await makeRequest({
      extra: {
        responseType: "blob",
        headers: {
          "Content-Type": "application/octet-stream",
        },
      },
      method: "POST",
      url: `${BASE_API_URL}/pdf/view/${uuid}/${pdfToken}/`,
      withCredentials: true,
    });
    console.log({ response });

    return new Blob([response.data]);
  } catch (error) {
    if (error.response.status === 401) {
      toast.error("Aucun Scénario n'a été trouvé", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    throw error;
  }
};

export const contact = async ({ subject, message, token, csrfToken }) => {
  try {
    return await makeRequest({
      url: `${BASE_API_URL}/contact/send`,
      method: "post",
      data: {
        subject,
        message,
        "g-recaptcha-response": token,
        "_token": csrfToken,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const useTownWithZipCode = (code) => {
  const { data, ...rest } = useQuery(
    ["useTownWithZipCode", code],
    async () => {
      return await axios({
        url: OPENDATASOFT_API_URL,
        params: { codePostal: code },
      }).then(({ data }) => data);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      retry: false,
      enabled: !!code && code.length === 5,
    }
  );

  return {
    data: map(data, (element) => ({
      insee: element.code,
      townName: element.nom,
      department: element.codeDepartement,
    })),
    ...rest,
  };
};

export const getTownWithZipCode = async (code) => {
  let requestResult = {};

  // Little function to create an error toast
  let createErrorToast = function (message) {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  // Try to make request to the API, if error a toast is shown
  try {
    requestResult = await axios({
      url: OPENDATASOFT_API_URL,
      params: { codePostal: code },
    });
  } catch (error) {
    createErrorToast(error.message);

    return;
  }

  // Check if the API call return a status code other than 200 or an empty result
  if (
    200 !== requestResult.status ||
    undefined === requestResult.data ||
    0 === requestResult.data.length
  ) {
    createErrorToast("Aucune commune pour ce code postal");
    return;
  }

  // Create result and return it
  let data = requestResult.data;

  var result = [];

  data.map((element) =>
    result.push({
      insee: element.code,
      townName: element.nom,
      department: element.codeDepartement,
    })
  );

  return result;
};

export const piaGetLocalAidsInformations = async (fundings) => {
  try {
    return await makeRequest({
      url: `${BASE_API_URL}/pia/local-aids/informations`,
      method: "get",
      data: {
        fundings: fundings,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const getStatistics = async () => {
  try {
    return await makeRequest({
      url: `${BASE_API_URL}/statistics/get`,
      method: "get",
    });
  } catch (error) {
    throw error;
  }
};

export const exportSimulationData = async () => {
  try {
    const { data } = await makeRequest({
      url: `${BASE_API_URL}/statistics/export-simu`,
      method: "get",
      extra: {
        responseType: "blob",
        headers: {
          "Content-Type": "application/octet-stream",
        },
      },
      withCredentials: true,
    });
    fileDownload(data, "export_simulations.csv");
  } catch (error) {
    throw error;
  }
};

export const exportLearningData = async () => {
  try {
    const { data } = await makeRequest({
      url: `${BASE_API_URL}/statistics/export-learning`,
      method: "get",
      extra: {
        responseType: "blob",
        headers: {
          "Content-Type": "application/octet-stream",
        },
      },
      withCredentials: true,
    });
    fileDownload(data, "export_learnings.csv");
  } catch (error) {
    throw error;
  }
};

export const getPrecarityAmounts = async (region, personCount) => {
  try {
    const data = await makeRequest({
      url: `${BASE_API_URL}/toolbox/precarity_amount/${region}/${personCount}`,
      method: "get",
    });
    return data.data;
  } catch (error) {
    throw error;
  }
};

export const getCsrfToken = async (csrfId) => {
  try {
    const res = await makeRequest({
      url: `${BASE_API_URL}/csrf_token`,
      data: {
        csrfId: csrfId,
      },
      method: "post",
    });

    if (res.status === 200) {
      return res.data;
    }
    return null;
  } catch (error) {
    throw error;
  }
};
