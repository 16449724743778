import React, { useEffect, useState } from "react";
import Page from "../layouts/Page";
import Title from "../components/Title";
import Routes from "../routes";
import { exportSimulationData, exportLearningData, getStatistics } from "../api";
import AdvisorLearningBlock from "../components/Statistics/AdvisorLearningBlock";
import AdvisorSimulatorBlock from "../components/Statistics/AdvisorSimulatorBlock";
import DirectionLearningBlock from "../components/Statistics/DirectionLearningBlock";
import DirectionSimulatorBlock from "../components/Statistics/DirectionSimulatorBlock";
import ReactSVG from "react-svg";
import exportIcon from "../svgs/export.svg";

export default function Statistics() {
  const [statistics, setStatistics] = useState(null);
  useEffect(() => {
    async function getStats() {
      try {
        const { data } = await getStatistics();
        setStatistics(data?.statistics);
      } catch (error) {
        console.error(error);
      }
    }
    getStats();
  }, []);

  function exportLearnings() {
    try {
      exportLearningData().then((response) => {
        console.log({response});
      })
    } catch (error) {}
  }
  function exportSimulations() {
    try {
      exportSimulationData().then((response) => {
        console.log({ response });
      });
    } catch (error) {}
  }
  return (
    <Page>
      <div className="flex justify-between items-center">
        <Title className="pageTitle">{Routes().statistics.label}</Title>
        <div className="text-white">
          <button className="flex-center gap-2" onClick={exportLearnings}>
            <ReactSVG className="w-6 text-white" src={exportIcon} />
            Formations.CSV
          </button>
          <button className="flex-center gap-2" onClick={exportSimulations}>
            <ReactSVG className="w-6 text-white" src={exportIcon} />
            Simulations.CSV
          </button>
        </div>
      </div>
      <div className="grid xl:grid-cols-2 gap-4">
        {statistics?.hasAdvisor ? (
          <>
            {statistics?.learnings && (
              <AdvisorLearningBlock learnings={statistics?.learnings} />
            )}
            {statistics?.simulator && (
              <AdvisorSimulatorBlock simulator={statistics?.simulator} />
            )}
          </>
        ) : (
          <>
            {statistics?.learnings && (
              <DirectionLearningBlock learnings={statistics?.learnings} />
            )}
            {statistics?.simulator && (
              <DirectionSimulatorBlock simulator={statistics?.simulator} />
            )}
          </>
        )}
      </div>
    </Page>
  );
}
