import React from "react";
import * as Yup from "yup";
import { mapValues, map, reduce, get, toArray } from "lodash";

import ChoiceMultiple from "./ChoiceMultiple";
import Choice from "./Choice";
import Nested from "./Nested";
import NestedCost from "./NestedCost";
import Switch from "./Switch";
import Input from "./Input";
import Select from "./Select";

export function getInitialValues(formData) {
  return {
    ...mapValues(formData.properties, k => {
      switch (k.type) {
        case "string":
          return "";
        case "array":
          return [];
        case "object":
          return mapValues(k.properties, kn => ({}));
        default:
          return null;
      }
    }),
    ...formData.values,
  };
}

export function GetMatchingWidget({
  field,
  fieldKey,
  values,
  errors,
  simpleTitle,
  cost,
  validate,
  handleChange,
  handleBlur,
  parent,
  active,
  checkPrimaryWorks
}) {
  switch (field.widget) {
    case "select":
      return (
        <Select
          field={field}
          key={fieldKey}
          name={fieldKey}
          title={field.title}
          values={values}
          choices={toArray(
            map(field.enum, option => {
              return {
                value: option.value,
                label: option.label
              };
            })
          )}
          validate={validate}
          parent={parent}
        />
      );
    case "switch":
      return (
        <Switch
          field={field}
          key={fieldKey}
          name={fieldKey}
          title={field.title}
          choices={field.enum}
          values={values}
          errors={errors && errors[fieldKey]}
          validate={validate}
          parent={parent}
        />
      );
    case "input":
      return (
        <div key={fieldKey}>
          {field.before && <p className="mt-6">{field.before}</p>}
          <Input
            field={field}
            key={fieldKey}
            name={fieldKey}
            title={field.title}
            choices={field.enum}
            values={values}
            type={field.type}
            error={errors && errors[fieldKey]}
            validate={validate}
            suffix={field.suffix}
            parent={parent}
            tooltip={field.tooltip}
            className={field.className}
          />
        </div>
      );
    case "checkbox":
      return (
        <ChoiceMultiple
          field={field}
          key={fieldKey}
          name={fieldKey}
          title={field.title}
          choices={field.items.enum}
          values={values}
          simpleTitle={simpleTitle}
          errors={errors && errors[fieldKey]}
          validate={validate}
          parent={parent}
          active={active}
        />
      );
    case "radio":
      return (
        <Choice
          field={field}
          key={fieldKey}
          name={fieldKey}
          title={field.title}
          choices={field.enum}
          values={values}
          simpleTitle={simpleTitle}
          error={errors && errors[fieldKey]}
          validate={validate}
          parent={parent}
          active={active}
          checkPrimaryWorks={checkPrimaryWorks}
        />
      );
    case "nested":
      return (
        <Nested
          field={field}
          key={fieldKey}
          name={fieldKey}
          title={field.title}
          fields={field.properties}
          values={values}
          simpleTitle={true}
          parent={fieldKey}
          errors={errors && errors[fieldKey]}
          active={active}
        />
      );
    case "nested-cost":
      return (
        <NestedCost
          field={field}
          key={fieldKey}
          name={fieldKey}
          title={field.title}
          fields={field.properties}
          values={values[fieldKey]}
          errors={errors && errors[fieldKey]}
          handleChange={handleChange}
          handleBlur={handleBlur}
          cost={cost}
        />
      );
    default:
      return null;
  }
}

export function validationSchema(formData) {
  return Yup.object().shape({
    ...reduce(
      formData.required,
      (acc, next) => {
        let type = null;
        switch (get(formData, `properties.${next}.type`)) {
          case "string":
            type = Yup.string()
              .min(1)
              .required("Requis");
            break;
          case "number":
            type = Yup.number().required("Requis");
            break;
          case "array":
            type = Yup.array()
              .min(1)
              .required("Requis");
            break;
          default:
            break;
        }
        return {
          ...acc,
          [next]: type
        };
      },
      {}
    )
  });
}
