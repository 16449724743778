import React from "react";

import { ThemeProvider } from "styled-components/macro";
import { simulatorTheme } from "../theme";

import Page from "../layouts/Page";
import Title from "../components/Title";

function DisabledSimulator(props) {
  return (
    <ThemeProvider theme={simulatorTheme}>
      <Page>
        <Title>Simulateur d'aides</Title>
        <p class={"text-center"}>Le simulateur n'est pas accessible pour votre agence bancaire</p>
      </Page>
    </ThemeProvider>
  );
}

export default DisabledSimulator;
