import styled from "styled-components/macro";

const LabelTitle = styled.div`
  font-size: 18px;
  line-height: 22px;
  text-align: center;

  [class^="Tooltip-"] {
    margin-left: 3px;
    display: inline-block;
    vertical-align: middle;
  }
`;

export default LabelTitle;
