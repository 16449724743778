import React, { useState } from "react";
import Page from "../layouts/Page";
import Title from "../components/Title";
import Routes from "../routes";
import BlockWithTitle from "../components/BlockWithTitle";
import { Formik } from "formik";
import Select from "../components/FormWidgets/Select";
import Input from "../components/FormWidgets/Input";
import { buildingOccupationTypeData } from "../data/toolboxTableData";
import arrowIcon from "../svgs/arrow-submit.svg";
import * as Yup from "yup";
import ReactSVG from "react-svg";
import { SelectAmountPrecarity } from "../components/FormWidgets/SelectAmountPrecarity";
import { useGetAidsTables } from "../api";
import Loader from "../components/Loader";
import NationalHelpTable from "../components/ToolboxTable/NationalHelpTable";
import { find } from "lodash";

const validationSchema = Yup.object().shape({
  buildingOccupationType: Yup.string().required("Requis"),
  clientFiscalPersonsCount: Yup.number().required("Requis"),
  clientIncomeFiscalAmountYearly: Yup.string().required("Requis"),
});

export default function ToolboxTable() {
  const [buildingOccupationType, setBuildingOccupationType] = useState(null);
  const [
    clientIncomeFiscalAmountYearly,
    setClientIncomeFiscalAmountYearly,
  ] = useState(null);

  return (
    <Page>
      <div className="flex justify-between items-center">
        <Title className="pageTitle">{Routes().toolbox.label}</Title>
      </div>
      <div className="grid  gap-4">
        <BlockWithTitle title="Tableau récapitulatif des aides nationales">
          <Formik
            onSubmit={async (values, actions) => {
              const regex = /(^.+?)-/;
              let m;
              if (
                (m = regex.exec(values.buildingOccupationType)) !== null &&
                m[1]
              ) {
                setBuildingOccupationType(m[1]);
                setClientIncomeFiscalAmountYearly(
                  values.clientIncomeFiscalAmountYearly
                );
              }
            }}
            initialValues={{
              buildingOccupationType: null,
              clientIncomeFiscalAmountYearly: null,
              clientFiscalPersonsCount: 0,
            }}
            validationSchema={validationSchema}
            render={({ values, handleSubmit, isValid }) => {
              return (
                <form className="" onSubmit={handleSubmit}>
                  <div className="grid xl:grid-cols-3 gap-4">
                    <Select
                      name="buildingOccupationType"
                      title="Détention du bien"
                      placeholder="Sélectionner une proposition"
                      choices={buildingOccupationTypeData}
                    />
                    <Input
                      type="number"
                      title="Nombre de personnes dans le ménage"
                      name="clientFiscalPersonsCount"
                      placeholder="Entrer un chiffre"
                    />

                    <SelectAmountPrecarity
                      buildingOccupationType={values.buildingOccupationType}
                      personsCount={values.clientFiscalPersonsCount}
                    />
                  </div>
                  <div className="pt-10">
                    <button
                      className={`${
                        !isValid
                          ? "bg-gradient-to-r from-gray-400 to-gray-600 cursor-not-allowed "
                          : "cyan-gradient"
                      } p-4 rounded-md min-w-10 text-white uppercase flex items-center gap-2`}
                      disabled={!isValid}
                      type="submit"
                    >
                      <ReactSVG src={arrowIcon} className="arrowIcon w-8 h-8" />
                      <span>Générer le tableau des aides</span>
                    </button>
                  </div>
                </form>
              );
            }}
          />
          <div>
            {buildingOccupationType && clientIncomeFiscalAmountYearly && (
              <SwitchTable
                buildingOccupationType={buildingOccupationType}
                clientIncomeFiscalAmountYearly={clientIncomeFiscalAmountYearly}
              />
            )}
          </div>
        </BlockWithTitle>
      </div>
    </Page>
  );
}

function SwitchTable({
  buildingOccupationType,
  clientIncomeFiscalAmountYearly,
}) {
  const veryLow = clientIncomeFiscalAmountYearly === "very-low";
  const low = clientIncomeFiscalAmountYearly === "low";
  const intermediate = clientIncomeFiscalAmountYearly === "intermediate";
  const hight = clientIncomeFiscalAmountYearly === "hight";
  const principal = buildingOccupationType === "principal";
  const { data: aidsTable, isFetched } = useGetAidsTables();

  if (!isFetched) {
    return <Loader />;
  }
  if (veryLow && principal) {
    return (
      <NationalHelpTable
        code="table-principal-tm"
        table={find(aidsTable, { code: "table-principal-tm" })}
      />
    );
  }
  if (veryLow && !principal) {
    return (
      <NationalHelpTable
        code="table-secondaire-tm"
        table={find(aidsTable, { code: "table-secondaire-tm" })}
      />
    );
  }
  if (low && principal) {
    return (
      <NationalHelpTable
        code="table-principal-m"
        className="NationalHelpTable--low"
        table={find(aidsTable, { code: "table-principal-m" })}
      />
    );
  }
  if (low && !principal) {
    return (
      <NationalHelpTable
        code="table-secondaire-m"
        className="NationalHelpTable--low"
        table={find(aidsTable, { code: "table-secondaire-m" })}
      />
    );
  }
  if (intermediate && principal) {
    return (
      <NationalHelpTable
        code="table-principal-i"
        className="NationalHelpTable--intermediate"
        table={find(aidsTable, { code: "table-principal-i" })}
      />
    );
  }
  if (intermediate && !principal) {
    return (
      <NationalHelpTable
        code="table-secondaire-i"
        className="NationalHelpTable--intermediate"
        table={find(aidsTable, { code: "table-secondaire-i" })}
      />
    );
  }
  if (hight && principal) {
    return (
      <NationalHelpTable
        code="table-principal-s"
        className="NationalHelpTable--hight"
        table={find(aidsTable, { code: "table-principal-s" })}
      />
    );
  }
  if (hight && !principal) {
    return (
      <NationalHelpTable
        code="table-secondaire-s"
        className="NationalHelpTable--hight"
        table={find(aidsTable, { code: "table-secondaire-s" })}
      />
    );
  }

  return null;
}
