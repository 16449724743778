import React from "react";
import styled from "styled-components/macro"; // eslint-disable-line
import LoaderBase from "react-loader-spinner";

function Loader() {
  return (
    <div
      css={`
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 20vh;
      `}
    >
      <LoaderBase type="ThreeDots" color="#2E384B" height="100" width="100" />
    </div>
  );
}

export default Loader;
