import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components/macro";
import { Formik } from "formik";
import { map, forEach, get, omit } from "lodash";
import Button, { LightButton } from "../../components/Button";
import { getInitialValues } from "../FormWidgets/helpers";
import NestedCost from "../FormWidgets/NestedCost";
import Loader from "../Loader";
import Input from "../FormWidgets/Input";
import { costEstimate } from "../../api";
import LoaderBase from "react-loader-spinner";

const FormContainer = styled.form`
  margin-top: 40px;
`;

const FormFields = styled.div``;

const FormFooter = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  position: sticky;
  bottom: 0;
  background: #fff;
  padding: 30px;
`;
const FormFooterTotal = styled.div`
  background: ${(props) => props.theme.color.dark};
  color: #fff;
  border-radius: 10px;
  padding: 30px 70px;
  white-space: nowrap;
  text-align: center;
`;

const BtnGroup = styled.div`
  margin-top: 45px;
  ${Button} + ${Button} {
    margin-left: 15px;
  }
`;

function Step3(props) {
  const [totalCost, setTotalCost] = useState(0);
  const [formData, setFormData] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await costEstimate();
        data.properties = omit(data.properties, 'totalCostScenario');
        // Add a proprepy totalCostScenario for each property + controlled field
        map(data.properties, (field) => {
          Object.assign(field.properties, {
            totalCostGesture: {
              type: "number",
              widget: "input",
              title: "Coût total d'un geste",
              propertyOrder: 5,
              suffix: "€",
              before: 'ou'
            },
          });
        });
        map(data.values, (value) => {
          Object.assign(value, {
            totalCostGesture: '',
          });
        })
        setFormData(data);
        setTotalCost(data.costSummary.totalCost);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const costProject = useCallback((values) => {
    delete values.totalCostScenario;
    return map(values, (value) => {
      return parseInt(value.materialCost + value.workforceCost, 10)
    }).reduce((accumulator, currentValue) => {
      return accumulator + currentValue
    }, 0);;
  }, []);


  const updateTotal = useCallback((data) => {
    setTotalCost(data.totalCost);
  }, []);

  return (
    <>
      {formData ? (
        <Formik
          initialValues={getInitialValues(formData)}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={async (values, actions) => {
            const { title } = formData;
            setButtonLoading(1);
            map(values, (value) => {
              delete value.totalCostGesture;
            })
            try {
              await costEstimate({
                method: "post",
                data: {
                  [title]: {
                    ...values,
                    "_token": formData["_token"],
                  },
                },
              });
              props.history.push("/simulator/4");
            } catch (error) {
              setButtonLoading(0);
              forEach(get(error, `response.data.${title}`), (error, key) => {
                forEach(error, (nestedError, nestedKey) => {
                  actions.setFieldError(
                    `${key}[${nestedKey}]`,
                    nestedError.errors
                  );
                });
              });
            }

            actions.setSubmitting(false);
          }}
          render={({ values, errors, handleSubmit, setFieldError }) => {
            return (
              <FormContainer onSubmit={handleSubmit}>
                <div>
                  <FormFields>
                    {map(formData.properties, (field, fieldKey) => {
                      return (
                        <NestedCost
                          key={fieldKey}
                          values={values[fieldKey]}
                          fields={field.properties}
                          title={field.title}
                          name={fieldKey}
                          icon={field.icon}
                          onUpdate={updateTotal}
                          onError={setFieldError}
                          totalCostScenarioControl={values.totalCostScenario}
                        />
                      );
                    })}
                  </FormFields>

                  <FormFooter className="flex items-center gap-8">
                    <div
                      css={`
                        padding: 0 40px;
                        font-size: 12px;
                      `}
                    >
                      *Tous les coûts et chiffres annoncés ici sont des valeurs
                      estimatives destinées à fournir des éléments de choix.
                      <br />
                      Ils n’ont pas valeur de devis et ne peuvent engager la
                      responsabilité de l’entreprise Economie d’énergie SAS.
                    </div>
                    <div className={`max-w-xs ml-auto text-sm ${!totalCost ? 'pointer-events-none opacty-50' : ''}`}>
                      <label >Si indiqué dans le diagnostic du logement, renseigner le coût du scénario de travaux</label>
                      <Input
                        className="mt-2"
                        type="number"
                        name="totalCostScenario"
                        suffix="€"
                      />
                    </div>
                    <FormFooterTotal>
                      <div
                        css={`
                          font-size: 16px;
                        `}
                      >
                        coût global du projet
                      </div>
                      <div
                        css={`
                          font-size: 30px;
                          margin-top: 16px;
                        `}
                      >
                        {values.totalCostScenario ? values.totalCostScenario : costProject(values)} €
                      </div>
                    </FormFooterTotal>
                  </FormFooter>
                </div>

                <BtnGroup
                  css={`
                    text-align: right;
                  `}
                >
                  <LightButton
                    type="button"
                    onClick={() => {
                      props.history.push("/simulator/2");
                    }}
                  >
                    Etape Précédente
                  </LightButton>
                  <Button type="submit">
                    {buttonLoading ? (
                      <LoaderBase
                        type="ThreeDots"
                        color="#ffffff"
                        height="16"
                        width="100"
                      />
                    ) : (
                      "Etape Suivante"
                    )}
                  </Button>
                </BtnGroup>
              </FormContainer>
            );
          }}
        />
      ) : (
        <div
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 90vh;
          `}
        >
          <Loader />
        </div>
      )}
    </>
  );
}

export default Step3;
