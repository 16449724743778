import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { Row, Col } from "styled-bootstrap-grid";
import { get } from "lodash";

import Page from "../layouts/Page";
import Title from "../components/Title";
import Block from "../components/Block";
import ContentDate from "../components/ContentDate";
import ContentTitle from "../components/ContentTitle";
import ContentSubtitle from "../components/ContentSubtitle";
import ContentImage from "../components/ContentImage";
import NewsThread from "../components/NewsThread";
import LastNews from "../components/LastNews";
import NewsLabel from "../components/NewsLabel";
import Loader from "../components/Loader";

import Routes from "../routes";

import { getFeaturedNews } from "../api";
import { formatDate } from "../helpers";

const FeaturedArticle = styled.div``;

const FeaturedArticleLink = styled(Link)`
  color: #3b3f42;
  text-decoration: none;
`;

function News() {
  const [featured, setFeatured] = useState(null);

  useEffect(() => {
    async function getFeatured() {
      try {
        setFeatured(null);
        const { data } = await getFeaturedNews();
        setFeatured(data[0]);
      } catch (error) {
        console.error(error);
      }
    }
    getFeatured();
  }, []);

  return (
    <Page>
      <Title>{Routes().news.label}</Title>
      <Block>
        <Row>
          <Col className="m-t-1" xl="9">
            {featured ? (
              <FeaturedArticle>
                <Row>
                  <Col sm={4}>
                    <ContentDate>
                      {formatDate(new Date(featured.date))}
                    </ContentDate>
                  </Col>
                  <Col
                    sm={8}
                    css={`
                      text-align: right;
                    `}
                  >
                    <NewsLabel key={"top-content"} color={"#7FB1B2"}>
                      A la une
                    </NewsLabel>
                  </Col>
                </Row>
                <FeaturedArticleLink to={`/news/${get(featured, "id")}`}>
                  <ContentTitle>{get(featured, "title.rendered")}</ContentTitle>
                  <ContentImage
                    src={
                      get(featured, "featured_image")
                        ? get(featured, "featured_image")
                        : undefined
                    }
                  />
                  <ContentSubtitle
                    dangerouslySetInnerHTML={{
                      __html: get(featured, "excerpt.rendered")
                    }}
                  />
                </FeaturedArticleLink>
              </FeaturedArticle>
            ) : (
              <Loader />
            )}
          </Col>
          <Col className="m-t-1" xl="3">
            <NewsThread />
          </Col>
        </Row>

        <LastNews noTitle />
      </Block>
    </Page>
  );
}

export default News;
