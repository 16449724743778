import React from "react";
import BlockStat, {
  NumberStat,
  PercentageBlock,
  PercentageLine,
} from "./BlockStat";
import traingIcon from "../../svgs/training.svg";
import { map, upperCase } from "lodash";

const textColor = "text-purple-500";

export default function AdvisorLearningBlock({
  learnings: {
    timeSpent = 0,
    fullVideos = 0,
    globalCompletion = 0,
    stagesCompletion = [],
  },
}) {
  return (
    <BlockStat title="Formation" textColor={textColor} icon={traingIcon}>
      <div className="grid lg:grid-cols-2 gap-4">
        <NumberStat
          textColor={textColor}
          text={timeSpent > 1 ? "minutes passées" : "minute passée"}
          number={timeSpent}
        />
        <NumberStat
          textColor={textColor}
          text={
            fullVideos > 1
              ? "vidéos entièrement parcourues"
              : "vidéo entièrement parcourue"
          }
          number={fullVideos}
        />
      </div>
      <LearningPercentageBlock percent={globalCompletion} />
      {map(stagesCompletion, ({ completion, title }, key) => (
        <LearningPercentLine
          key={key}
          stageTitle={title}
          percent={completion}
        />
      ))}
    </BlockStat>
  );
}

function LearningPercentLine({ stageTitle, percent }) {
  return (
    <PercentageLine
      text={"Parcours " + upperCase(stageTitle)}
      percent={percent}
      bgColor="bg-purple-800"
    />
  );
}

function LearningPercentageBlock({ percent }) {
  const Subtitle = (
    <>
      de formation
      <br />
      terminée
    </>
  );
  return (
    <PercentageBlock
      bgColor="bg-gradient-to-br from-purple-400 to-purple-800"
      percent={percent}
      text={`${percent} %`}
      subtitle={Subtitle}
    />
  );
}
