import * as Yup from "yup";
import { Col, Row } from "styled-bootstrap-grid";
import React, { useEffect, useState } from "react";
import {
  getCsrfToken,
  getPDFSimulation,
  savingEstimate,
  addAdditionalLoan,
} from "../../api";

import BigLink from "../BigLink";
import { Formik } from "formik";
import Input from "../FormWidgets/Input";
import { LightButton } from "../Button";
import Loader from "../Loader";
import LoaderBase from "react-loader-spinner";
import MailSender from "../FormWidgets/MailSender";
import Modal from "../Modal";
import { ClassicModal } from "../Modal";
import ReactSVG from "react-svg";
import Tooltip from "../Tooltip";
import checkIcon from "../../svgs/check.svg";
import confortIcon from "../../svgs/confort.svg";
import { get } from "lodash";
import impactIcon from "../../svgs/impact-eco.svg";
import plusIcon from "../../svgs/plus.svg";
import printIcon from "../../svgs/print.svg";
import warningIcon from "../../svgs/warningV2.svg";
import diagnosticIcon from "../../svgs/diagnostic.svg";
import reventeIcon from "../../svgs/revente.svg";
import styled from "styled-components/macro";
import { Suffix } from "../inputs/BasicInput";
import {
  formatAmountPerMonth,
  formatAmountPrice,
} from "../../utils/formatAmount";
const Reassurance = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  font-weight: 600;
  font-size: 18px;
  color: #3b3f42;
  letter-spacing: 0;
  text-align: center;
  line-height: 20px;
  > div {
    margin-bottom: 20px;
  }
`;

const BlockChildren = styled.div`
  background: #f7f7f8;
  border-radius: 20px;
  padding: 0 55px;
`;

const BlockWrapper = styled.div`
  margin: 0 auto 60px;
  max-width: 1162px;

  ${BlockChildren} > ${Row} {
    &:not(:last-child) {
      border-bottom: 1px solid rgb(216, 216, 216);
    }
  }

  .noticeFinance {
    text-align: center;
    margin: 0 auto 1rem;
    display: block;
  }
  .green {
    color: ${(props) => props.theme.color.green};
  }
`;

const CellTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: #3b3f42;
  letter-spacing: 0;
  line-height: 22px;
  position: relative;

  ${Tooltip} {
    margin: auto 0 auto 5px;
    display: inline-flex;
    vertical-align: middle;
    div {
      display: flex;
    }
  }
`;

const Cell = styled.div`
  padding: 20px 10px;
`;

const Total = styled.div`
  font-weight: 600;
  background: #ffffff;
  border-radius: 10px;
  font-size: 25px;
  color: #3b3f42;
  text-align: center;
  line-height: 22px;
  padding: 25px 30px;
  width: 250px;
  max-width: 100%;
  margin: auto;

  ${Input} {
    margin: 0;
    font-weight: 600;
    font-size: 25px;
    color: #3b3f42;
    text-align: center;
    line-height: 22px;

    div {
      border: 0;
    }

    input {
      margin: 0;
      font-weight: 600;
      font-size: 25px;
      color: #3b3f42;
      text-align: center;
      line-height: normal;
      height: auto;
      padding: 0;
    }

    .customIcon {
      right: 0;
      & + ${Suffix} {
        right: 26px;
      }
    }
  }
`;

const BlockTitle = styled.div`
  width: 565px;
  max-width: 100%;
  padding: 10px;
  margin: 0 auto;
  background-image: ${(props) => props.gradient};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  font-weight: 400;
  font-size: 24px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  line-height: 22px;
`;

const Button = styled.button`
  background-image: linear-gradient(135deg, #fba264 0%, #e36a67 100%);
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  line-height: 22px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  margin-top: 5px;
  padding: 3px 10px;
  border: 0;
  text-transform: uppercase;
  div {
    display: inline-flex;
  }
  svg {
    margin-right: 10px;
    width: 15px;
    height: 15px;
    path {
      fill: #fff;
      stroke: #fff;
    }
  }
`;

// Disable PDF
const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px auto;
  max-width: 1162px;
  position: relative;
  ${BigLink} {
    max-width: 275px;
    text-align: center;
    padding: 17px;
    > span {
      margin-top: 10px;
    }

    + ${MailSender} {
      margin-left: 15px;
    }
  }
  ${MailSender} {
    flex: 1;
  }

  ${LightButton} {
    margin-right: 1rem;
    left: 0;

    @media (min-width: 1184px) {
      position: absolute;
    }
  }
`;

const DisabledButton = styled(Button)`
  cursor: not-allowed;
  background: #9e9e9e;
`;

// default duration for EPTZ loan
const DEFAULT_EPTZ_DURATION = 20;
// default duration for classic loan
const DEFAULT_LOAN_DURATION = 15;
// default rate for classic loan
const DEFAULT_LOAN_RATE = 1.5;


function Block({ className, title, color, gradient, children }) {
  return (
    <BlockWrapper className={className}>
      <BlockTitle color={color} gradient={gradient}>
        {title}
      </BlockTitle>
      <BlockChildren>{children}</BlockChildren>
    </BlockWrapper>
  );
}

function etiquetteDPE({ energyLabelBeforeWork, knowEtiquetteDPE, buildingOccupationType }) {
  const energyLow = ["E", "F", "G"];
  if (energyLow.includes(energyLabelBeforeWork) && buildingOccupationType === "OWNER_LESSOR" && knowEtiquetteDPE !== "0") {
    return energyLabelBeforeWork;
  }
  if (knowEtiquetteDPE === "0") {
    return energyLabelBeforeWork = null;
  }
  return null;
}

function buttonDPE({ energyLabelBeforeWork, knowEtiquetteDPE, buildingOccupationType, buildingUsage }) {
  const energyLow = ["E", "F", "G"];
  if ((energyLow.includes(energyLabelBeforeWork) || knowEtiquetteDPE === "0") && buildingOccupationType === "OWNER_LESSOR") {
    return "Vos obligations à venir";
  }
  if (
    (energyLabelBeforeWork === "F" || energyLabelBeforeWork === "G" || knowEtiquetteDPE === "0")
    && buildingOccupationType === "TENANT" && buildingUsage === "MAIN_RESIDENCE"
  ) {
    return "Vos obligations à venir";
  }
  if (energyLow.includes(energyLabelBeforeWork) || knowEtiquetteDPE === "0") {
    return "En savoir plus";
  }
  return null;
}

function contentModalDPE({ energyLabelBeforeWork, knowEtiquetteDPE, buildingOccupationType, buildingUsage }) {
  const energyLow = ["E", "F", "G"];
  if (energyLabelBeforeWork === "E" && buildingOccupationType === "OWNER_LESSOR") {
    return (
      <>
        <b>2034</b><br /> Il est pour le moment prévu que tous les logements classés E, F ou G ne puissent plus faire l'objet de nouveaux contrats de location.
      </>
    );
  }
  if (energyLabelBeforeWork === "F" && buildingOccupationType === "OWNER_LESSOR") {
    return (
      <>
        Dès le <b>25 août 2022</b><br />
        Il sera interdit d'augmenter le loyer en cours de bail ou lors d'un changement de locataire et ce pour toutes les zones géographiques du territoire.<br /><br />
        Dès le <b>1er janvier 2028</b><br />
        <b>L'ensemble des logements classés F ou G ne pourront plus faire l'objet de nouveaux contrats de location</b> (dont la consommation d'énergie est supérieure à 330 kWh/m2.an ou dont les émissions de gaz à effet de serre dépassent 70 Kg/m2.an)
      </>
    );
  }
  if (energyLabelBeforeWork === "G" && buildingOccupationType === "OWNER_LESSOR") {
    return (
      <>
        Dès le <b>25 août 2022</b><br />
        Il sera interdit d'augmenter le loyer en cours de bail ou lors d'un changement de locataire et ce pour toutes les zones géographiques du territoire.
        <br /><br />
        Dès le <b>1er janvier 2023</b>
        <br />Un logement sera qualifié d'énergétiquement indécent lorsque sa consommation d'énergie dépassera 450kWh/m2.an (les logements les moins performants parmi les étiquettes G).
        <b> Les logements concernés ne pourront alors plus faire l'objet de nouveaux contrats de location.</b>
        <br /><br />
        Dès le <b>1er janvier 2025</b><br />
        Il est prévu d'étendre la mesure ci-dessus à l'ensemble des logements classés G (dont la consommation d'énergie est supérieure à 420 kWh/m2.an ou dont les émissions de gaz à effet de serre dépassent 100 Kg/m2.an).

      </>
    );
  }
  if (knowEtiquetteDPE === "0" && buildingOccupationType === "OWNER_LESSOR") {
    return (
      <>
        Dès le <b>25 août 2022</b><br />
        Pour l'ensemble des logements classés F ou G (dont la consommation d'énergie est supérieure à 330 kWh/m2.an ou dont les émissions de gaz à effet de serre dépassent 70 Kg/m2.an), il sera interdit
        d'augmenter le loyer en cours de bail ou lors d'un changement de locataire et ce pour toutes les zones géographiques du territoire.
        <br /><br />
        Dès le <b>1er janvier 2023</b> <br />
        Un logement sera qualifié d'énergétiquement indécent lorsque sa consommation d'énergie dépassera 450kWh/m2. an (les logements les moins performants parmi les étiquettes G). Les logements concernés ne pourront alors plus faire l'objet de nouveaux contrats de location.
      </>
    );
  }
  if (
    (energyLabelBeforeWork === "F" || energyLabelBeforeWork === "G" || knowEtiquetteDPE === "0")
    && (buildingOccupationType === "TENANT" && buildingUsage === "MAIN_RESIDENCE")
  ) {
    return (
      <>
        Dès le <b>25 août 2022</b><br />
        Pour l'ensemble des logements classés F ou G (dont la consommation d'énergie est supérieure à 330 kWh/m2.an ou dont les émissions de gaz à effet de serre dépassent 70 Kg/m2.an), il sera interdit
        d'augmenter le loyer en cours de bail ou lors d'un changement de locataire et ce pour toutes les zones géographiques du territoire.
        <br /><br />
        Dès le <b>1er janvier 2023</b> <br />
        Un logement sera qualifié d'énergétiquement indécent lorsque sa consommation d'énergie dépassera 450kWh/m2. an (les logements les moins performants parmi les étiquettes G). Les logements concernés ne pourront alors plus faire l'objet de nouveaux contrats de location.
      </>
    );
  }
  if (energyLow.includes(energyLabelBeforeWork) || knowEtiquetteDPE === "0") {
    return (
      <div className="leading-10">
        Le nouveau DPE, en vigueur depuis le 1er juillet 2021, est valable <b>10 ans</b>.<br />
        Dès le <b>1er septembre 2022</b> l'audit énergétique sera obligatoire pour les étiquettes F et G. <br />
        Les anciens DPE réalisés avant 2018 ne seront plus valables après le <b>31 décembre 2022</b>.
        Les anciens DPE réalisés entre 2018 et le 30 juin 2021 ne seront plus valables après le <b>31 décembre 2024</b>. <br />
        A partir de <b>2028</b>, les logements de type passoires thermiques seront interdits à la location (étiquettes F ou G).
        <br />
        <span css={`color: #e95c0c`}>
          <span className="underline">Attention </span>:
          un DPE est moins précis et moins complet qu'un audit énergétique et ne pourra donc ni servir à l'obtention des aides nationales à la rénovation globale ni à celle des bonifications MaPrimeRénov'.
        </span>
      </div>
    );
  }
  return null;
}

function Step6(props) {
  const option = props.match.params.option;
  const { history } = props;

  // show / hide additional loan block
  const [additionalLoanBlock, setAdditionalLoanBlock] = useState(false);
  // enable the submit button
  const [enableSubmit, setEnableSubmit] = useState(false);
  // estimation datas
  const [estimation, setEstimation] = useState(null);
  // show / hide loader on the financial block
  const [financialLoading, setFinancialLoading] = useState(false);
  // show / hide form error modal
  const [shouldShowModal, setShouldShowModal] = useState(false);
  const [showClassiqueModal, setShowClassiqueModal] = useState(false);
  const [IsLoadingPDF, setIsLoadingPDF] = useState(false);
  const uuid = get(estimation, "uuid");
  const pdfToken = get(estimation, "pdfToken");
  const isLBP = get(estimation, "isLBP");
  const [personnalFinancialAmount, setPersonnalFinancialAmount] = useState(
    <Loader />
  );
  const workCostValue = get(estimation, "recap.rows.worksCost.value");
  const monthlyLoan = get(estimation, "financial.rows.monthlyLoan.value");
  const monthlySavings = get(estimation, "financial.rows.monthlySavings.value");

  const [valueEtiquetteDPE, setValueEtiquetteDPE] = useState("");
  const [textButtonDPE, setTextButtonDPE] = useState(null);
  const [textModalDPE, setTextModalDPE] = useState(null);
  const energyLabelBeforeWork = get(estimation, "dpe.EnergyLabelBeforeWork", "");
  const knowEtiquetteDPE = get(estimation, "dpe.EnergyLabelKnown", "");
  const buildingOccupationType = get(estimation, "dpe.buildingOccupationType", "");
  const buildingUsage = get(estimation, "dpe.buildingUsage", "");

  const eptzEligibility = get(
    estimation,
    "recap.rows.eptzAmount"
  );

  const eptzAmountConstraints = get(
    estimation,
    "recap.rows.eptzAmount.constraints"
  );
  const eptzMaxAmount = eptzAmountConstraints
    ? eptzAmountConstraints.max
    : workCostValue;

  const validationSchema = Yup.object().shape({
    eptzAmount: Yup.number()
      .min(0, "Le montant ne doit pas être inférieur à 0 €")
      .max(
        eptzMaxAmount,
        `Le montant ne doit pas être supérieur à ${eptzMaxAmount}€ (Plafond des EcoPTZ)`
      )
      .typeError("Le montant n'est pas au bon format"),
    eptzDuration: Yup.number()
      .integer("La durée doit être un entier")
      .min(3, "La durée ne doit pas être inférieure à 3 ans")
      .max(
        DEFAULT_EPTZ_DURATION,
        `La durée ne doit pas être supérieure à ${DEFAULT_EPTZ_DURATION} ans`
      ),
    additionalLoanAmount: Yup.number()
      .min(0, "Le montant ne doit pas être inférieur à 0 €")
      .max(
        workCostValue,
        `Le montant ne doit pas être supérieur au montant des travaux`
      )
      .typeError("Le montant n'est pas au bon format"),
    additionalLoanDuration: Yup.number()
      .integer("La durée doit être un entier")
      .min(3, "La durée ne doit pas être inférieure à 3 ans")
      .max(
        DEFAULT_LOAN_DURATION,
        `La durée ne doit pas être supérieure à ${DEFAULT_LOAN_DURATION} ans`
      ),
    additionalLoanRate: Yup.number()
      .min(0, "Le taux ne doit pas être inférieur à 0 %")
      .max(100, "Le taux ne doit pas être supérieur à 100 %")
      .typeError("Le taux n'est pas au bon format"),
    classicLoanAmount: Yup.number()
      .min(0, "Le montant ne doit pas être inférieur à 0 €")
      .max(
        workCostValue,
        `Le montant ne doit pas être supérieur au montant des travaux`
      )
      .typeError("Le montant n'est pas au bon format"),
    classicLoanDuration: Yup.number()
      .integer("La durée doit être un entier")
      .min(3, "La durée ne doit pas être inférieure à 3 ans")
      .max(
        DEFAULT_LOAN_DURATION,
        `La durée ne doit pas être supérieure à ${DEFAULT_LOAN_DURATION} ans`
      ),
    classicLoanRate: Yup.number()
      .min(1, "Le taux ne doit pas être inférieur à 1 %")
      .max(100, "Le taux ne doit pas être supérieur à 100 %")
      .typeError("Le taux n'est pas au bon format"),
  });

  const handleRecapForm = async (values) => {
    try {
      setFinancialLoading(true);
      setEnableSubmit(false);
      setPersonnalFinancialAmount(
        workCostValue -
        values.eptzAmount -
        values.additionalLoanAmount -
        values.classicLoanAmount
      );
      const { token: csrfToken } = await getCsrfToken("saving-estimate");
      await savingEstimate(option, {
        method: "post",
        data: { ...values, "_token": csrfToken },
      }).then((res) => {
        if (res.data) {
          setEstimation(res.data);
        }
        setFinancialLoading(false);
      });
    } catch (error) {
      console.error(error);
      setFinancialLoading(false);
    }
  };

  const handleAdditionalLoanButton = (showBlock, values) => {
    setAdditionalLoanBlock(showBlock);
    if (!showBlock) {
      values.eptzAmount = eptzMaxAmount;
      values.eptzDuration = DEFAULT_EPTZ_DURATION;
      values.additionalLoanAmount = 0;
      values.additionalLoanDuration = DEFAULT_LOAN_DURATION;
      values.additionalLoanRate = DEFAULT_LOAN_RATE;
    } else {
      values.additionalLoanAmount = workCostValue - values.eptzAmount;
    }
    // results with new values
    handleAdditionalLoan(values);
  };

  const handleAdditionalLoan = async (values) => {
    try {
      setFinancialLoading(true);
      setEnableSubmit(false);
      await addAdditionalLoan(option, {
        method: "post",
        data: values,
      }).then((res) => {
        handleRecapForm(values);
      });
    } catch (error) {
      console.error(error);
      setFinancialLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { token: csrfToken } = await getCsrfToken("saving-estimate");
        const { data } = await savingEstimate(option, {
          data: { "_token": csrfToken },
        });
        setEstimation(data);
        setPersonnalFinancialAmount(
          data.recap.rows.worksCost.value - data.recap.rows.eptzAmount.value
        );
        setAdditionalLoanBlock(
          get(data, "recap.rows.additionalLoanAmount.value") !== 0
        );
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [option]);

  useEffect(() => {
    const textButtonDpe = buttonDPE({ energyLabelBeforeWork, knowEtiquetteDPE, buildingOccupationType, buildingUsage })
    const textModalDPE = contentModalDPE({ energyLabelBeforeWork, knowEtiquetteDPE, buildingOccupationType, buildingUsage })
    const valueEtiquetteDPE = etiquetteDPE({ energyLabelBeforeWork, knowEtiquetteDPE, buildingOccupationType })
    setTextButtonDPE(textButtonDpe);
    setTextModalDPE(textModalDPE);
    setValueEtiquetteDPE(valueEtiquetteDPE);
  }, [energyLabelBeforeWork, knowEtiquetteDPE, buildingOccupationType, buildingUsage])

  return !estimation ? (
    <div
      css={`
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 90vh;
      `}
    >
      <Loader />
    </div>
  ) : (
    <div>
      <div>
        <Formik
          initialValues={{
            eptzAmount: get(estimation, "recap.rows.eptzAmount.value") || 0,
            eptzDuration:
              get(estimation, "recap.rows.eptzAmount.duration.value") ||
              DEFAULT_EPTZ_DURATION,
            additionalLoanAmount:
              get(estimation, "recap.rows.additionalLoanAmount.value") || 0,
            additionalLoanDuration:
              get(
                estimation,
                "recap.rows.additionalLoanAmount.duration.value"
              ) || DEFAULT_LOAN_DURATION,
            additionalLoanRate:
              get(estimation, "recap.rows.additionalLoanAmount.rate.value") ||
              DEFAULT_LOAN_RATE,
            classicLoanAmount:
              get(estimation, "recap.rows.classicLoanAmount.value") || 0,
            classicLoanDuration:
              get(estimation, "recap.rows.classicLoanAmount.duration.value") ||
              DEFAULT_LOAN_DURATION,
            classicLoanRate:
              get(estimation, "recap.rows.classicLoanAmount.rate.value") ||
              DEFAULT_LOAN_RATE,
          }}
          onSubmit={(values, actions) => {
            let eptzAmount = values.eptzAmount;
            let additionalLoanAmount = values.additionalLoanAmount;
            if (workCostValue < eptzAmount + additionalLoanAmount) {
              setShouldShowModal(true);
              actions.setSubmitting(false);
            } else {
              handleRecapForm(values);
            }
          }}
          validationSchema={validationSchema}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <Block
                title={eptzEligibility ? "Financement du projet" : "Coûts de votre projet"}
                gradient="linear-gradient(135deg, #FBA264 0%, #E36A67 100%)"
              >
                <Row alignItems={"center"}>
                  {get(estimation, "recap.rows.worksCost") ? (
                    <>
                      <Col col={4}>
                        <Cell>
                          <CellTitle>
                            {get(estimation, "recap.rows.worksCost.label")}
                            {get(
                              estimation,
                              "recap.rows.worksCost.tooltip"
                            ) && (
                                <Tooltip
                                  tooltip={get(
                                    estimation,
                                    "recap.rows.worksCost.tooltip"
                                  )}
                                />
                              )}
                          </CellTitle>
                        </Cell>
                      </Col>
                      <Col col={4}>
                        <Cell>
                          <Total>
                            {get(
                              estimation,
                              "recap.rows.worksCost.formattedValue"
                            )}
                          </Total>
                        </Cell>
                      </Col>
                    </>
                  ) : null}
                </Row>

                {eptzEligibility ? (
                  <Row>
                  <Col>
                    {get(estimation, "recap.rows.eptzAmount") ? (
                      <>
                        <Col>
                          {" "}
                          <Row alignItems={"center"}>
                            <Col col={4}>
                              <Cell>
                                <CellTitle>
                                  <div>
                                    {get(
                                      estimation,
                                      "recap.rows.eptzAmount.label"
                                    )}
                                    {get(
                                      estimation,
                                      "recap.rows.eptzAmount.tooltip"
                                    ) && (
                                        <Tooltip
                                          tooltip={get(
                                            estimation,
                                            "recap.rows.eptzAmount.tooltip"
                                          )}
                                        />
                                      )}
                                  </div>
                                  <Button
                                    type="button"
                                    onClick={() => {
                                      handleAdditionalLoanButton(
                                        !additionalLoanBlock,
                                        values
                                      );
                                    }}
                                  >
                                    <ReactSVG src={plusIcon} />
                                    {additionalLoanBlock
                                      ? "Supprimer le prêt complémentaire"
                                      : "Ajouter un prêt complémentaire"}
                                  </Button>
                                </CellTitle>
                              </Cell>
                            </Col>
                            <Col col={4}>
                              <Cell>
                                <Total>
                                  <Input
                                    component="input"
                                    name="eptzAmount"
                                    type="number"
                                    suffix="€"
                                    customIcon={true}
                                    css={`
                                      margin-top: 0 !important;
                                    `}
                                    handleChange={() => setEnableSubmit(true)}
                                  />
                                </Total>
                              </Cell>
                            </Col>
                            {values.eptzAmount > 0 ? (
                              <Col col={4}>
                              <Cell>
                                <Row>
                                  <Col col="6">
                                    <strong>Durée du prêt :</strong>
                                    <Input
                                      component="input"
                                      name="eptzDuration"
                                      type="number"
                                      suffix="ans"
                                      customIcon={true}
                                      css={`
                                        margin-top: 3px;
                                        min-width: 155px;
                                      `}
                                      handleChange={() => setEnableSubmit(true)}
                                    />
                                  </Col>
                                </Row>
                              </Cell>
                            </Col>
                            ) : null}

                          </Row>
                        </Col>
                      </>
                    ) : null}
                    {additionalLoanBlock &&
                      get(estimation, "recap.rows.additionalLoanAmount") ? (
                      <>
                        <Col>
                          {" "}
                          <Row alignItems={"center"}>
                            <Col col={4}>
                              <Cell>
                                <CellTitle>
                                  <div>
                                    {get(
                                      estimation,
                                      "recap.rows.additionalLoanAmount.label"
                                    )}
                                    {get(
                                      estimation,
                                      "recap.rows.additionalLoanAmount.tooltip"
                                    ) && (
                                        <Tooltip
                                          tooltip={get(
                                            estimation,
                                            "recap.rows.additionalLoanAmount.tooltip"
                                          )}
                                        />
                                      )}
                                  </div>
                                </CellTitle>
                              </Cell>
                            </Col>
                            <Col col={4}>
                              <Cell>
                                <Total>
                                  <Input
                                    component="input"
                                    name="additionalLoanAmount"
                                    type="number"
                                    suffix="€"
                                    customIcon={true}
                                    css={`
                                      margin-top: 3px;
                                    `}
                                    handleChange={() => setEnableSubmit(true)}
                                  />
                                </Total>
                              </Cell>
                            </Col>
                            <Col col={4}>
                              <Cell>
                                <Row>
                                  <Col col="6">
                                    <strong>Durée du prêt :</strong>
                                    <Input
                                      component="input"
                                      name="additionalLoanDuration"
                                      type="number"
                                      suffix="ans"
                                      customIcon={true}
                                      css={`
                                        margin-top: 3px;
                                      `}
                                      handleChange={() => setEnableSubmit(true)}
                                    />
                                  </Col>
                                  <Col col="6">
                                    <strong>Taux d'intéret :</strong>
                                    <Input
                                      component="input"
                                      name="additionalLoanRate"
                                      type="string"
                                      suffix="%"
                                      customIcon={true}
                                      css={`
                                        margin-top: 3px;
                                      `}
                                      handleChange={() => setEnableSubmit(true)}
                                    />
                                  </Col>
                                </Row>
                              </Cell>
                            </Col>
                          </Row>
                        </Col>
                      </>
                    ) : null}
                    {get(estimation, "recap.rows.classicLoanAmount") ? (
                      <>
                        <Col>
                          {" "}
                          <Row alignItems={"center"}>
                            <Col col={4}>
                              <Cell>
                                <CellTitle>
                                  <div>
                                    {get(
                                      estimation,
                                      "recap.rows.classicLoanAmount.label"
                                    )}
                                    {get(
                                      estimation,
                                      "recap.rows.classicLoanAmount.tooltip"
                                    ) && (
                                        <Tooltip
                                          tooltip={get(
                                            estimation,
                                            "recap.rows.classicLoanAmount.tooltip"
                                          )}
                                        />
                                      )}
                                  </div>
                                </CellTitle>
                              </Cell>
                            </Col>
                            <Col col={4}>
                              <Cell>
                                <Total>
                                  <Input
                                    component="input"
                                    name="classicLoanAmount"
                                    type="number"
                                    suffix="€"
                                    css={`
                                      margin-top: 3px;
                                    `}
                                    customIcon
                                    handleChange={() => setEnableSubmit(true)}
                                  />
                                </Total>
                              </Cell>
                            </Col>
                            <Col col={4}>
                              <Cell>
                                <Row>
                                  <Col col="6">
                                    <strong>Durée du prêt :</strong>
                                    <Input
                                      component="input"
                                      name="classicLoanDuration"
                                      type="number"
                                      suffix="ans"
                                      css={`
                                        margin-top: 3px;
                                      `}
                                      customIcon
                                      handleChange={() => setEnableSubmit(true)}
                                    />
                                  </Col>
                                  <Col col="6">
                                    <strong>Taux d'intéret :</strong>
                                    <Input
                                      component="input"
                                      name="classicLoanRate"
                                      type="string"
                                      suffix="%"
                                      customIcon
                                      css={`
                                        margin-top: 3px;
                                      `}
                                      handleChange={() => setEnableSubmit(true)}
                                    />
                                  </Col>
                                </Row>
                              </Cell>
                            </Col>
                          </Row>
                        </Col>
                      </>
                    ) : null}
                  </Col>
                </Row>
                ) : null}

                {personnalFinancialAmount !== null && eptzEligibility ? (
                  <Row alignItems={"center"}>
                    <>
                      <Col col={4}>
                        <Cell>
                          <CellTitle>
                            Reste à financer
                            {get(estimation, "remainCostTooltip") && (
                              <Tooltip
                                tooltip={get(estimation, "remainCostTooltip")}
                              />
                            )}
                          </CellTitle>
                        </Cell>
                      </Col>
                      <Col col={4}>
                        <Cell>
                          <Total>
                            {workCostValue -
                              values.eptzAmount -
                              values.additionalLoanAmount -
                              values.classicLoanAmount >
                              0
                              ? formatAmountPrice(
                                workCostValue -
                                values.eptzAmount -
                                values.additionalLoanAmount -
                                values.classicLoanAmount
                              )
                              : formatAmountPrice(0)}
                          </Total>
                        </Cell>
                      </Col>
                    </>
                  </Row>
                ) : null}

                {eptzEligibility ? (
                  <Row textAlign={"center"}>
                  <Col>
                    <Cell>
                      <b
                        style={{ textAlign: "center" }}
                        className="noticeFinance"
                      >
                        Attention : le plan de financement ne doit pas intégrer
                        les aides car elles ne seront versées à votre client
                        qu'après la fin des travaux.
                        <br />
                        En revanche, nous avons fait l'hypothèse que ces aides
                        viennent rembourser par anticipation le ou les crédits
                        (Cf ci-après).
                      </b>
                      {!enableSubmit || financialLoading ? (
                        <DisabledButton
                          type="submit"
                          disabled={true}
                          css={`
                            margin: 0 auto;
                          `}
                        >
                          <ReactSVG src={checkIcon} /> Calculer les mensualités
                        </DisabledButton>
                      ) : (
                        <Button
                          type="submit"
                          css={`
                            margin: 0 auto;
                          `}
                        >
                          <ReactSVG src={checkIcon} /> Calculer les mensualités
                        </Button>
                      )}
                    </Cell>
                  </Col>
                </Row>
                ) : null}
              </Block>
            </form>
          )}
        />
        <Block
          title={eptzEligibility ? "Flux financiers mensuels" : "Estimation des économies d'énergie de votre projet"}
          gradient="linear-gradient(-48deg, #00A1AD 0%, #00CFCD 100%)"
        >
          {!financialLoading ? (
            <>
            {eptzEligibility ? (
              <>
              <Row alignItems={"center"}>
                {get(estimation, "financial.rows.monthlyLoan") ? (
                  <>
                    <Col col={4}>
                      <Cell>
                        <CellTitle>
                          {get(estimation, "financial.rows.monthlyLoan.label")}
                        </CellTitle>
                      </Cell>
                    </Col>
                    <Col col={4}>
                      <Cell>
                        <Total>
                          {formatAmountPerMonth(
                            get(
                              estimation,
                              "financial.baseMonthlyEptzAndLoan.formattedValue"
                            )
                          )}
                        </Total>
                      </Cell>
                    </Col>
                    <Col col={4}>
                      <Cell>
                        La dépense mensuelle pour le remboursement du crédit sans déduction des aides après 12 mois.
                      </Cell>
                    </Col>
                  </>
                ) : null}
              </Row>
              <Row alignItems={"center"}>
                {get(estimation, "financial.rows.monthlyLoan") ? (
                  <>
                    <Col col={4}>
                      <Cell>
                        <CellTitle>
                          {get(estimation, "financial.rows.monthlyLoan.label")}
                          {get(
                            estimation,
                            "financial.rows.monthlyLoan.tooltip"
                          ) && (
                              <Tooltip
                                tooltip={get(
                                  estimation,
                                  "financial.rows.monthlyLoan.tooltip"
                                )}
                              />
                            )}
                        </CellTitle>
                      </Cell>
                    </Col>
                    <Col col={4}>
                      <Cell>
                        <Total>
                          {formatAmountPerMonth(
                            get(
                              estimation,
                              "financial.rows.monthlyLoan.formattedValue"
                            )
                          )}
                        </Total>
                      </Cell>
                    </Col>
                    <Col col={4}>
                      <Cell>
                        Echéances incluant le remboursement par les{" "}
                        <b className="green">
                          {get(
                            estimation,
                            "recap.rows.financialAidsTotal.formattedValue"
                          )}
                        </b>{" "}
                        d'aide au bout de 12 mois d'échéances à{" "}
                        <b>
                          {get(
                            estimation,
                            "financial.baseMonthlyEptzAndLoan.formattedValue"
                          )}
                          /mois
                        </b>
                        .
                      </Cell>
                    </Col>
                  </>
                ) : null}
              </Row>
              </>
            ) : null}
              <Row alignItems={"center"}>
                {get(estimation, "financial.rows.monthlySavings") ? (
                  <>
                    <Col col={4}>
                      <Cell>
                        <CellTitle>
                          {get(
                            estimation,
                            "financial.rows.monthlySavings.label"
                          )}
                          {get(


                            estimation,
                            "financial.rows.monthlySavings.tooltip"
                          ) && (
                              <Tooltip
                                tooltip={get(
                                  estimation,
                                  "financial.rows.monthlySavings.tooltip"
                                )}
                              />
                            )}
                        </CellTitle>
                      </Cell>
                    </Col>
                    <Col col={4}>
                      <Cell>
                        <Total>
                          {formatAmountPerMonth(
                            get(
                              estimation,
                              "financial.rows.monthlySavings.formattedValue"
                            )
                          )}
                        </Total>
                      </Cell>
                    </Col>
                    <Col col={4}>
                      <Cell>
                        Economies d’énergie se poursuivant bien après le crédit,
                        tout au long de la durée de vie énergétique des
                        installations -{" "}
                        <b>entre 17 & 30 ans selon les travaux.</b>
                      </Cell>
                    </Col>
                  </>
                ) : null}
              </Row>
              <Row alignItems={"center"}>
                {get(estimation, "financial.rows.monthlyGain") ? (
                  <>
                    <Col col={4}>
                      <Cell>
                        <CellTitle>
                          {get(estimation, "financial.rows.monthlyGain.label")}
                          {get(estimation,
                            "financial.rows.monthlyGain.tooltip"
                          ) && (
                              <Tooltip
                                tooltip={get(
                                  estimation,
                                  "financial.rows.monthlyGain.tooltip"
                                )}
                              />
                            )}
                        </CellTitle>
                      </Cell>
                    </Col>
                    <Col col={4}>
                      <Cell>
                        <Total>
                          {formatAmountPerMonth(
                            get(
                              estimation,
                              "financial.rows.monthlyGain.formattedValue"
                            )
                          )}
                        </Total>
                      </Cell>
                    </Col>
                  </>
                ) : null}
              </Row>
            </>
          ) : (
            <Loader />
          )}

        </Block>
        <Block
          title={
            monthlyLoan > monthlySavings
              ? "Grâce à ces travaux, vous améliorez ..."
              : "Et en plus, vous améliorez ..."
          }
          gradient="linear-gradient(135deg, #49cb8a 0%, #3ac27e 100%)"
          css={`
            margin-bottom: 25px;
          `}
        >
          <Row>
            <Col col="3">
              <Reassurance>
                <ReactSVG src={diagnosticIcon}
                  css={`
                            svg g {
                              fill: #3AC27E;
                              }
                              `} />
                <p>
                  L'étiquette <span css={`color: #e36a67; font-weight: bold`}>{valueEtiquetteDPE}</span> de votre DPE
                </p>
                {textButtonDPE !== null ? (
                  <div className="flex-center">
                    <ReactSVG src={warningIcon} css={`margin-right:8px`} />
                    <Button
                      type="button"
                      onClick={() => {
                        setShowClassiqueModal(true);
                      }}
                      css={`
                              text-transform:none; 
                              border-radius:5px
                            `}
                    >
                      {textButtonDPE}
                    </Button>
                  </div>
                ) : null}
              </Reassurance>
            </Col>
            <Col col="3">
              <Reassurance>
                <ReactSVG src={reventeIcon} />
                La valeur de votre bien à la revente
                <a
                  css={`
                    color: #3b3f42;
                  `}
                  href="https://fr.calameo.com/read/00512519861d0630600a5?page=1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  > En savoir plus
                </a>
              </Reassurance>
            </Col>
            <Col col="3">
              <Reassurance>
                <ReactSVG src={impactIcon} />
                Votre impact <br /> environnemental
              </Reassurance>
            </Col>
            <Col col="3">
              <Reassurance>
                <ReactSVG src={confortIcon} />
                Votre confort
              </Reassurance>
            </Col>
          </Row>
        </Block>
      </div>
      <Buttons>
        <LightButton
          type="button"
          className="backButton"
          onClick={() => history.push("/simulator/5")}
        >
          Etape précédente
        </LightButton>
        <BigLink
          css={`
            margin-bottom: 0;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 14px;
          `}
          to={"#"}
          onClick={async () => {
            setIsLoadingPDF(true);
            try {
              const pdf = await getPDFSimulation(pdfToken, uuid);
              if (pdf) {
                let url = window.URL.createObjectURL(pdf);
                let a = document.createElement("a");
                a.href = url;
                a.download = isLBP
                  ? "etude mon projet energetique.pdf"
                  : "simulation.pdf";
                // Comment to disable download PDF
                a.click();
                setIsLoadingPDF(false);
              }
            } catch (e) {
              setIsLoadingPDF(false);
            }
          }}
        >
          {IsLoadingPDF ? (
            <LoaderBase
              type="ThreeDots"
              color="#ffffff"
              height="16"
              width="100"
            />
          ) : (
            <div className="flex flex-col items-center">
              <ReactSVG src={printIcon} />
              <span>Imprimer la simulation et les démarches</span>
            </div>
          )}
        </BigLink>
      </Buttons>

      <Modal open={shouldShowModal}>
        <div
          css={`
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 30px;
            max-width: 400px;
            text-align: center;
          `}
        >
          La somme de l'éco Prêt à taux zéro et du prêt complémentaire ne peut
          pas être supérieure au montant des travaux.
        </div>

        <div
          css={`
            margin: 0 auto;
            max-width: 150px;
          `}
        >
          <LightButton onClick={() => setShouldShowModal(false)}>
            Fermer
          </LightButton>
        </div>
      </Modal>

      <ModalDpe
        open={showClassiqueModal}
        setOpen={setShowClassiqueModal}
        dpe={estimation?.dpe}
        titleDpe={textButtonDPE}
        content={textModalDPE}
      />
    </div>
  );
}

function ModalDpe({ open, setOpen, dpe, titleDpe, content }) {
  return (
    <ClassicModal
      open={open}
      title={<div className="font-bold text-center">{titleDpe}</div>}
      bgColor="bg-white"
      closeModal={() => {
        setOpen(false);
      }}
    >
      <div className="pt-2 pb-6 leading-8 text-left text-gray-900">
        {content}
      </div>
      <div className="flex justify-center">
        <button
          className={`orange-gradient p-2 rounded-md min-w-10`}
          onClick={() => {
            setOpen(false);
          }}
        >
          OK
        </button>
      </div>
    </ClassicModal>
  )
}
export default Step6;
