import React from "react";
import styled from "styled-components/macro";
import LoginPage from "../layouts/LoginPage";
import { SimulatorButton } from "../components/Button";

const Button = styled(SimulatorButton)`
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  display: block;
`;

function PasswordOk(props) {
  return (
    <LoginPage>
      <p
        css={`
          font-size: 18px;
          line-height: 22px;
        `}
      >
        Vérifiez votre boîte de réception. <br />
        Nous venons de vous envoyer un email, si celui-ci est valide, contenant un lien pour
        réinitialiser votre mot de passe. <br />
        Si vous ne recevez rien, vérifiez d'abord votre dossier de courrier
        indésirable ainsi que votre adresse mail, puis réessayez.
      </p>
      <Button
        onClick={() => {
          props.history.push("/login");
        }}
      >
        Retour à la page de connexion
      </Button>
    </LoginPage>
  );
}

export default PasswordOk;
