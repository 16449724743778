import React from "react";
import styled from "styled-components/macro";

const Title = styled.strong`
  font-family: "ProximaNova", sans-serif;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 10px;
  display: block;
`;

function simpleTitle({ children }) {
  return <Title>{children}</Title>;
}

export default simpleTitle;
