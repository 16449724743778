import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import ReactSVG from "react-svg";
import { Link } from "react-router-dom";
import { Row, Col } from "styled-bootstrap-grid";
import { map, get } from "lodash";

import BigLink from "../components/BigLink";
import Loader from "../components/Loader";
import Page from "../layouts/Page";
import Block from "../components/Block";
import Title from "../components/Title";
import euro from "../images/euro.png";
import bookIcon from "../svgs/book.svg";
import faqIcon from "../svgs/foire.svg";
import pdfIcon from "../svgs/PDF-toolbox-home.svg";

import Routes from "../routes";

import { getToolbox } from "../api";

const Subtitle = styled.div`
  font-size: 30px;
  line-height: 37px;
  font-weight: bold;
  letter-spacing: -0.02px;
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const ExtraLinks = styled.div`
  display: flex;
  margin-top: 45px;
  justify-content: center;

  ${BigLink} {
    width: 50%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 30px;

    > span {
      margin-top: 0;
      margin-left: 30px;
    }
    + ${BigLink} {
      margin-left: 25px;
    }

    svg {
      width: 70px;
      height: 70px;
    }
  }
`;

const HelpBlock = styled.div`
  background: #f6f6f6;
  border-radius: 10px;
  padding: 40px 16px 16px;
  margin-bottom: 20px;
  position: relative;
  width: 100%;
  display: flex;

  &:hover {
    color: ${(props) => props.theme.color.primary};
    box-shadow: 1px 2px 20px 0 rgba(0, 0, 0, 0.2);
  }
`;

const HelpBlockMainLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const HelpBlockTitle = styled.div`
  font-size: 17px;
  line-height: 20px;
  margin-top: auto;
`;

const HelpBlockImage = styled.div`
  text-align: center;
  margin-bottom: 25px;
`;
const HelpBlockPdf = styled.a`
  position: absolute;
  top: 14px;
  right: 14px;

  svg {
    width: 25px;
    height: 25px;
  }
`;
const InlineBlockTitle = styled.div`
  font-size: 17px;
  line-height: 20px;
`;

const InlineBlock = styled.div`
  background: #f6f6f6;
  border-radius: 10px;
  padding: 17px 50px 17px 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  position: relative;
  &:hover {
    color: ${(props) => props.theme.color.primary};
    box-shadow: 1px 2px 20px 0 rgba(0, 0, 0, 0.2);
  }
  ${InlineBlockTitle} {
    margin-left: 30px;
  }
`;
const InlineBlockPdf = styled.a`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 16px;

  svg {
    width: 30px;
    height: 30px;
  }
`;
const InlineBlockMainLink = styled(Link)`
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
  flex: 1;
`;
const InlineBlockImage = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

function ToolboxHome() {
  const [data, setData] = useState(null);

  useEffect(() => {
    async function getData() {
      try {
        const { data } = await getToolbox();
        setData(data);
      } catch (error) {
        console.error(error);
      }
    }
    getData();
  }, []);
  return (
    <Page>
      <Title>Boite à outils</Title>
      <Block>
        {!data ? (
          <Loader />
        ) : data && data.help ? (
          <>
            <Subtitle
              css={`
                margin-top: 0;
              `}
            >
              Les aides
            </Subtitle>
            <Row
              css={`
                margin-bottom: 15px;
              `}
            >
              <Col
                xl="2"
                lg="4"
                md="6"
                css={`
                  align-items: stretch;
                  display: flex;
                `}
              >
                <HelpBlock>
                  <HelpBlockMainLink to={`/toolbox/tableaux-aides`}>
                    <HelpBlockImage>
                      <img src={euro} alt="euro" />
                    </HelpBlockImage>
                    <HelpBlockTitle>
                      Tableau des aides par type de travaux
                    </HelpBlockTitle>
                  </HelpBlockMainLink>
                </HelpBlock>
              </Col>
              {map(data.help, (elem, key) => {
                return (
                  <Col
                    xl="2"
                    lg="4"
                    md="6"
                    key={`col-${Object.keys(data.help)[key]}`}
                    css={`
                      align-items: stretch;
                      display: flex;
                    `}
                  >
                    <HelpBlock>
                      <HelpBlockMainLink to={`/toolbox/help/${elem.id}`}>
                        <HelpBlockImage>
                          <img src={elem.img.url} alt="" />
                        </HelpBlockImage>
                        <HelpBlockTitle>{elem.title}</HelpBlockTitle>
                      </HelpBlockMainLink>
                      {get(elem, "pdf.url") ? (
                        <HelpBlockPdf
                          href={get(elem, "pdf.url")}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <ReactSVG src={pdfIcon} />
                        </HelpBlockPdf>
                      ) : null}
                    </HelpBlock>
                  </Col>
                );
              })}
            </Row>
          </>
        ) : null}

        {
          <Row>
            <Col xl="12">
              {data && data.essential_renovation_energy ? (
                <>
                  <Subtitle>
                    Le diagnostique de performance énergetique (DPE)
                  </Subtitle>
                  <div className="grid grid-cols-2 gap-8">
                    {map(data.essential_renovation_energy, (elem, key) => {
                      return (
                        <InlineBlock key={key}>
                          <InlineBlockMainLink
                            to={`/toolbox/essential_renovation_energy/${elem.id}`}
                          >
                            <InlineBlockImage>
                              <img src={elem.img.url} alt="" />
                            </InlineBlockImage>
                            <InlineBlockTitle>{elem.title}</InlineBlockTitle>
                          </InlineBlockMainLink>
                          {get(elem, "pdf.url") ? (
                            <InlineBlockPdf
                              href={get(elem, "pdf.url")}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <ReactSVG src={pdfIcon} />
                            </InlineBlockPdf>
                          ) : null}
                        </InlineBlock>
                      );
                    })}
                  </div>
                </>
              ) : null}
            </Col>
          </Row>
        }

        <ExtraLinks>
          <BigLink as={Link} to={Routes().glossary.path}>
            <ReactSVG src={bookIcon} className="bookIcon" />
            <span>Glossaire technique</span>
          </BigLink>
          <BigLink as={Link} to={Routes().help.routes.FAQ.path}>
            <ReactSVG src={faqIcon} />
            <span>Foire aux questions</span>
          </BigLink>
        </ExtraLinks>
      </Block>
    </Page>
  );
}

export default ToolboxHome;
