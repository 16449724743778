import styled from "styled-components/macro";

const Title = styled.div`
  font-family: "ProximaNova";
  font-size: 34px;
  margin-bottom: 18px;
  padding-right: 8px;
  font-weight: 400;

  sup {
    vertical-align: super;
    font-size: smaller;
  }

  strong {
    font-weight: bold;
  }

  &::after {
    content: ${props => (props.underline ? '""' : "none")};
    height: 1px;
    background: ${props => props.theme.mainGradient};
    display: block;
    margin-top: 5px;
    width: calc(100% + 8px);
  }
`;

export const UnderlineTitle = styled(Title)`
  font-size: 20px;
  margin: auto;

  &:after {
    content: "";
    height: 1px;
    width: 100%;
    display: block;
    background: ${props => props.theme.mainGradient};
  }
`;

export default Title;
