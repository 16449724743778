import React from "react";
import { Switch, Route } from "react-router-dom";
import { map } from "lodash";

import Routes from "../routes";

function Toolbox() {
  return (
    <Switch>
      {map(Routes().toolbox.routes, (route, index) => {
        return (
          <Route
            exact={route.exact}
            key={index}
            path={route.path}
            component={route.component}
          />
        );
      })}
    </Switch>
  );
}

export default Toolbox;
