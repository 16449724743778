export const buildingOccupationTypeData = [
  {
    value: "principal-region",
    label:
      "Propriétaire occupant ou bailleur résidant hors Ile-de-France d'une résidence principale",
  },
  {
    value: "principal-idf",
    label:
      "Propriétaire occupant ou bailleur résidant en Ile-de-France d'une résidence principale",
  },
  {
    value: "secondaire-region",
    label:
      "Propriétaire résidant hors Ile-de-France d'une résidence secondaire",
  },
  {
    value: "secondaire-idf",
    label: "Propriétaire résidant en Ile-de-France d'une résidence secondaire",
  },
  {
    value: "locataire-region",
    label: "Locataire résidant hors Ile-de-France",
  },
  {
    value: "locataire-idf",
    label: "Locataire résidant en Ile-de-France",
  },
];
