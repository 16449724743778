import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import ReactSVG from "react-svg";
import AsyncSelect from "react-select/async";
import { map, get, size, slice } from "lodash";

import ContentDate from "./ContentDate";
import Paging from "./Paging";

import radioIcon from "../svgs/radio.svg";

import { getAllNews, getNewsCategories } from "../api";
import { formatDate } from "../helpers";
import NewsLabel from "./NewsLabel";

const Wrapper = styled.div`
  background: #f7f7f8;
  border-radius: 10px;
  padding: 20px;

  ${Paging} {
    text-align: right;
  }
`;

const Intro = styled.div`
  display: flex;
  margin-bottom: 20px;

  .radioIcon svg {
    width: 35px;
    height: auto;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-left: 20px;
`;

const List = styled.div`
  margin-top: 30px;

  ${ContentDate} {
    margin-bottom: 6px;
  }
`;

const Item = styled(Link)`
  margin-right: -10px;
  margin-left: -10px;
  display: block;
  text-decoration: none;
  color: #000;
  padding: 15px;
  background: rgba(255, 255, 255, 0);
  transition: background 125ms ease;
  &:hover {
    background: rgba(255, 255, 255, 0.75);
    border-radius: 10px;
  }
`;
const ItemTitle = styled.div`
  font-size: 16px;
`;

function NewsThread() {
  const [data, setData] = useState(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    async function getData() {
      try {
        setData(null);
        const { data } = await getAllNews(40);
        setData(data);
      } catch (error) {
        console.error(error);
      }
    }
    getData();
  }, []);

  return (
    <Wrapper>
      <Intro>
        <ReactSVG src={radioIcon} className="radioIcon"
        css={`
          svg {
            max-height: 40px;
          }
        `}/>
        <Title>Fil d’actualités</Title>
      </Intro>
      <AsyncSelect
        cacheOptions
        defaultOptions
        placeholder="Choisir la thématique"
        loadOptions={async () => {
          const { data } = await getNewsCategories();
          return [
            { label: "Choisir la thématique", value: "" },
            ...map(data, cat => ({ value: cat.id, label: cat.title }))
          ];
        }}
        onChange={async selected => {
          const { data } = await getAllNews(40, 0, [selected.value]);
          setData(data);
          setPage(1);
        }}
      />
      <List>
        {map(slice(data, (page - 1) * 4, (page - 1) * 4 + 4), item => (
          <Item key={item.id} to={`news/${item.id}`}>
            <ContentDate>{formatDate(new Date(item.date))}</ContentDate>
            {item.categories
              ? map(item.categories, (el, index) => (
                  <NewsLabel key={index} color={el.color}>
                    {el.title}
                  </NewsLabel>
                ))
              : null}
            <ItemTitle>{get(item, "title.rendered")}</ItemTitle>
          </Item>
        ))}
      </List>

      {size(data) > 4 ? (
        <Paging
          current={page}
          total={Math.ceil(size(data) / 4)}
          onNext={() => {
            if (page === Math.ceil(size(data) / 4)) {
              // return to first page if at the end
              setPage(1);
            } else {
              setPage(page < Math.ceil(size(data) / 4) ? page + 1 : page);
            }
          }}
          onPrev={() => {
            if (page === 1) {
              // go to last page if at the start
              setPage(Math.ceil(size(data) / 4));
            } else {
              setPage(page > 1 ? page - 1 : page);
            }
          }}
        />
      ) : null}
    </Wrapper>
  );
}

export default NewsThread;
