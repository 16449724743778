import Select from "./Select";
import React, { useEffect, useState } from "react";
import { getPrecarityAmounts } from "../../api";
import { formatAmountPrice } from "../../utils/formatAmount";

function getRegion(buildingOccupationType) {
  if (!buildingOccupationType) return null;
  const regex = /.+-(.+)$/;
  let m;
  if ((m = regex.exec(buildingOccupationType)) !== null && m[1]) {
    return m[1];
  }
  return null;
}

async function calculPrecarityAmount(buildingOccupationType, personsCount) {
  const region = getRegion(buildingOccupationType);

  if (personsCount > 0 && region) {
    const { amounts } = await getPrecarityAmounts(region, personsCount);
    if (
      amounts?.intermediateHouse &&
      amounts?.modestHouse &&
      amounts?.veryModestHouse
    ) {
      return [
        {
          value: "very-low",
          label: `Inférieur à ${formatAmountPrice(amounts.veryModestHouse)}`,
        },
        {
          value: "low",
          label: `De ${formatAmountPrice(
            amounts.veryModestHouse
          )} à ${formatAmountPrice(amounts.modestHouse)}`,
        },
        {
          value: "intermediate",
          label: `De ${formatAmountPrice(
            amounts.modestHouse
          )} à ${formatAmountPrice(amounts.intermediateHouse)}`,
        },
        {
          value: "hight",
          label: `Supérieur à ${formatAmountPrice(amounts.intermediateHouse)}`,
        },
      ];
    }
  }
  return [];
}

export function SelectAmountPrecarity({
  buildingOccupationType,
  personsCount,
  ...props
}) {
  const [
    clientIncomeFiscalAmountData,
    setClientIncomeFiscalAmountData,
  ] = useState([]);
  useEffect(() => {
    calculPrecarityAmount(buildingOccupationType, personsCount).then(
      (response) => {
        setClientIncomeFiscalAmountData(response);
      }
    );
  }, [buildingOccupationType, personsCount]);
  return (
    <Select
      name="clientIncomeFiscalAmountYearly"
      title="Revenu fiscal déclaré sur l'avis d'imposition"
      placeholder={"Sélectionner un revenu"}
      choices={clientIncomeFiscalAmountData}
      {...props}
    />
  );
}
