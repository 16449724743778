import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import Loader from "react-loader-spinner";
import ReactSVG from "react-svg";
import {
  get,
  map,
  reduce,
  orderBy,
  takeRightWhile,
  includes,
  forEach,
  every,
  omit,
} from "lodash";
import { GetMatchingWidget } from "./helpers";
import Radio from "../inputs/Radio";
import SwitchGroup, {
  RadioGroupLabel,
  SwitchRadio,
  RadioGroupItems,
} from "./Switch";
import { SelectWrapper } from "./Select";
import { costEstimateValidation, getCsrfToken } from "../../api";

const NestedCostTitle = styled.div`
  font-size: 22px;
  line-height: 1;
  font-weight: 600;
  margin-bottom: 15px;
`;

const NestedCostIcon = styled.div`
  width: 120px;
  height: 120px;
  background: ${(props) => props.theme.mainGradient};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;

  svg {
    height: 60px;
    width: 60px;
    fill: #fff;
    stroke: #fff;
  }
`;

const NestedCostFields = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  display: flex;
  align-items: center;
  margin-top: 0;
  padding: 0 30px;

  ${SwitchGroup} {
    margin-top: 25px;
    label {
      max-width: 100%;
      width: 95px;
      padding: 16px 20px;
      background: white;
      border: 1px solid #e3e3e3;
    }
  }

  ${RadioGroupItems} {
    width: 206px;
    flex-wrap: nowrap;

    ${Radio} {
      max-width: 100%;
    }
  }
  ${RadioGroupLabel} {
    font-size: 17px;
    margin-right: 15px;
    width: calc(100% - 206px);
    text-align: left;
  }

  ${SwitchRadio} {
    margin-top: 0;
  }

  ${SelectWrapper} {
    > label {
      margin-right: 15px;
      width: calc(100% - 206px);
    }
  }

  > [class^="input-"],
  > [class^="select-"],
  > [class^="switch-"] {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    label {
      font-size: 17px;
      margin-bottom: 0;
    }
    label + * {
      width: 206px;
    }
  }
`;

const NestedCostWrapper = styled.div`
  border-radius: 20px;
  background: #f7f7f8;
  display: flex;
  align-items: flex-start;
  padding: 30px 60px;
  margin-bottom: 30px;
`;

const NestedCostValue = styled.div`
  border-radius: 10px;
  background: #fff;
  padding: 30px 50px;
  margin: auto;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 16px;
  text-align: center;
  width: 290px;
`;

const NestedCostValueLabel = styled.div``;
const NestedCostValueValue = styled.div`
  font-size: 30px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
`;

function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

function NestedCost({
  values,
  fields,
  title,
  name,
  icon,
  onUpdate,
  onError,
  errors,
  totalCostScenarioControl,
}) {
  const [loading, setLoading] = useState(false);
  const [cost, setCost] = useState(null);
  const [perCent, setPercent] = useState(null);
  const [perCentCost, setPercentCost] = useState(null);
  const [error, setError] = useState(null);
  const debouncedValues = useDebounce(values, 500);
  const [totalCostGestureControl, setTotalCostGestureControl] = useState(false);

  useEffect(() => {
    async function sendEstimate() {
      try {
        const { token: csrfToken } = await getCsrfToken(
          "one-work-cost-estimate"
        );

        onError(name, null);
        setError(false);
        setLoading(true);
        const { data } = await costEstimateValidation(name, {
          data: {
            ...debouncedValues,
            "_token": csrfToken,
          },
        });
        if (data) {
          debouncedValues.materialCost = data.materialCost;
          debouncedValues.workforceCost = data.workforceCost;
          setCost(data.workCost);
          setPercent(data.perCent);
          calculPercentCost(data.perCent, totalCostScenarioControl);
          if (onUpdate && typeof onUpdate === "function") {
            onUpdate(data);
          }

        }
        setLoading(false);
      } catch (error) {
        if (onError && typeof onError === "function") {
          forEach(
            get(error, `response.data.cost_estimate_step`),
            (error, key) => {
              forEach(error, (nestedError, nestedKey) => {
                onError(`${key}[${nestedKey}]`, nestedError.errors);
              });
            }
          );
        }
        setLoading(false);
        setError(true);
      }
    }

    async function costCalc() {
      try {
        const { token: csrfToken } = await getCsrfToken(
          "one-work-cost-estimate"
        );

        onError(name, null);
        setError(false);
        setLoading(true);
        const { data } = await costEstimateValidation(name, {
          data: {
            ...debouncedValues,
            workforceCost: '',
            materialCost: '',
            costKnown: "0",
            "_token": csrfToken,
          },
        });
        if (data) {
          const newMaterialCost = Math.round((debouncedValues.totalCostGesture * data.materialCost) / data.workCost);
          const newWorkforceCost = debouncedValues.totalCostGesture - newMaterialCost;

          debouncedValues.materialCost = newMaterialCost;
          debouncedValues.workforceCost = newWorkforceCost;

          setCost(newMaterialCost + newWorkforceCost);
          setPercent(data.perCent);
          calculPercentCost(data.perCent, totalCostScenarioControl);
          if (onUpdate && typeof onUpdate === "function") {
            onUpdate({ ...data, materialCost: newMaterialCost, workforceCost: newWorkforceCost, workCost: newMaterialCost + newWorkforceCost, totalCost: newMaterialCost + newWorkforceCost });
          }
        }
        setLoading(false);
      } catch (error) {
        if (onError && typeof onError === "function") {
          forEach(
            get(error, `response.data.cost_estimate_step`),
            (error, key) => {
              forEach(error, (nestedError, nestedKey) => {
                onError(`${key}[${nestedKey}]`, nestedError.errors);
              });
            }
          );
        }
        setLoading(false);
        setError(true);
      }
    }

    if (values.totalCostGesture) {
      fields.workforceCost.className = "pointer-events-none opacity-50"
      fields.materialCost.className = "pointer-events-none opacity-50"
      setTotalCostGestureControl(true);
    }
    if (totalCostGestureControl && !values.totalCostGesture) {
      fields.workforceCost.className = '';
      fields.materialCost.className = '';
      setTotalCostGestureControl(false);
    }

    if (every(omit(debouncedValues, ["totalCostGesture"]), (el) => {
      return el && el !== "0"
    }) && !values.totalCostGesture) {
      sendEstimate();
    } else if (
      debouncedValues.costKnown === "0" &&
      every(omit(debouncedValues, ["materialCost", "workforceCost", "totalCostGesture"]))
    ) {
      sendEstimate();
    } else if (
      debouncedValues.costKnown === "1" && (every(omit(debouncedValues, ["materialCost", "workforceCost"])) || every(debouncedValues, (el) => el && el !== "0"))
    ) {
      costCalc()
    }
  }, [debouncedValues, name, onError, onUpdate, errors, totalCostScenarioControl, fields.materialCost, fields.workforceCost, totalCostGestureControl, values.totalCostGesture]);

  const calculPercentCost = (perCent, totalCostScenarioControl) => {
    if (perCent && totalCostScenarioControl && totalCostScenarioControl !== '') {
      setPercentCost(perCent * totalCostScenarioControl / 100);
    } else {
      setPercentCost(null);
    }
  };

  useEffect(() => {
    calculPercentCost(perCent, totalCostScenarioControl);
  }, [totalCostScenarioControl, perCent]);

  const fieldsWithKey = map(fields, (field, key) => ({ ...field, key }));

  const afterRadio = reduce(
    takeRightWhile(
      orderBy(fieldsWithKey, ["propertyOrder"]),
      (item) => item.key !== "costKnown"
    ),
    (acc, next) => {
      return [...acc, next.key];
    },
    []
  );
  const costKnown = values.costKnown === "0" || values.costKnown === "";

  return (
    <>
      <NestedCostTitle>{title}</NestedCostTitle>
      <NestedCostWrapper>
        <NestedCostIcon>
          <ReactSVG src={icon} />
        </NestedCostIcon>
        <NestedCostFields className={totalCostScenarioControl && totalCostScenarioControl !== '' ? 'pointer-events-none opacity-50' : ''}>
          {map(fields, (field, fieldKey) => {
            if (costKnown && includes(afterRadio, fieldKey)) {
              return null;
            }

            return GetMatchingWidget({
              field,
              fieldKey: `${name}[${fieldKey}]`,
              values: values,
            });
          })}
        </NestedCostFields>

        <NestedCostValue>
          <NestedCostValueLabel>
            {costKnown ? "Coût estimé des travaux" : "Coût réel des travaux"}
          </NestedCostValueLabel>
          <NestedCostValueValue>
            {error ? (
              <span
                css={`
                  color: red;
                `}
              >
                erreur
              </span>
            ) : loading ? (
              <Loader type="ThreeDots" color="#2E384B" height="30" width="50" />
            ) : (perCentCost ? (
              `${perCentCost} €`
            ) : ((cost ? `${cost} €` : `...`)))}
          </NestedCostValueValue>
        </NestedCostValue>
      </NestedCostWrapper>
    </>
  );
}

export default NestedCost;
