import BlockStat, { NumberStat } from "./BlockStat";
import React from "react";
import houseIcon from "../../svgs/house.svg";
import { get } from "lodash";
import { formatDateNumber } from "../../helpers";
import { roundAndFormatNumber } from "../../utils/formatAmount";

const textColor = "text-orange-400";

export default function DirectionSimulatorBlock({ simulator }) {
  const {
    nbUserActive = 0,
    simulationCount = 0,
    nbSimuByUser = 0,
    userActivePercent = 0,
    ranking = [],
  } = simulator;

  const TextActiveUser = (
    <>
      {nbUserActive > 1 ? "comptes activés" : "compte activé"}
      <br />({userActivePercent} %)
    </>
  );
  return (
    <BlockStat title="Simulateur" textColor={textColor} icon={houseIcon}>
      <div className="grid lg:grid-cols-2 gap-4">
        <NumberStat
          textColor={textColor}
          text={TextActiveUser}
          number={nbUserActive}
        />
        <NumberStat
          textColor={textColor}
          text={
            simulationCount > 1
              ? "simulations effectuées"
              : "simulation effectuée"
          }
          number={simulationCount}
        />
      </div>
      <RankingBlock
        nbSimu={roundAndFormatNumber(nbSimuByUser, 1)}
        ranking={ranking}
      />
      <div className="text-orange-400 font-semibold">
        Activité dans votre banque au {formatDateNumber(new Date())}
      </div>
    </BlockStat>
  );
}

function RankingBlock({ nbSimu, ranking }) {
  return (
    <div
      className={`bg-gradient-to-br from-orange-400 to-orange-800 md:h-44 flex px-4 py-2 rounded text-white relative flex-wrap`}
    >
      <div className="md:w-1/3 w-full flex-col-center text-center">
        <div className="font-semibold text-4xl">{nbSimu}</div>
        <div>simulations / conseiller en moyenne</div>
      </div>
      <div className="md:w-2/3 w-full flex gap-2">
        <RanckingColumn position={2} number={get(ranking, "1.count", 0)} />
        <RanckingColumn position={1} number={get(ranking, "0.count", 0)} />
        <RanckingColumn position={3} number={get(ranking, "2.count", 0)} />
      </div>
    </div>
  );
}

function RanckingColumn({ position, number }) {
  const positionSize = { 1: "h-24", 2: "h-20", 3: "h-14" };
  return (
    <div className="w-1/3 flex flex-col justify-end">
      <div className="leading-4 text-center pb-2">
        <div>
          <span className="font-semibold">{position}</span>
          <span>{position === 1 ? "er" : "ème"}</span>
        </div>
        <div>conseiller</div>
      </div>
      <div
        className={`bg-white bg-opacity-60 text-orange-800 rounded flex items-end justify-center text-4xl pb-1 ${get(
          positionSize,
          position
        )}`}
      >
        {number}
      </div>
    </div>
  );
}
