import BlockStat, { NumberStat } from "./BlockStat";
import React from "react";
import houseIcon from "../../svgs/house.svg";
import { get } from "lodash";
import { formatDateNumber } from "../../helpers";

const textColor = "text-orange-400";

export default function AdvisorSimulatorBlock({ simulator }) {
  const { simulationCount = 0, rank = 0, outOf = 0, ranking = [] } = simulator;

  return (
    <BlockStat title="Simulateur" textColor={textColor} icon={houseIcon}>
      <div className="grid">
        <NumberStat
          textColor={textColor}
          text={
            simulationCount > 1
              ? "simulations effectuées"
              : "simulation effectuée"
          }
          number={simulationCount}
        />
      </div>
      <RankingBlock rank={rank} outOf={outOf} ranking={ranking} />
      <div className="text-orange-400 font-semibold">
        Classement dans votre banque au {formatDateNumber(new Date())}
      </div>
    </BlockStat>
  );
}

function RankingBlock({ rank, outOf, ranking }) {
  return (
    <div
      className={`bg-gradient-to-br from-orange-400 to-orange-800 md:h-44 flex px-4 py-2 rounded text-white relative flex-wrap`}
    >
      <div className="md:w-1/3 w-full flex-col-center">
        <div className="flex items-end">
          <span className="font-semibold text-4xl">{rank}</span>
          <span className="text-xl pb-1">{rank === 1 ? "er" : "ème"}</span>
        </div>
        <div>
          sur {outOf.toLocaleString("fr-FR")} conseiller{outOf > 1 ? "s" : ""}
        </div>
      </div>
      <div className="md:w-2/3 w-full flex gap-2">
        <RanckingColumn position={2} number={get(ranking, "1.count", 0)} />
        <RanckingColumn position={1} number={get(ranking, "0.count", 0)} />
        <RanckingColumn position={3} number={get(ranking, "2.count", 0)} />
      </div>
    </div>
  );
}

function RanckingColumn({ position, number }) {
  const positionSize = { 1: "h-24", 2: "h-20", 3: "h-14" };
  return (
    <div className="w-1/3 flex flex-col justify-end">
      <div className="leading-4 text-center pb-2">
        <div>
          <span className="font-semibold">{position}</span>
          <span>{position === 1 ? "er" : "ème"}</span>
        </div>
        <div>conseiller</div>
      </div>
      <div
        className={`bg-white bg-opacity-60 text-orange-800 rounded flex items-end justify-center text-4xl pb-1 ${get(
          positionSize,
          position
        )}`}
      >
        {number}
      </div>
    </div>
  );
}
