import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import Collapse from "@kunukn/react-collapse";
import ReactSVG from "react-svg";
import { map, filter, head } from "lodash";

import plusIcon from "../svgs/plus.svg";
import minusIcon from "../svgs/minus.svg";

const Title = styled.div`
  padding-top: 35px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 20px;

  svg {
    path {
      fill: #000;
      stroke: #000;
    }
  }
`;
const Item = styled.div`
  padding-bottom: 15px;
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  margin-top: -1px;
  padding-bottom: ${props => (props.isOpen ? "35px" : "15px")};
`;
const Content = styled(Collapse)`
  overflow: hidden;
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);
  max-width: 100%;
  font-size: 17px;
  color: #7c7c7d;
`;
const List = styled.div``;

function Accordion({ className, items }) {
  const [actives, setActive] = useState([]);

  useEffect(() => {
    const hash = window.location.hash.substr(1);
    if (!hash) return;

    const opened = head(filter(items, item => item.slug === hash));
    if (!opened) return;

    setActive([opened.id]);

    const node = document.getElementById(hash);
    if (node) {
      node.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    }
  }, [items]);

  return (
    <div className={className}>
      <List>
        {map(items, (item, index) => {
          const active = actives.includes(item.id);
          return (
            <Item isOpen={active} key={item.id || index} id={item.slug}>
              <Title
                onClick={() => {
                  if (active) {
                    setActive(filter(actives, i => i !== item.id));
                  } else {
                    setActive([...actives, item.id]);
                  }
                }}
              >
                {item.title} <ReactSVG src={active ? minusIcon : plusIcon} />
              </Title>
              <Content isOpen={active}>
                <div
                  className="wysiwyg"
                  dangerouslySetInnerHTML={{ __html: item.content }}
                />
              </Content>
            </Item>
          );
        })}
      </List>
    </div>
  );
}

export default Accordion;
