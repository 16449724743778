import styled from "styled-components/macro";

const Label = styled.div`
  background: ${props => (props.color ? props.color : "#000")};
  font-size: 10px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  padding: 3px 7px;
  display: inline-block;
  margin-bottom: 13px;
  border-radius: 2px;
`;

export default styled(Label)`
  + ${Label} {
    margin-left: 5px;
  }
`;
