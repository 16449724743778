import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Row, Col } from "styled-bootstrap-grid";
import NewsBlock from "./NewsBlock";
import Title from "./Title";
import { map, get } from "lodash";

import { getAllNews } from "../api";

import Loader from "./Loader";

const NewsSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 60px;
  width: 100%;
`;

const NewsSectionRow = styled(Row)`
  min-width: 100%;
`;

function LastNews({ noTitle, maxNews = 3 }) {
  const [lastNews, setLastNews] = useState(null);

  useEffect(() => {
    async function getLastNews() {
      try {
        setLastNews(null);
        const { data } = await getAllNews(maxNews);
        setLastNews(data);
      } catch (error) {
        console.error(error);
      }
    }
    getLastNews();
  }, []); //eslint-disable-line

  return (
    <NewsSection>
      {noTitle !== true ? <Title underline>Actualités</Title> : null}
      <NewsSectionRow style={{ marginTop: "-20px" }}>
        {lastNews
          ? map(lastNews, item => (
              <Col className="m-t-1" md="4" key={`col-${item.id}`}>
                <NewsBlock
                  id={item.id}
                  date={item.date}
                  title={get(item, "title.rendered")}
                  image={item.featured_image}
                  labels={item.categories}
                />
              </Col>
            ))
          : map([...Array(maxNews).keys()], (_, idx) => (
              <Col md="4" key={idx}>
                <Loader />
              </Col>
            ))}
      </NewsSectionRow>
    </NewsSection>
  );
}

export default LastNews;
