import React from "react";
import styled from "styled-components/macro";

const BlockDate = styled.div`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 16px;
  color: #626568;
`;

function ContentDate({ className, children }) {
  return <BlockDate className={className}>{children}</BlockDate>;
}

export default styled(ContentDate)``;
