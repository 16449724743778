import React from "react";
import styled from "styled-components/macro";
import BasicInput from "../inputs/BasicTextarea";

function Textarea({
  className,
  error,
  value,
  name,
  title,
  type,
  validate,
  suffix
}) {
  return (
    <BasicInput
      className={className}
      name={name}
      label={title}
      value={value}
      type={type}
      error={error}
      validate={validate}
      suffix={suffix}
    />
  );
}

export default styled(Textarea)``;
